import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import fullscreenViewAtom from '../../../recoil/fullscreenView/atom'
import { ButtonBase, Tooltip } from '@mui/material'
import { MinimizeIcon } from '../Icons/MinimizeIcon'
import { MaximizeIcon } from '../Icons/MaximizeIcon'
import variables from '../../../variables.module.scss'

interface FullscreenViewButtonProps {
  immersive?: boolean;
  iconColor?: string;
  bgColor?: string;
  tooltipPlacement?: 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  onChange?: (fullscreen: boolean) => void
  onInit?: (fullscreen: boolean) => void
}

export const FullscreenViewButton: React.FC<FullscreenViewButtonProps> = ({
  immersive = false,
  iconColor = variables.blueColor,
  bgColor = variables.headerBackgroundColor,
  tooltipPlacement = 'left',
  onChange,
  onInit
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [fullscreenView, setFullscreenView] = useRecoilState(fullscreenViewAtom)

  useEffect(() => {
    if (typeof onInit === 'function') {
      onInit(fullscreenView.fullscreen)
    }
  }, [fullscreenView])

  return (
    <Tooltip
      open={showTooltip}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      title={fullscreenView.fullscreen ? 'Exit fullscreen view' : 'Enter fullscreen view'}
      placement={tooltipPlacement}
      arrow
    >
      <ButtonBase
        onClick={() => {
          setFullscreenView(prevFullScreenView => {
            const updatedView = {...prevFullScreenView}

            if (typeof onChange === 'function') {
              onChange(!updatedView.fullscreen)
            }

            updatedView.fullscreen = !updatedView.fullscreen
            updatedView.immersive = immersive

            return updatedView
          })
          setShowTooltip(false)
        }}
        sx={{ borderRadius: '6px', backgroundColor: bgColor, padding: '2px' }}
      >
        {fullscreenView.fullscreen ? <MinimizeIcon stroke={iconColor} /> : <MaximizeIcon stroke={iconColor} /> }
      </ButtonBase>
    </Tooltip>
  )
}
