import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import entriesAtom from '../recoil/entries'

interface TrafficStats {
  entriesCaptured: number
}

const useTrafficStats = (): TrafficStats => {
  const entries = useRecoilValue(entriesAtom)
  const [entriesCaptured, setEntriesCaptured] = useState(0);

  useEffect(() => {
    setEntriesCaptured(entries.size)
  }, [entries.size])

  return {entriesCaptured}
}

export default useTrafficStats
