import React from 'react'
import { IconProps } from './types'

export const SquareAsteriskIcon: React.FC<IconProps> = ({size = 24, stroke = 'black'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={stroke}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-square-asterisk'
    >
      <rect width='18' height='18' x='3' y='3' rx='2' />
      <path d='M12 8v8' />
      <path d='m8.5 14 7-4' />
      <path d='m8.5 10 7 4' />
    </svg>
  )
}
