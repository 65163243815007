import { LocalStorageKey } from '../../../../consts'

export interface Conversations {
  [scriptTitle: string]: Conversation
}

export interface Conversation {
  threadId: string
  scriptTitle: string
}

const prepareStorage = () => {
  if (localStorage.getItem(LocalStorageKey.ScriptingAssistantConversations) === null) {
    localStorage.setItem(LocalStorageKey.ScriptingAssistantConversations, JSON.stringify({}));
  }
}

export const getAllConversations = (): Conversations => {
  prepareStorage()

  const conversationsStr = localStorage.getItem(LocalStorageKey.ScriptingAssistantConversations)
  return {...JSON.parse(conversationsStr)}
}

export const getConversation = (scriptTitle: string): Conversation | null => {
  prepareStorage()

  const conversations = getAllConversations()
  if (!conversations[scriptTitle]) {
    return null
  }

  return conversations[scriptTitle]
}

export const checkThreadIdIsNew = (threadId: string) => {
  prepareStorage()

  const conversationsStr = localStorage.getItem(LocalStorageKey.ScriptingAssistantConversations)

  return !conversationsStr.includes(threadId)
}

export const saveConversation = (threadId: string, scriptTitle: string) => {
  prepareStorage()

  if (!checkThreadIdIsNew(threadId)) {
    return
  }

  const conversations = getAllConversations()
  if (conversations[scriptTitle]) {
    return
  }

  conversations[scriptTitle] = {threadId, scriptTitle}

  localStorage.setItem(
    LocalStorageKey.ScriptingAssistantConversations,
    JSON.stringify(conversations)
  )
}

export const deleteConversation = (scriptTitle: string) => {
  prepareStorage()

  const conversations = getAllConversations()

  if (!conversations[scriptTitle]) {
    return
  }

  delete conversations[scriptTitle]

  localStorage.setItem(
    LocalStorageKey.ScriptingAssistantConversations,
    JSON.stringify(conversations)
  )
}
