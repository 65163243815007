import React, { useCallback, useEffect, useRef, useState } from 'react'
import variables from '../../../../variables.module.scss'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { FloatingScriptActionsPanel } from '../FloatingScriptActionsPanel/FloatingScriptActionsPanel'
import { trafficViewTabs } from '../../../../recoil/trafficViewTab/atom'
import { Script } from '../../../modals/ScriptingModal/ScriptingTypes'
import { useSetRecoilState } from 'recoil'
import scriptLogIndexOnLoadAtom from '../../../../recoil/scriptLogIndexOnLoad'
import trafficViewTabAtom from '../../../../recoil/trafficViewTab'
import { authorizeAction, AUTHZ_ACTIONS } from '../../../UI/Auth/SamlAuth/Authorization'
import { postActivateScript } from '../api/postActivateScript'
import { getScripts } from '../api/getScripts'
import { postDeactivateScript } from '../api/postDeactivateScript'
import scriptAllWsLogsAtom from '../../../../recoil/scriptAllWsLogs'
import scriptsAtom from '../../../../recoil/scripts'
import styles from '../ScriptBrewAiAssistant/ScriptBrewAiAssistant.module.scss'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'
import { RobotIcon } from '../../../UI/Icons/RobotIcon'

import './ScriptEditor.scss'
import useLicense from '../../../../hooks/useLicense'
import { LicenseEnterpriseEdition } from '../../../../consts'
import { NewScriptOverlayAiPrompt } from './NewScriptOverlayAiPrompt/NewScriptOverlayAiPrompt'
import Moment from 'moment'
import { CloseRounded } from '@mui/icons-material'

import Editor, { loader, useMonaco } from '@monaco-editor/react'

import * as monaco from 'monaco-editor';
import { appAiAssistantEnabled } from '../../../../types/global'

loader.config({ monaco });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
self.MonacoEnvironment = {
  getWorker: function (moduleId, label) {
    if (label === 'json') {
      return new Worker(
        new URL(
          'monaco-editor/esm/vs/language/json/json.worker',
          import.meta.url
        )
      );
    }
    if (label === 'css' || label === 'scss' || label === 'less') {
      return new Worker(
        new URL('monaco-editor/esm/vs/language/css/css.worker', import.meta.url)
      );
    }
    if (label === 'html' || label === 'handlebars' || label === 'razor') {
      return new Worker(
        new URL(
          'monaco-editor/esm/vs/language/html/html.worker',
          import.meta.url
        )
      );
    }
    if (label === 'typescript' || label === 'javascript') {
      return new Worker(
        new URL(
          'monaco-editor/esm/vs/language/typescript/ts.worker',
          import.meta.url
        )
      );
    }
    return new Worker(
      new URL('monaco-editor/esm/vs/editor/editor.worker', import.meta.url)
    );
  },
};

interface ScriptEditorProps {
  selectedScript: Script
  setScriptTitle: (title: string) => void
  scriptText: string
  setScriptText: (text: string) => void
  setScriptChanged: (changed: boolean) => void
  darkModeEnabled: boolean
  activationButtonClicked: boolean
  setActivationButtonClicked: (clicked: boolean) => void
  setSelectedScript: React.Dispatch<React.SetStateAction<Script>>
  handleOpenAiAssistant: () => void
  setCodeCreatePrompt: (prompt: string) => void
  setCodeToChange: (code: string) => void
  setCodeChangePrompt: (prompt: string) => void
  aiCreatedCode: string
  setAiCreatedCode: (code: string) => void
  aiModifiedCode: string
  setAiModifiedCode: (code: string) => void
  handleSaveScriptChanges: () => Promise<unknown>
  showOverlayAiPrompt: boolean
  setShowOverlayAiPrompt: (show: boolean) => void
}

export const ScriptEditor: React.FC<ScriptEditorProps> = ({
  selectedScript,
  setScriptTitle,
  scriptText,
  setScriptText,
  setScriptChanged,
  darkModeEnabled,
  activationButtonClicked,
  setActivationButtonClicked,
  setSelectedScript,
  handleOpenAiAssistant,
  setCodeCreatePrompt,
  setCodeToChange,
  setCodeChangePrompt,
  aiCreatedCode,
  setAiCreatedCode,
  aiModifiedCode,
  setAiModifiedCode,
  handleSaveScriptChanges,
  showOverlayAiPrompt,
  setShowOverlayAiPrompt
}) => {
  const setScriptAllWsLogs = useSetRecoilState(scriptAllWsLogsAtom)
  const setTrafficViewTab = useSetRecoilState(trafficViewTabAtom)
  const setScriptLogIndexOnLoad = useSetRecoilState(scriptLogIndexOnLoadAtom)
  const setScripts = useSetRecoilState(scriptsAtom)
  const [editorSelections, setEditorSelections] = useState([])
  const [aiPromptSelections, setAiPromptSelections] = useState([])
  const [lineHighlightClass, setLineHighlightClass] = useState('EditorAiPromptSelectionLine')

  const decorationCollectionRef = useRef(null)

  const monaco = useMonaco()

  useEffect(() => {
    if (!editorRef.current) {
      return
    }

    let decorations = []

    if (aiPromptSelections.length > 0) {
      decorations = [
        {
          range: new monaco.Range(
            aiPromptSelections[0].startLineNumber,
            aiPromptSelections[0].startColumn,
            aiPromptSelections[0].endLineNumber,
            aiPromptSelections[0].endColumn,
          ),
          options: {
            isWholeLine: true,
            className: lineHighlightClass,
          },
        },
      ]
    }

    if (decorationCollectionRef.current) {
      decorationCollectionRef.current = editorRef.current.deltaDecorations(decorationCollectionRef.current, [])
    }

    decorationCollectionRef.current = editorRef.current.deltaDecorations([], decorations)
  }, [aiPromptSelections, lineHighlightClass])

  const [indicatorPosition, setIndicatorPosition] = useState(null);

  const [hasInteracted, setHasInteracted] = useState(false);

  const editorRef = useRef(null);

  const zoneIdRef = useRef(null);      // Reference to store the View Zone ID
  const cleanupRef = useRef(null);

  const containerRef = useRef(null);

  const { licenseInfo } = useLicense()

  const addViewZone = (editor, afterLineNumber: number, heightInLines: number, zoneNode: HTMLElement) => {
    editor.changeViewZones((changeAccessor) => {
      zoneIdRef.current = changeAccessor.addZone({
        afterLineNumber: afterLineNumber, // Position after line 3
        heightInLines, // Height of the zone in lines
        domNode: zoneNode // The main content of the zone
      })
    });

    // 5. Add event listener to the button within the zone
    const zoneButton = zoneNode.querySelector('#zoneButton');
    if (zoneButton) {
      const handleButtonClick = () => {
        alert('Button inside View Zone clicked!');
      };
      zoneButton.addEventListener('click', handleButtonClick);

      // Define the cleanup function
      // Store the cleanup function for later use
      cleanupRef.current = () => {
        zoneButton.removeEventListener('click', handleButtonClick);
        editor.changeViewZones((changeAccessor) => {
          changeAccessor.removeZone(zoneIdRef.current);
        });
      };
    }

    // If no interactive elements, define a basic cleanup function
    if (!zoneButton) {
      cleanupRef.current = () => {
        editor.changeViewZones((changeAccessor) => {
          changeAccessor.removeZone(zoneIdRef.current)
        })
      }
    }
  };

  const removeViewZone = (editor, zoneId) => {
    if (!editor || !zoneId) {
      return
    }

    editor.changeViewZones((changeAccessor) => {
      changeAccessor.removeZone(zoneId)
    })
  }

  // Function to update the position
  const updatePositions = () => {
    if (!editorRef.current || !containerRef.current) return;

    const editor = editorRef.current;
    const selections = editor.getSelections();
    setEditorSelections(selections);

    if (!selections || selections.length === 0) {
      setIndicatorPosition(null);
      return;
    }

    const hasRangeSelection = selections.some(selection =>
      selection.startLineNumber !== selection.endLineNumber ||
      selection.startColumn !== selection.endColumn
    );

    if (hasRangeSelection) {
      const rangeSelection = selections.find(selection =>
        selection.startLineNumber !== selection.endLineNumber ||
        selection.startColumn !== selection.endColumn
      );

      if (rangeSelection) {
        const position = {
          lineNumber: rangeSelection.endLineNumber,
          column: rangeSelection.endColumn,
        };

        const domPos = editor.getScrolledVisiblePosition(position);

        if (domPos && containerRef.current) {
          const lineCount = editor.getModel()?.getLineCount() || 0;
          let topOffset = 0;

          if (rangeSelection.startLineNumber === 1) {
            topOffset = domPos.top + domPos.height - 35;
          } else if (rangeSelection.endLineNumber === lineCount) {
            topOffset = domPos.top - 40;
          } else {
            topOffset = domPos.top + domPos.height - 40;
          }

          const absolutePos = {
            top: topOffset,
            left: domPos.left + 90,
          };

          setIndicatorPosition(absolutePos);
        } else {
          setIndicatorPosition(null);
        }
      }
    } else {
      setEditorSelections([]);
      setIndicatorPosition(null);
    }
  };

  // Handle editor mount
  function handleEditorMount(editor, monacoInstance) {
    editorRef.current = editor;

    // Initial position update (without setting indicators)
    // Avoid calling updatePositions here to prevent initial red square

    // Listen for selection changes
    const disposableSelection = editor.onDidChangeCursorSelection(() => {
      if (!hasInteracted) setHasInteracted(true);
      updatePositions();
    });

    // Listen for editor layout changes (e.g., resizing)
    const disposableLayout = editor.onDidLayoutChange(() => {
      updatePositions();
    });

    // Listen for editor scroll changes
    const disposableScroll = editor.onDidScrollChange(() => {
      updatePositions();
    });

    const disposableKeyDown = editor.onKeyDown((e) => {
      const browserEvent = e.browserEvent;

      if (
        (browserEvent.ctrlKey || browserEvent.metaKey) &&
        (browserEvent.code === 'KeyA' || browserEvent.key.toLowerCase() === 'a')
      ) {
        setTimeout(updatePositions, 0);
      }
    });

    // Listen for window resize
    window.addEventListener('resize', updatePositions);

    // Access the TypeScript language service
    monacoInstance.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monacoInstance.languages.typescript.ScriptTarget.ESNext,
      allowNonTsExtensions: true,
      // Add or modify compiler options as needed
      checkJs: true, // Enable type checking in JavaScript
      // You can add more options here
    });

    // Optionally, set diagnostic options
    monacoInstance.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: false,
      noSyntaxValidation: false,
      diagnosticCodesToIgnore: [2304]
    });

    // Cleanup on unmount
    return () => {
      disposableSelection.dispose();
      disposableLayout.dispose();
      disposableScroll.dispose();
      disposableKeyDown.dispose();
      window.removeEventListener('resize', updatePositions);
    };
  }

  useEffect(() => {
    if (showOverlayAiPrompt) {
      handleOpenAiAssistant()
    }
  }, [showOverlayAiPrompt])

  const handleSubmitAiNewScriptPrompt = useCallback((e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault()

      const userMessage = e.target.value
      if (!userMessage) {
        return
      }

      e.target.value = ''
      e.target.style.cursor = 'wait'
      e.target.setAttribute('disabled', true);
      e.target.setAttribute('placeholder', '⏳ Creating your automation...')

      handleOpenAiAssistant()
      setCodeCreatePrompt(userMessage)
    }
  }, [])

  const handleSubmitAiCodeChangePrompt = useCallback((e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault()

      if (editorRef.current === null || editorSelections.length === 0) {
        return
      }

      const userMessage = e.target.value
      if (!userMessage) {
        return
      }

      e.target.value = ''
      e.target.style.cursor = 'wait'
      e.target.setAttribute('disabled', true);
      e.target.setAttribute('placeholder', '⏳ Saving your script...')

      const closeButton = document.querySelector<HTMLElement>('.close-inline-ai-prompt-btn')
      closeButton.style.display = 'none'

      handleSaveScriptChanges().then(() => {
        e.target.setAttribute('placeholder', '⏳ Modifying selected lines...')

        handleOpenAiAssistant()
        setCodeToChange(editorRef.current.getModel().getValueInRange(editorSelections[0]))
        setCodeChangePrompt(userMessage)
      })
    }
  }, [editorSelections])

  const handleInsertAiAssistantPrompt = useCallback(() => {
    if (editorRef.current === null || editorSelections.length === 0) {
      return
    }

    const zoneNode = document.createElement('div');
    zoneNode.style.height = '100%';
    zoneNode.style.background = darkModeEnabled
      ? variables.githubEditorBackgroundColorLight
      : variables.lightestGrayColor;

    zoneNode.style.padding = '10px';
    zoneNode.style.zIndex = '4';

    const root = createRoot(zoneNode);
    flushSync(() => {
      root.render(
        <Box
          className='inline-ai-prompt'
          display='flex'
          alignItems='flex-start'
          gap='10px'
        >
          <TextareaAutosize
            className={`${styles.Textarea} ${styles.InEditor} ${darkModeEnabled ? styles.Dark : styles.Light}`}
            placeholder='⚡ Ask our GenAI to change/fix the code'
            onKeyDown={handleSubmitAiCodeChangePrompt}
          />
          <IconButton
            className='close-inline-ai-prompt-btn'
            sx={{
              padding: '2px',
              borderRadius: '6px',
              border: `1px solid ${darkModeEnabled
                ? variables.slateColor
                : variables.lighterGrayColor}`,
            }}
            onClick={() => {
              setAiModifiedCode('')
              setEditorSelections([])
              setAiPromptSelections([])
              setCodeToChange('')
              setCodeChangePrompt('')
              setLineHighlightClass('EditorAiPromptSelectionLine')

              removeViewZone(editorRef.current, zoneIdRef.current)
            }}
          >
            <CloseRounded
              htmlColor={
                darkModeEnabled
                  ? variables.lightSlateColor
                  : variables.grayColor
              }
              sx={{
                fontSize: '20px'
              }}
            />
          </IconButton>
        </Box>
      );
    })

    setAiPromptSelections([])
    setAiPromptSelections(editorSelections)

    removeViewZone(editorRef.current, zoneIdRef.current)
    addViewZone(editorRef.current, editorSelections[0].endLineNumber, 5, zoneNode)
  }, [editorSelections])

  const handleFormatCode = () => {
    if (editorRef.current) {
      editorRef.current.getAction('editor.action.formatDocument').run()
    }
  }

  useEffect(() => {
    if (editorRef.current !== null && aiCreatedCode.length > 0) {
      setScriptText(aiCreatedCode)

      const codeChunks = aiCreatedCode.split('\n')
      let aiCreatedTitle = codeChunks[0]

      if (aiCreatedTitle) {
        aiCreatedTitle = aiCreatedTitle.replace(/^\s*\/{2,}\s*/, '').trim()
      } else {
        aiCreatedTitle = `GenAI-created script ${Moment()?.utc().format('MM/DD/YYYY, (UTC+00) h:mm:ss A')}`
      }

      setScriptTitle(aiCreatedTitle)

      setAiCreatedCode('')
      setCodeCreatePrompt('')
      setShowOverlayAiPrompt(false)

      setTimeout(() => {
        handleFormatCode()
      }, 500)
    }
  }, [aiCreatedCode])

  useEffect(() => {
    if (editorRef.current !== null && aiModifiedCode.length > 0) {
      setLineHighlightClass('EditorAiPromptUpdatedLine')

      editorRef.current.executeEdits('replace-operation', [
        {
          range: aiPromptSelections[0],
          text: aiModifiedCode,
          forceMoveMarkers: true,
        },
      ])

      setAiModifiedCode('')
      removeViewZone(editorRef.current, zoneIdRef.current)

      setTimeout(() => {
        setEditorSelections([])
        setAiPromptSelections([])
        setCodeToChange('')
        setCodeChangePrompt('')
        setLineHighlightClass('EditorAiPromptSelectionLine')
        handleFormatCode()
      }, 2000)
    }
  }, [aiModifiedCode])

  useEffect(() => {
    // Cleanup function to clear any pending timeouts when the component unmounts
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current();
      }
      if (editorRef.current) {
        editorRef.current.dispose();
      }

      setIndicatorPosition(null);
    };
  }, []);


  const handleActivateScript = () => {
    if (
      !authorizeAction({
        [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
        AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
      })
    ) {
      return
    }

    setActivationButtonClicked(true)
    postActivateScript(selectedScript.index)
      .then(() => {
        getScripts().then((scripts) => setScripts(scripts))
        setSelectedScript((script) => {
          const updatedScript = { ...script }
          updatedScript.active = true

          return updatedScript
        })
      })
      .then(() => setActivationButtonClicked(false))
  }

  const handleDeactivateScript = () => {
    if (
      !authorizeAction({
        [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
        AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
      })
    ) {
      return
    }

    setActivationButtonClicked(true)

    postDeactivateScript(selectedScript.index)
      .then(() => {
        getScripts().then((scripts) => setScripts(scripts))
        setSelectedScript(script => {
          const updatedScript = { ...script }
          updatedScript.active = false

          return updatedScript
        })

        setScriptAllWsLogs((scriptLogs) => {
          const updatedScriptLogs = { ...scriptLogs }

          const updatedScriptLog = {
            ...updatedScriptLogs[selectedScript.index]
          }
          updatedScriptLog.logs = []

          updatedScriptLogs[selectedScript.index] = updatedScriptLog

          return updatedScriptLogs
        })
      })
      .then(() => setActivationButtonClicked(false))
  }

  return (
    <Box ref={containerRef} boxSizing='border-box' position='relative' height='100%'>
      {showOverlayAiPrompt && (
        <NewScriptOverlayAiPrompt
          darkModeEnabled={darkModeEnabled}
          handleSubmitAiPrompt={handleSubmitAiNewScriptPrompt}
        />)
      }
      <Editor
        height='100%'
        defaultLanguage='javascript'
        value={scriptText}
        onMount={handleEditorMount}
        onChange={(text) => {
          setScriptText(text)
          setScriptChanged(true)
        }}
        options={{
          scrollBeyondLastLine: false,
        }}
        className={showOverlayAiPrompt ? 'EditorBlurred' : null}
      />

      {
        appAiAssistantEnabled() &&
        indicatorPosition &&
        licenseInfo !== null &&
        licenseInfo.doc.edition === LicenseEnterpriseEdition && licenseInfo.valid && (
          <Box
            style={{
              boxSizing: 'border-box',
              position: 'absolute',
              top: indicatorPosition.top,
              left: indicatorPosition.left,
              zIndex: 10,
            }}
          >
            <Button
              color='primary'
              variant='contained'
              size='small'
              className={`themeButton slate`}
              startIcon={
                <Box
                  boxSizing='border-box'
                  mr='5px'
                >
                  <RobotIcon
                    stroke={variables.lighterGrayBlueColor}
                    size={20}
                  />
                </Box>
              }
              onClick={() => {
                handleInsertAiAssistantPrompt()
                setIndicatorPosition(null)
              }}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontFamily='Roboto'
                fontWeight={500}
                whiteSpace='nowrap'
                color='#ffffff'
              >
                Ask our GenAI to change the code
              </Typography>
            </Button>
          </Box>
        )
      }

      <FloatingScriptActionsPanel
        selectedScript={selectedScript}
        darkModeEnabled={darkModeEnabled}
        inProgress={activationButtonClicked}
        blurred={showOverlayAiPrompt}
        handleDeactivateScript={handleDeactivateScript}
        handleActivateScript={handleActivateScript}
        goToScriptConsoleLogs={() => {
          setTrafficViewTab(trafficViewTabs.CONSOLE)
          setScriptLogIndexOnLoad(selectedScript.index)
        }}
      />
    </Box>
  )
}
