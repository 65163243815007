import React from 'react'
import { IconProps } from './types'

export const StopwatchIcon: React.FC<IconProps> = ({ size = 24, stroke = 'black' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={stroke}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-timer'
    >
      <line x1='10' x2='14' y1='2' y2='2' />
      <line x1='12' x2='15' y1='14' y2='11' />
      <circle cx='12' cy='14' r='8' />
    </svg>
  )
}
