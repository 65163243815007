import Cookies from 'js-cookie'
import React, { useRef } from 'react'
import {
  SamlAuthType,
  SamlAuthCookieName, HubBaseUrl, initSamlAuthPath
} from '../../../../consts'

import {
  Box,
  Dialog,
  Grid,
} from '@mui/material'

import { DashboardBanner } from '../../DashboardBanner/DashboardBanner'
import { SamlSignInForm } from './SamlSignInForm/SamlSignInForm'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import authSessionLogoutAtom from '../../../../recoil/authSessionLogout/atom'
import authSessionOkAtom from '../../../../recoil/authSessionOk/atom'
import showAuthDialogAtom from '../../../../recoil/showAuthDialog/atom'
import authSessionLogoutCompletedAtom from '../../../../recoil/authSessionLogoutCompleted/atom'
import useWindowDimensions from '../../../../hooks/WindowDimensionsHook'
import * as jose from 'jose'

export const checkSamlEnabled = () => {
  return window.__RUNTIME_CONFIG__.REACT_APP_AUTH_TYPE === SamlAuthType
}

export const extractJwtPayload = (): jose.JWTPayload => {
  const authJwtStr = Cookies.get(SamlAuthCookieName)
  return authJwtStr !== undefined ? jose.decodeJwt(authJwtStr) : undefined
}

export const checkSamlAuth = () => Cookies.get(SamlAuthCookieName) !== undefined

const samlLogout = () => {
  window.location.href = `${HubBaseUrl}/saml/logout`
}

const muiDialogPaperPropVals = {
  style: {
    border: 0,
    borderRadius: 0,
    marginTop: 0,
    overflow: 'hidden'
  }
}

interface SamlAuthProps {
  children?: React.ReactNode | React.ReactNode[]
}

export const SamlAuth: React.FC<SamlAuthProps> = ({ children }) => {
  const [samlAuthCookieOk, setSamlAuthCookieOk] = React.useState(checkSamlAuth())
  const [showAuthDialog, setShowAuthDialog] = useRecoilState(showAuthDialogAtom)

  const setAuthSessionOk = useSetRecoilState(authSessionOkAtom)
  const authSessionLogout = useRecoilValue(authSessionLogoutAtom)

  const handleCloseAuthDialog = () => setShowAuthDialog(false)

  React.useLayoutEffect(() => {
    if (window.location.pathname.includes(initSamlAuthPath)) {
      window.history.replaceState({}, '', window.location.pathname.replace(initSamlAuthPath, ''));
    }
  }, [])

  React.useEffect(() => {
    const trackSamlAuthCookie = setInterval(() => {
      setSamlAuthCookieOk(checkSamlAuth())
    }, 500)


    setAuthSessionOk(checkSamlAuth())

    return () => clearInterval(trackSamlAuthCookie)
  }, [])

  const renderComplete = useRef({samlAuthCookieOk: false, authSessionLogoutEffect: false})

  React.useEffect(() => {
    if (renderComplete.current["samlAuthCookieOk"]) {
      setAuthSessionOk(samlAuthCookieOk)
    }

    setShowAuthDialog(!samlAuthCookieOk)
    renderComplete.current["samlAuthCookieOk"] = true
  }, [samlAuthCookieOk])

  const setAuthSessionLogoutCompleted = useSetRecoilState(authSessionLogoutCompletedAtom)

  React.useEffect(() => {
    if (renderComplete.current["authSessionLogoutEffect"]) {
      samlLogout()
      setAuthSessionLogoutCompleted(Date.now())
    }

    renderComplete.current["authSessionLogoutEffect"] = true
  }, [authSessionLogout])

  const [desktopView, setDesktopView] = React.useState(false)
  const [signInFormView, setSignInFormView] = React.useState<React.ReactElement>(<></>)

  const { width: windowWidth } = useWindowDimensions()
  React.useEffect(() => setDesktopView(windowWidth >= 1024), [windowWidth])

  React.useEffect(() => {
    if (desktopView) {
      setSignInFormView(
        <Grid container wrap='nowrap' sx={{ height: '100%' }}>
          <Grid item xs={5}>
            <SamlSignInForm />
          </Grid>
          <Grid item xs={7}>
            <DashboardBanner />
          </Grid>
        </Grid>
      )
    } else {
      setSignInFormView(
        <>
          <DashboardBanner compact />
          <Box height='100%' boxSizing='border-box' padding='0 16px'>
            <SamlSignInForm />
          </Box>
        </>
      )
    }
  }, [desktopView])

  return (
    <>
      {children}
      <Dialog
        fullScreen
        disableEscapeKeyDown
        open={showAuthDialog}
        onClose={handleCloseAuthDialog}
        PaperProps={muiDialogPaperPropVals}
        sx={{ zIndex: 1400 }}
      >
        {signInFormView}
      </Dialog>
    </>

  )
}
