import React from 'react'
import { StopwatchIcon } from '../../components/UI/Icons/StopwatchIcon'
import { InlineCode } from '../../components/UI/InlineCode/InlineCode'
import { ErrorPageTemplate } from '../components/ErrorPageTemplate'

import variables from '../../variables.module.scss'

export const AuthExpiredState: React.FC = () => {
  return (
    <ErrorPageTemplate
      title='Authentication timeout'
      description={<>
        It&apos;s been a while since you started authenticating & did not finish in time.
        Please, start over!
      </>}
      suggestion={[<>
        <strong>Suggestion:</strong>&nbsp;Use helm value&nbsp;<InlineCode>tap.auth.dex.oauth2StateParamExpiry</InlineCode>
        &nbsp;to adjust the OAuth2 state parameter expiration
      </>]}
      errorIcon={<StopwatchIcon size={36} stroke={variables.failureColor} />}
    />
  )
}
