import React from "react";
import logo from './assets/Kubeshark-logo.svg';
import './Header.sass';
import * as UI from "../UI"

import { NavDropdown } from "./NavDropdown/NavDropdown";
import { NavDivider } from "./NavDivider";
import { useRecoilValue } from "recoil";
import fullscreenViewAtom from "../../recoil/fullscreenView/atom";
import { appAuthEnabled } from "../../types/global";
import { AuthButton } from "../UI/AuthButton/AuthButton";
import { TrafficSettingsButton } from '../Filters/TrafficSettingsButton/TrafficSettingsButton'
import useScriptLogTracker from '../../hooks/useScriptLogTracker'

export const Header: React.FC = () => {
  useScriptLogTracker()

  const fullscreenView = useRecoilValue(fullscreenViewAtom)

  if (fullscreenView.fullscreen) {
    return <></>
  }

  return <div className="header">
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <img className="logo" src={logo} alt="logo" />
    </div>
    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <UI.DocsLink />
      <NavDivider />
      <TrafficSettingsButton iconOnly={false} />
      <NavDivider />
      {appAuthEnabled() && (
        <>
          <AuthButton size='small' />
          <NavDivider />
        </>
      )}
      <NavDropdown />
    </div>
  </div>;
}
