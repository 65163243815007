import React, { useRef } from 'react'
import { Script } from '../../../modals/ScriptingModal/ScriptingTypes'
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material'
import variables from '../../../../variables.module.scss'
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import Tooltip from '@mui/material/Tooltip'
import { authorizeAction, AUTHZ_ACTIONS } from '../../../UI/Auth/SamlAuth/Authorization'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { Dropdown } from '../../../UI/Dropdown/Dropdown'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { CircleDivider } from '../../../UI/CircleDivider/CircleDivider'
import { LightDarkModeSwitch } from '../../../UI/LightDarkModeSwitch/LightDarkModeSwitch'
import { FullscreenViewButton } from '../../../UI/FullscreenView/FullscreenViewButton'

interface NavigationBarProps {
  darkModeEnabled: boolean
  onSidebarSwitchClick: () => void
  sidebarOpen: boolean
  scriptTitle: string
  onTitleInputChange: (event) => void
  scriptChanged: boolean
  selectedScript: Script
  saveChangesButtonClicked: boolean
  handleSaveScriptChanges: () => void
  disableSaving: boolean
  deleteDropdownOpen: boolean
  setDeleteDropdownOpen: (value: ((prevState: boolean) => boolean) | boolean) => void
  onDeleteDropdownButtonClick: () => void
  deleteButtonClicked: boolean
  handleDeleteScript: () => void
  handleCancelDeleteScript: () => void
  onDarkModeChange: (event) => void
  onFullscreenModeChange: (fullscreen) => void
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  darkModeEnabled,
  onSidebarSwitchClick,
  sidebarOpen,
  scriptTitle,
  onTitleInputChange,
  scriptChanged,
  selectedScript,
  saveChangesButtonClicked,
  handleSaveScriptChanges,
  disableSaving,
  deleteDropdownOpen,
  setDeleteDropdownOpen,
  onDeleteDropdownButtonClick,
  deleteButtonClicked,
  handleDeleteScript,
  handleCancelDeleteScript,
  onDarkModeChange,
  onFullscreenModeChange,
}) => {
  const deleteButtonRef = useRef(null)

  return (
    <Box
      boxSizing='border-box'
      position='absolute'
      zIndex={1}
      padding='8px 12px'
      width='100%'
      display='flex'
      alignItems='center'
      gap='10px'
      bgcolor={
        darkModeEnabled
          ? variables.githubEditorBackgroundColorLight
          : variables.mainBackgroundColor
      }
      borderBottom={`1px solid ${darkModeEnabled ? variables.slateColor : variables.lighterGrayColor}`}
    >
      <IconButton onClick={onSidebarSwitchClick}>
        {sidebarOpen ? (
          <MenuOpenRoundedIcon htmlColor={variables.lightGrayColor} />
        ) : (
          <MenuRoundedIcon htmlColor={variables.lightGrayColor} />
        )}
      </IconButton>
      <TextField
        variant='standard'
        placeholder='Enter script title'
        value={scriptTitle}
        type='string'
        style={{
          flex: '1'
        }}
        inputProps={{
          style: {
            paddingTop: '6px',
            color: darkModeEnabled
              ? variables.headerBackgroundColor
              : variables.grayColor,
            fontSize: 18,
            fontWeight: 600
          }
        }}
        onChange={onTitleInputChange}
      />
      <Tooltip
        title={
          !authorizeAction({
            [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
            AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
          })
            ? 'You are not authorized to save scripts'
            : null
        }
        arrow
      >
        <Button
          color='primary'
          variant='contained'
          size='small'
          className={`themeButton ${
            !scriptChanged && selectedScript !== null
              ? 'slate'
              : 'warning'
          }`}
          startIcon={
            <SaveRoundedIcon
              htmlColor={
                !scriptChanged && selectedScript !== null
                  ? '#ffffff'
                  : variables.grayColor
              }
              sx={{ marginRight: '5px' }}
            />
          }
          onClick={
            saveChangesButtonClicked
              ? null
              : handleSaveScriptChanges
          }
          disabled={(!scriptChanged && selectedScript !== null) || disableSaving}
          disableRipple={
            saveChangesButtonClicked ||
            !authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
              AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
            })
          }
          sx={{
            transition: 'background-color 0.3s',
            opacity:
              saveChangesButtonClicked ||
              !authorizeAction({
                [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
              })
                ? 0.7
                : 1,
            filter: `grayscale(${
              !authorizeAction({
                [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
              })
                ? 1
                : 0
            })`,
            cursor:
              saveChangesButtonClicked ||
              !authorizeAction({
                [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
              })
                ? 'not-allowed'
                : 'pointer'
          }}
        >
          <Typography
            variant='body1'
            fontSize={14}
            fontFamily='Roboto'
            fontWeight={500}
            whiteSpace='nowrap'
            color={
              !scriptChanged && selectedScript !== null
                ? '#ffffff'
                : variables.grayColor
            }
          >
            {saveChangesButtonClicked
              ? 'Saving...'
              : selectedScript === null
                ? 'Save New Script'
                : 'Save Changes'}
          </Typography>
        </Button>
      </Tooltip>
      <Dropdown
        open={deleteDropdownOpen}
        setOpen={setDeleteDropdownOpen}
        anchorElement={
          <Tooltip
            title={
              !authorizeAction({
                [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
              })
                ? 'You are not authorized to delete scripts'
                : null
            }
            arrow
          >
            <IconButton
              ref={deleteButtonRef}
              color='error'
              disabled={selectedScript === null}
              onClick={onDeleteDropdownButtonClick}
              disableRipple={
                selectedScript === null ||
                !authorizeAction({
                  [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                  AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                })
              }
              sx={{
                opacity:
                  selectedScript === null ||
                  !authorizeAction({
                    [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                    AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                  })
                    ? 0.7
                    : 1,
                filter: `grayscale(${
                  !authorizeAction({
                    [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                    AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                  })
                    ? 1
                    : 0
                })`,
                cursor: !authorizeAction({
                  [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                  AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                })
                  ? 'not-allowed'
                  : 'pointer'
              }}
            >
              <DeleteRoundedIcon
                htmlColor={
                  selectedScript === null
                    ? variables.lightGrayColor
                    : variables.failureColor
                }
                sx={{ fontSize: '24px' }}
              />
            </IconButton>
          </Tooltip>
        }
        anchorRef={deleteButtonRef}
        placement='bottom-start'
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 20]
            }
          }
        ]}
        growFrom='right top'
      >
        <Box
          boxSizing='border-box'
          padding='12px'
          display='flex'
          alignItems='center'
          flexDirection='column'
          gap='10px'
          borderRadius='4px'
          border={`1px solid ${
            darkModeEnabled
              ? variables.slateColor
              : variables.lighterGrayColor
          }`}
          bgcolor={
            darkModeEnabled
              ? variables.githubEditorBackgroundColorLight
              : variables.lightGrayBlueColor
          }
          maxHeight='400px'
          overflow='auto'
        >
          <p
            style={{
              margin: 0,
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 500,
              color: darkModeEnabled
                ? variables.lighterGrayColor
                : variables.slateColor,
              whiteSpace: 'nowrap'
            }}
          >
            Are you sure to delete this script?
          </p>
          <Box
            boxSizing='border-box'
            width='100%'
            display='flex'
            alignItems='center'
            gap='10px'
          >
            <Button
              color='error'
              variant='contained'
              size='small'
              className='themeButton error'
              disableRipple={
                deleteButtonClicked ||
                !authorizeAction({
                  [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                  AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                })
              }
              startIcon={
                deleteButtonClicked ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      color: variables.mainBackgroundColor,
                      mr: '5px'
                    }}
                  />
                ) : (
                  <DeleteRoundedIcon sx={{ marginRight: '5px' }} />
                )
              }
              onClick={
                deleteButtonClicked ? null : handleDeleteScript
              }
              sx={{
                flex: 1,
                opacity:
                  deleteButtonClicked ||
                  !authorizeAction({
                    [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                    AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                  })
                    ? 0.8
                    : 1,
                cursor:
                  deleteButtonClicked ||
                  !authorizeAction({
                    [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                    AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                  })
                    ? 'not-allowed'
                    : 'pointer'
              }}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontFamily='Roboto'
                fontWeight={500}
                whiteSpace='nowrap'
                color='#ffffff'
              >
                {deleteButtonClicked ? 'Deleting...' : 'Delete'}
              </Typography>
            </Button>
            <Button
              variant='contained'
              size='small'
              className={`themeButton ${
                darkModeEnabled ? 'slate' : 'light-gray'
              }`}
              onClick={handleCancelDeleteScript}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontFamily='Roboto'
                fontWeight={500}
                whiteSpace='nowrap'
                color='#ffffff'
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </Dropdown>
      <CircleDivider
        bgColor={
          darkModeEnabled
            ? variables.slateColor
            : variables.lighterGrayColor
        }
        mr='5px'
      />
      <LightDarkModeSwitch
        checked={darkModeEnabled}
        onChange={onDarkModeChange}
      />
      <FullscreenViewButton
        immersive
        iconColor={
          darkModeEnabled
            ? variables.headerBackgroundColor
            : variables.slateColor
        }
        bgColor='transparent'
        tooltipPlacement='top-start'
        onChange={onFullscreenModeChange}
      />
    </Box>
  )
}
