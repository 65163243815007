import React from 'react';
import { Box } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import variables from '../../../../variables.module.scss'

interface SettingDisabledCardProps {
  width?: string
  height?: string
  children?: React.ReactNode
}

export const SettingDisabledCard: React.FC<SettingDisabledCardProps> = ({ width = '100%', height = '100%', children }) => {
  return (
    <Box
      width={width}
      height={height}
      padding='8px'
      display='flex'
      alignItems='center'
      justifyContent='center'
      gap='9px'
      borderRadius='6px'
      border={`1px solid ${variables.lightGrayBlueColor}`}
      bgcolor='#ffffff'
      zIndex={2}
      boxSizing='border-box'
    >
      <SettingsOutlinedIcon
        htmlColor={variables.lightGrayColor}
      />
      {children}
    </Box>
  )
}
