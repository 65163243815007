import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  DialogContentText,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ForwardIcon from '@mui/icons-material/Forward'
import CircleIcon from '@mui/icons-material/Circle'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import MultipleStopRoundedIcon from '@mui/icons-material/MultipleStopRounded'
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded'
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded'
import { Edge, ElementPosition, LegendData } from './ServiceMapModalTypes'
import ServiceMapOptions from './ServiceMapOptions'
import ForceGraph from './ForceGraph'
import { useRecoilValue } from 'recoil'

import { Utils } from '../../../helpers/Utils'

import Queryable from '../../UI/Queryable/Queryable'
import NodeTypes from './NodeTypes'
import EdgeTypes from './EdgeTypes'
import { composeNodeFilter, EndpointType } from './NodeFilters'
import composeEdgeFilter from './EdgeFilters'

import entriesAtom from '../../../recoil/entries'
import { FullscreenViewButton } from '../../UI/FullscreenView/FullscreenViewButton'
import fullscreenViewAtom from '../../../recoil/fullscreenView/atom'
import { useInterval } from '../../../helpers/interval'
import variables from '../../../variables.module.scss'
import { CheckboxIcon } from './components/CheckboxIcon'
import { ResourceNameIcon } from '../../UI/Icons/ResourceNameIcon'
import { PodIcon } from '../../UI/Icons/PodIcon'
import { EndpointSliceIcon } from '../../UI/Icons/EndpointSliceIcon'
import { ServiceIcon } from '../../UI/Icons/ServiceIcon'
import { NamespaceIcon } from '../../UI/Icons/NamespaceIcon'
import { NodeIcon } from '../../UI/Icons/NodeIcon'
import {
  MapOutlined,
  RotateLeftRounded,
  SettingsOutlined,
  VisibilityOffRounded
} from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { NamespaceColorSet } from '../../../hooks/useNamespaceColors'

import byteSize from 'byte-size'
import Button from '@mui/material/Button'

import useLocalStorageState from 'use-local-storage-state'
import { LocalStorageKey } from '../../../consts'
import { LoadingBadge } from '../../EntryDetailed/LoadingBadge'

function humanReadableBytes(bytesVal) {
  const byteSizeRes = byteSize(bytesVal)
  return `${Number.parseFloat(byteSizeRes.value).toFixed(2)}${byteSizeRes.unit}`
}

function sliceMapFromKey<T>(map: Map<string, T>, startKey: string): Map<string, T> {
  const entries: [string, T][] = Array.from(map.entries()); // Ensure type safety
  const startIndex = entries.findIndex(([key]) => key === startKey);

  if (startIndex === -1) return new Map(); // Key not found, return empty Map

  return new Map(entries.slice(startIndex + 1)); // Slice and convert back to Map
}

const CHUNK_SIZE = 4000
const CHUNK_TIMEOUT = 900

interface ServiceMapProps {
  renderKey: number
  setRenderKey: (num: number) => void
}

export const ServiceMap: React.FC<ServiceMapProps> = ({ renderKey, setRenderKey }) => {
  const [edgeType, setEdgeType] = useLocalStorageState(LocalStorageKey.ServiceMapEdgeType, {
    defaultValue: 'bandwidth'
  })

  const [nodeType, setNodeType] = useLocalStorageState(LocalStorageKey.ServiceMapNodeType, {
    defaultValue: 'name'
  })

  const [graphData, setGraphData] = useState({ nodes: [], edges: [] });
  const [graphOptions, setGraphOptions] = useState(ServiceMapOptions);
  const namespaceColorGroups = useRef({})

  const [legendData, setLegendData] = useState<LegendData>({});

  const [legendNamespacesHidden, setLegendNamespacesHidden] = useLocalStorageState(LocalStorageKey.ServiceMapNamespacesHidden, {
    defaultValue: []
  })

  const [legendProtocolsHidden, setLegendProtocolsHidden] = useLocalStorageState(LocalStorageKey.ServiceMapProtocolsHidden, {
    defaultValue: []
  })

  const [legendNodesHidden, setLegendNodesHidden] = useState([])

  const [selectedEdges, setSelectedEdges] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);

  const [selectingFromHighlight, setSelectingFromHighlight] = useState(false);
  const [hoveredElementPosition, setHoveredElementPosition] = useState<ElementPosition>({x: 0, y: 0})

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipQuery, setTooltipQuery] = useState("");

  const [showCumulative, setShowCumulative] = useLocalStorageState(LocalStorageKey.ServiceMapShowCumulative, {
    defaultValue: false
  })

  const [showRequests, setShowRequests] = useLocalStorageState(LocalStorageKey.ServiceMapShowRequests, {
    defaultValue: true
  })

  const [showResponses, setShowResponses] = useLocalStorageState(LocalStorageKey.ServiceMapShowResponses, {
    defaultValue: true
  })

  const [maximizeOptionsCard, setMaximizeOptionsCard] = React.useState(true);
  const [maximizeLegendCard, setMaximizeLegendCard] = React.useState(true);

  const [mapDragged, setMapDragged] = React.useState(false);

  const entries = useRecoilValue(entriesAtom)

  const [mapLoading, setMapLoading] = useState(false)
  const [lastChunkIndex, setLastChunkIndex] = useState('')
  const [chunkAccumulator, setChunkAccumulator] = useState([])

  const fullscreenView = useRecoilValue(fullscreenViewAtom)

  const chunkProcessor = useCallback((chunks, position = 0, isWorkload = false) => {
    if (!chunks || chunks.length === 0) {
      return
    }

    if (!isWorkload) {
      setMapLoading(true)
    }

    if (!chunks[position]) {
      if (!isWorkload) {
        setMapLoading(false)
      }
      setLastChunkIndex(chunks[position - 1][chunks[position - 1].length - 1].id)
      return
    }

    setChunkAccumulator(chunks[position])
    setTimeout(() => {
      chunkProcessor(chunks, position + 1)
    }, CHUNK_TIMEOUT)
  }, [])

  const [rerunConversion, setRerunConversion] = useState(null)

  useEffect(() => {
    if (renderKey === null) {
      return
    }

    const chunks = Utils.splitArrToChunks([...entries.values()], CHUNK_SIZE)
    setLastChunkIndex('')

    if (chunks.length === 0) {
      setTimeout(() => {
        setRerunConversion(Date.now())
      }, 1000)
      return
    }

    chunkProcessor(chunks, 0)
  }, [renderKey, rerunConversion])

  const [retryWorkloadProcessing, setRetryWorkloadProcessing] = useState(null)

  useEffect(() => {
    if (lastChunkIndex !== '') {
      const workloadArray = [...sliceMapFromKey(entries, lastChunkIndex).values()]
      if (workloadArray.length === 0) {
        setTimeout(() => {
          setRetryWorkloadProcessing(Date.now())
        }, 1000)
        return
      }
      const chunkedWorkloadArray = Utils.splitArrToChunks(workloadArray, CHUNK_SIZE)
      chunkProcessor(chunkedWorkloadArray, 0, true)
    }
  }, [lastChunkIndex, retryWorkloadProcessing])

  const secondsPassedRef = useRef(0)

  useInterval(() => {
    secondsPassedRef.current += 1
  }, 1000, true)

  const graphRef = useRef(null)
  const graphNetworkApi = useRef(null)
  const nodeMap = useRef({})
  const edgeMap = useRef({})
  const legendMap = useRef({})

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.nodes.update(graphData.nodes)
      graphRef.current.edges.update(graphData.edges)
    }
  }, [graphData])

  useEffect(() => {
    chunkAccumulator.map(entry => {
      let srcLabel = entry.src.name;
      let dstLabel = entry.dst.name;
      let srcKey = `${entry.src.name}.${entry.src.namespace}`;
      let dstKey = `${entry.dst.name}.${entry.dst.namespace}`;

      let srcName = "";
      let dstName = "";
      let srcVerb = "";
      let dstVerb = "";

      switch (nodeType) {
      case NodeTypes.Name:
        if (entry.src.pod) {
          srcVerb = NodeTypes.Pod;
          srcName = entry.src.pod.metadata.name;
        } else if (entry.src.endpointSlice) {
          srcVerb = NodeTypes.EndpointSlice;
          srcName = entry.src.endpointSlice.metadata.name;
        } else if (entry.src.service) {
          srcVerb = NodeTypes.Service;
          srcName = entry.src.service.metadata.name;
        }
        break;
      case NodeTypes.Namespace:
        if (entry.src.pod) {
          srcLabel = entry.src.pod.metadata.namespace;
        } else if (entry.src.endpointSlice) {
          srcLabel = entry.src.endpointSlice.metadata.namespace;
        } else if (entry.src.service) {
          srcLabel = entry.src.service.metadata.namespace;
        }
        srcKey = srcLabel;

        srcVerb = NodeTypes.Namespace;
        srcName = srcLabel;
        break;
      case NodeTypes.Pod:
        if (entry.src.pod) {
          srcLabel = entry.src.pod.metadata.name;
          srcKey = `${entry.src.pod.metadata.name}.${entry.src.pod.metadata.namespace}`;
          srcName = entry.src.pod.metadata.name;
        }

        srcVerb = NodeTypes.Pod;
        break;
      case NodeTypes.EndpointSlice:
        if (entry.src.endpointSlice) {
          srcLabel = entry.src.endpointSlice.metadata.name;
          srcKey = `${entry.src.endpointSlice.metadata.name}.${entry.src.endpointSlice.metadata.namespace}`;
          srcName = entry.src.endpointSlice.metadata.name;
        }

        srcVerb = NodeTypes.EndpointSlice;
        break;
      case NodeTypes.Service:
        if (entry.src.service) {
          srcLabel = entry.src.service.metadata.name;
          srcKey = `${entry.src.service.metadata.name}.${entry.src.service.metadata.namespace}`;
          srcName = entry.src.service.metadata.name;
        }

        srcVerb = NodeTypes.Service;
        break;
      }

      switch (nodeType) {
      case NodeTypes.Name:
        if (entry.dst.pod) {
          dstVerb = NodeTypes.Pod;
          dstName = entry.dst.pod.metadata.name;
        } else if (entry.dst.endpointSlice) {
          dstVerb = NodeTypes.EndpointSlice;
          dstName = entry.dst.endpointSlice.metadata.name;
        } else if (entry.dst.service) {
          dstVerb = NodeTypes.Service;
          dstName = entry.dst.service.metadata.name;
        }
        break;
      case NodeTypes.Namespace:
        if (entry.dst.pod) {
          dstLabel = entry.dst.pod.metadata.namespace;
        } else if (entry.dst.endpointSlice) {
          dstLabel = entry.dst.endpointSlice.metadata.namespace;
        } else if (entry.dst.service) {
          dstLabel = entry.dst.service.metadata.namespace;
        }
        dstKey = dstLabel;

        dstVerb = NodeTypes.Namespace;
        dstName = dstLabel;
        break;
      case NodeTypes.Pod:
        if (entry.dst.pod) {
          dstLabel = entry.dst.pod.metadata.name;
          dstKey = `${entry.dst.pod.metadata.name}.${entry.dst.pod.metadata.namespace}`;
          dstName = entry.dst.pod.metadata.name;
        }

        dstVerb = NodeTypes.Pod;
        break;
      case NodeTypes.EndpointSlice:
        if (entry.dst.endpointSlice) {
          dstLabel = entry.dst.endpointSlice.metadata.name;
          dstKey = `${entry.dst.endpointSlice.metadata.name}.${entry.dst.endpointSlice.metadata.namespace}`;
          dstName = entry.dst.endpointSlice.metadata.name;
        }

        dstVerb = NodeTypes.EndpointSlice;
        break;
      case NodeTypes.Service:
        if (entry.dst.service) {
          dstLabel = entry.dst.service.metadata.name;
          dstKey = `${entry.dst.service.metadata.name}.${entry.dst.service.metadata.namespace}`;
          dstName = entry.dst.service.metadata.name;
        }

        dstVerb = NodeTypes.Service;
        break;
      }

      switch (nodeType) {
      case NodeTypes.WorkerNode:
        if (entry.src.pod && entry.dst.pod) {
          srcLabel = entry.src.pod.spec.nodeName
          srcKey = entry.src.pod.spec.nodeName
          srcName = entry.src.pod.spec.nodeName

          dstLabel = entry.dst.pod.spec.nodeName
          dstKey = entry.dst.pod.spec.nodeName
          dstName = entry.dst.pod.spec.nodeName
        } else {
          srcKey = ""
          dstKey = ""
        }

        srcVerb = NodeTypes.WorkerNode
        dstVerb = NodeTypes.WorkerNode

        break;
      }

      if (srcLabel.length === 0) {
        srcLabel = entry.src.ip;
      }
      if (dstLabel.length === 0) {
        dstLabel = entry.dst.ip;
      }

      let srcId: number;
      let dstId: number;

      const keyArr: string[] = [srcKey, dstKey].filter((keyElem) => keyElem.length > 0);
      const labelArr: string[] = [srcLabel, dstLabel];
      const nameArr: string[] = [srcName, dstName];
      const namespaceArr: string[] = [entry.src.namespace, entry.dst.namespace];
      const namespaceColorSetArr: NamespaceColorSet[] = [entry.src.namespaceColorSet, entry.dst.namespaceColorSet];
      const verbArr: string[] = [srcVerb, dstVerb];

      for (let i = 0; i < keyArr.length; i++) {
        const nodeKey: string = keyArr[i];
        const namespace = namespaceArr[i];

        if (!(namespace in namespaceColorGroups.current) && namespace?.length > 0) {
          const colorSet = {
            background: namespaceColorSetArr[i].color,
            border: variables.grayColor
          }

          const newGroup = {
            key: namespace,
            font: {
              color: variables.fontColor,
              background: 'rgba(255,255,255,0.85)',
            },
            color: {
              highlight: {...colorSet, border: variables.blackColor},
              hover: {...colorSet, border: variables.blackColor},
              ...colorSet
            },
            filter: `src.namespace == "${namespace}" or dst.namespace == "${namespace}"`,
          }

          namespaceColorGroups.current[namespace] = newGroup

          setGraphOptions(prevOptions => {
            const updatedOptions = {...prevOptions}
            updatedOptions.groups[namespace] = newGroup
            return updatedOptions
          })
        }

        if (nodeKey in nodeMap.current) {
          nodeMap.current[nodeKey].value++;
        } else {
          let endpointType: EndpointType = null

          if (i === 0) {
            endpointType = EndpointType.Source
          } else if (i === 1) {
            endpointType = EndpointType.Destination
          }

          nodeMap.current[nodeKey] = {
            id: Object.keys(nodeMap.current).length,
            value: 1,
            label: `${labelArr[i]}`,
            filter: composeNodeFilter(nodeType, entry, endpointType),
            group: namespace,
            title: nodeKey,
            name: nameArr[i],
            namespace: namespace,
            verb: verbArr[i],
            color: namespaceColorGroups.current[namespace]?.color ?? {
              background: variables.lightGrayColor,
              border: variables.lightGrayColor,
            },
            hidden: legendNamespacesHidden.includes(namespace) || legendNodesHidden.includes(Object.keys(nodeMap.current).length),
            chosen: {
              node: (values) => {
                values.borderWidth = 3
                values.size = values.size + 20
              },
              label: (values) => {
                values.size = values.size + 10
              }
            }
          };
        }

        if (i == 0)
          srcId = nodeMap.current[nodeKey].id;
        else
          dstId = nodeMap.current[nodeKey].id;
      }

      const edgeKey = `${entry.proto.abbr}_${srcId}_${dstId}`;

      if (edgeKey in edgeMap.current) {
        if (edgeMap.current[edgeKey].dashes === false) edgeMap.current[edgeKey].dashes = entry.error !== null;
      } else {
        edgeMap.current[edgeKey] = {
          id: Object.keys(edgeMap.current).length,
          from: srcId,
          to: dstId,
          value: 1,
          count: 1,
          cumulative: 1,
          label: '',
          filter: composeEdgeFilter(nodeType, entry),
          protoFull: entry.proto,
          proto: entry.proto.abbr,
          title: entry.proto.longName,
          color: entry.proto.backgroundColor,
          dashes: entry.error !== null,
          hidden: legendProtocolsHidden.includes(entry.proto.abbr),
          chosen: {
            label: (values) => {
              values.size = 24
            }
          }
        }
      }

      const onlyTotalSizeAvailable = entry.size > 0 &&
        entry.requestSize === 0 && entry.responseSize === 0;

      switch (edgeType) {
      case EdgeTypes.Bandwidth:
        if (showRequests)
          edgeMap.current[edgeKey].cumulative += entry.requestSize;
        if (showResponses)
          edgeMap.current[edgeKey].cumulative += entry.responseSize;

        if ((showRequests || showResponses) && onlyTotalSizeAvailable) {
          edgeMap.current[edgeKey].cumulative += entry.size;
        }

        if (showCumulative)
          edgeMap.current[edgeKey].value = edgeMap.current[edgeKey].cumulative;
        else if (secondsPassedRef.current > 0)
          edgeMap.current[edgeKey].value = edgeMap.current[edgeKey].cumulative / secondsPassedRef.current;

        edgeMap.current[edgeKey].label = Utils.isReadableNumber(edgeMap.current[edgeKey].value) ?
          `<b>${humanReadableBytes(edgeMap.current[edgeKey].value)}${!showCumulative ? '/s' : ''}</b>` : ''
        break;
      case EdgeTypes.Throughput:
        edgeMap.current[edgeKey].cumulative++;

        if (showCumulative)
          edgeMap.current[edgeKey].value = edgeMap.current[edgeKey].cumulative;
        else if (secondsPassedRef.current > 0)
          edgeMap.current[edgeKey].value = Math.round(edgeMap.current[edgeKey].cumulative / secondsPassedRef.current * 100) / 100;

        edgeMap.current[edgeKey].label = Utils.isReadableNumber(edgeMap.current[edgeKey].value) ?
          `<b>${edgeMap.current[edgeKey].value}</b>${!showCumulative ? '/s' : ''}` : '';

        break;
      case EdgeTypes.Latency:
        edgeMap.current[edgeKey].value = Math.round((entry.elapsedTime + edgeMap.current[edgeKey].value * edgeMap.current[edgeKey].count) / (edgeMap.current[edgeKey].count + 1) * 100) / 100;
        edgeMap.current[edgeKey].label = Utils.isReadableNumber(edgeMap.current[edgeKey].value) ?
          `<b>${edgeMap.current[edgeKey].value} μs</b>` : '';
        break;
      }

      edgeMap.current[edgeKey].count++;
    });

    setGraphData({
      nodes: Object.values(nodeMap.current),
      edges: Object.values(edgeMap.current),
    });

    Object.values(edgeMap.current).forEach((edge: Edge) => {
      if (!legendMap.current[edge.protoFull.abbr]) {
        legendMap.current[edge.protoFull.abbr] = edge.protoFull
      }
    })

    const legendMapSorted = Object.keys(legendMap.current).sort().reduce(
      (obj, key) => {
        obj[key] = legendMap.current[key];
        return obj;
      },
      {}
    );

    setLegendData(legendMapSorted);
  }, [
    chunkAccumulator,
    edgeType,
    nodeType,
    showCumulative,
    showRequests,
    showResponses,
  ]);

  useEffect(() => {
    for (const key in nodeMap.current) {
      nodeMap.current[key].hidden = legendNamespacesHidden.includes(nodeMap.current[key].namespace) || legendNodesHidden.includes(nodeMap.current[key].id)
    }

    for (const key in edgeMap.current) {
      edgeMap.current[key].hidden = legendProtocolsHidden.includes(edgeMap.current[key].proto)
    }

    setGraphData({
      nodes: Object.values(nodeMap.current),
      edges: Object.values(edgeMap.current),
    });
  }, [
    legendNamespacesHidden,
    legendProtocolsHidden,
    legendNodesHidden,
  ])

  useEffect(() => {
    if (graphData?.nodes?.length === 0) return;
    const options = { ...graphOptions };
    setGraphOptions(options);
  }, [graphData?.nodes?.length]);

  const handleEdgeChange = (event: SelectChangeEvent) => {
    setSelectedEdges([]);
    setGraphData({
      nodes: [],
      edges: [],
    })

    nodeMap.current = {}
    edgeMap.current = {}

    setEdgeType(event.target.value as string);

    setRenderKey(-Date.now())

    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  };

  const handleNodeChange = (event: SelectChangeEvent) => {
    setSelectedNodes([]);
    setGraphData({
      nodes: [],
      edges: [],
    });
    nodeMap.current = {}
    edgeMap.current = {}

    setNodeType(event.target.value as string);

    setRenderKey(-Date.now())

    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  };

  const events = {
    select: ({nodes, edges}) => {
      setSelectedEdges(edges);
      setSelectedNodes(nodes);
    },
    hoverNode: ({node: nodeId, event}) => {
      if (selectingFromHighlight) {
        return
      }

      setTooltipQuery(graphData.nodes[nodeId].filter)
      setHoveredElementPosition({x: event.offsetX, y: event.offsetY})
      setShowTooltip(true)
    },
    blurNode: () => {
      if (selectingFromHighlight) {
        return
      }

      setShowTooltip(false)
      setTooltipQuery("")
      setHoveredElementPosition({x: 0, y: 0})
    },
    hoverEdge: ({edge: edgeId, event}) => {
      if (selectingFromHighlight) {
        return
      }

      setTooltipQuery(graphData.edges[edgeId].filter)
      setHoveredElementPosition({x: event.offsetX, y: event.offsetY})
      setShowTooltip(true)
    },
    blurEdge: () => {
      if (selectingFromHighlight) {
        return
      }

      setShowTooltip(false)
      setTooltipQuery("")
      setHoveredElementPosition({x: 0, y: 0})
    }
  }

  const handleShowCumulativeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCumulative(event.target.checked);
    setGraphData({
      nodes: [],
      edges: [],
    })
    nodeMap.current = {}
    edgeMap.current = {}

    setRenderKey(-Date.now())
  };

  const handleShowRequestsCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowRequests(event.target.checked);
    setGraphData({
      nodes: [],
      edges: [],
    })
    nodeMap.current = {}
    edgeMap.current = {}

    setRenderKey(-Date.now())
  };

  const handleShowResponsesCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowResponses(event.target.checked);
    setGraphData({
      nodes: [],
      edges: [],
    })
    nodeMap.current = {}
    edgeMap.current = {}

    setRenderKey(-Date.now())
  };

  const mapContainerRef = useRef(null);

  const [graphHeight, setGraphHeight] = React.useState(650)

  function setGraphNetworkApi(networkApi) {
    graphNetworkApi.current = networkApi
  }

  const handleResize = () => {
    setGraphHeight(mapContainerRef.current.clientHeight)
    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  };

  useLayoutEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (graphNetworkApi.current) {
        graphNetworkApi.current.fit()
      }
    };
  }, []);

  useEffect(() => {
    setGraphHeight(mapContainerRef.current.clientHeight)

    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  }, [fullscreenView])

  useEffect(() => {
    setTimeout(() => {
      if (graphNetworkApi.current) {
        graphNetworkApi.current.fit()
      }
    }, 400)
  }, [graphNetworkApi.current])

  useEffect(() => {
    if (!graphNetworkApi.current) {
      return
    }

    if (graphData.nodes.length > 0) {
      if (selectedNodes.every(v => graphNetworkApi.current.body.data.nodes.getIds().includes(v))) {
        graphNetworkApi.current.selectNodes(selectedNodes)
      } else {
        graphNetworkApi.current.selectNodes([])
      }
    }
  }, [selectedNodes, graphData.nodes])

  useEffect(() => {
    if (!graphNetworkApi.current) {
      return
    }

    if (graphData.edges.length > 0) {
      if (selectedEdges.every(v => graphNetworkApi.current.body.data.edges.getIds().includes(v))) {
        graphNetworkApi.current.selectEdges(selectedEdges)
      } else {
        graphNetworkApi.current.selectEdges([])
      }
    }
  }, [selectedEdges, graphData.edges])

  return (
    <div
      ref={mapContainerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "40vh",
        flexGrow: 1,
        backgroundColor: variables.mainBackgroundColor,
        color: '#000',
        overflow: 'hidden',
        cursor: mapDragged ? 'grabbing' : 'grab',
      }}
      onDoubleClick={() => {
        if (graphNetworkApi.current) {
          graphNetworkApi.current.fit({
            animation: true
          })
        }
      }}
      onMouseDown={() => setMapDragged(true)}
      onMouseUp={() => setMapDragged(false)}
    >
      <Box position='absolute' top='5px' right='5px' zIndex={1}>
        <FullscreenViewButton />
      </Box>
      {!maximizeOptionsCard && (
        <Box position='absolute' top='5px' left='5px' zIndex={1}>
          <Tooltip
            title='Map Settings - Switch node/edge types'
            placement='bottom-end'
            arrow
          >
            <IconButton onClick={() => {
              setMaximizeOptionsCard(true);
            }} style={{
              margin: "2px",
              padding: "4px",
              borderRadius: '4px',
              backgroundColor: variables.blueColor
            }}>
              <SettingsOutlined
                htmlColor={variables.mainBackgroundColor}
                sx={{
                  fontSize: '28px',
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {maximizeOptionsCard && <Card sx={{
        maxWidth: '300px',
        position: "absolute",
        top: '8px',
        left: '8px',
        zIndex: 1,
        boxShadow: 'none',
        border: `1px solid ${variables.lightBlueColor}`,
        borderRadius: '6px',
      }}>
        <CardContent sx={{
          padding: '20px',
          paddingBottom: `15px !important`
        }}>
          <Box
            boxSizing='border-box'
            paddingBottom='10px'
            marginBottom='20px'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            borderBottom={`1px solid ${variables.lightGrayBlueColor}`}
          >
            <span
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.fontColor
              }}
            >
              Settings
            </span>
            <IconButton onClick={() => {
              setMaximizeOptionsCard(false);
            }} style={{
              padding: "2px",
              border: `1px solid ${variables.lightBlueColor}`,
              borderRadius: '50px',
              backgroundColor: variables.dataBackgroundColor
            }}>
              <CloseRoundedIcon
                htmlColor={variables.blueColor}
                sx={{
                  fontSize: '16px'
                }}
              />
            </IconButton>
          </Box>
          <FormControl fullWidth size="small">
            <span
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                marginBottom: '8px',
                color: variables.slateColor,
              }}
            >
              Edges
            </span>
            <Select
              labelId="edge-select-label"
              id="edge-select"
              value={edgeType}
              IconComponent={() => <ExpandMoreRoundedIcon htmlColor={variables.blueColor} sx={{ mr: '5px' }} />}
              onChange={handleEdgeChange}
              sx={{
                borderRadius: '6px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${variables.lighterGrayColor}`,
                }
              }}
            >
              <MenuItem value={EdgeTypes.Bandwidth}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <MultipleStopRoundedIcon htmlColor={variables.blueColor} sx={{ fontSize: '20px' }} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>Bandwidth</span>
                </Box>
              </MenuItem>
              <MenuItem value={EdgeTypes.Throughput}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <AvTimerRoundedIcon htmlColor={variables.blueColor} sx={{ fontSize: '20px' }} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>Throughput</span>
                </Box>
              </MenuItem>
              <MenuItem value={EdgeTypes.Latency}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <HistoryToggleOffRoundedIcon htmlColor={variables.blueColor} sx={{ fontSize: '20px' }} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>Latency</span>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth size="small" sx={{
            marginTop: '20px',
            paddingBottom: '10px',
          }}>
            <span
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                marginBottom: '8px',
                color: variables.slateColor,
              }}
            >
              Nodes
            </span>
            <Select
              labelId="node-select-label"
              id="node-select"
              value={nodeType}
              IconComponent={() => <ExpandMoreRoundedIcon htmlColor={variables.blueColor} sx={{ mr: '5px' }} />}
              onChange={handleNodeChange}
              sx={{
                borderRadius: '6px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${variables.lighterGrayColor}`,
                }
              }}
            >
              <MenuItem value={NodeTypes.Name}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <ResourceNameIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Resolved Name
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.Namespace}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <NamespaceIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Namespace
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.Pod}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <PodIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Pod
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.EndpointSlice}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <EndpointSliceIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    EndpointSlice
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.Service}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <ServiceIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Service
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.WorkerNode}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <NodeIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Node
                  </span>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          {(edgeType === EdgeTypes.Bandwidth || edgeType === EdgeTypes.Throughput) && <FormControlLabel
            label={
              <DialogContentText sx={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.slateColor,
              }}>
                Show cumulative {edgeType === EdgeTypes.Bandwidth ? EdgeTypes.Bandwidth : ""}{edgeType === EdgeTypes.Throughput ? EdgeTypes.Throughput : ""}
              </DialogContentText>
            }
            control={
              <Checkbox
                checked={showCumulative}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIcon checked />}
                onChange={handleShowCumulativeCheck}
              />
            }
            style={{marginTop: "5px"}}
            labelPlacement="end"
          />}

          {edgeType === EdgeTypes.Bandwidth && <FormControlLabel
            label={
              <DialogContentText sx={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.slateColor,
              }}>
                Include request sizes
              </DialogContentText>
            }
            control={
              <Checkbox
                checked={showRequests}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIcon checked />}
                onChange={handleShowRequestsCheck}
              />
            }
            labelPlacement="end"
          />}

          {edgeType === EdgeTypes.Bandwidth && <FormControlLabel
            label={
              <DialogContentText sx={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.slateColor,
              }}>
                Include response sizes
              </DialogContentText>
            }
            control={
              <Checkbox
                checked={showResponses}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIcon checked />}
                onChange={handleShowResponsesCheck}
              />
            }
            labelPlacement="end"
          />}
        </CardContent>
      </Card>}

      {Object.keys(legendData).length > 0 && (
        <>
          {!maximizeLegendCard && (
            <Box position="absolute" bottom="5px" left="5px" zIndex={1}>
              <Tooltip
                title='Map Legend - Protocols/Namespaces'
                placement='top-start'
                arrow
              >
                <IconButton onClick={() => {
                  setMaximizeLegendCard(true)
                }} style={{
                  margin: '2px',
                  padding: '4px',
                  borderRadius: '4px',
                  backgroundColor: variables.blueColor
                }}>
                  <MapOutlined
                    htmlColor={variables.mainBackgroundColor}
                    sx={{
                      fontSize: '28px'
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Box sx={{
            position: 'absolute',
            left: '8px',
            bottom: '5px',
            zIndex: 1,
            boxShadow: 'none',
            borderRadius: '4px',
            background: 'transparent',
            maxWidth: '90%',
            width: '90%'
          }}>
            {maximizeLegendCard && (
              <Box
                boxSizing='border-box'
                marginLeft='5px'
                display='flex'
                alignItems='center'
                gap='10px'
              >
                <IconButton onClick={() => {
                  setMaximizeLegendCard(false);
                }} style={{
                  padding: "2px",
                  border: `1px solid ${variables.lightBlueColor}`,
                  borderRadius: '50px',
                  backgroundColor: variables.dataBackgroundColor
                }}>
                  <CloseRoundedIcon
                    htmlColor={variables.blueColor}
                    sx={{
                      fontSize: '16px'
                    }}
                  />
                </IconButton>
                <span
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: variables.fontColor
                  }}
                >
                  Legend
                </span>
                {(legendNamespacesHidden.length > 0 || legendProtocolsHidden.length > 0) && <Button
                  variant='contained'
                  className={`themeButton white`}
                  size='small'
                  startIcon={
                    <RotateLeftRounded htmlColor={variables.blueColor} sx={{ mr: '5px' }} />
                  }
                  sx={{
                    marginLeft: '10px !important',
                    padding: '3px 6px',
                    border: `1px solid ${variables.lightBlueColor} !important`,
                    backgroundColor: `${variables.dataBackgroundColor} !important`,
                    boxShadow: 'none !important',
                  }}
                  onClick={() => {
                    setLegendNamespacesHidden([])
                    setLegendProtocolsHidden([])
                    setLegendNodesHidden([])

                    setTimeout(() => {
                      try {
                        if (graphNetworkApi.current) {
                          graphNetworkApi.current.fit({
                            animation: true
                          })
                        }
                      } catch (e) {
                        console.log('Focusing on service map edges failed due to simultaneous map dimensions changing')
                      }
                    }, 500)
                  }}
                >
                  <span style={{ fontWeight: 500 }}>Reset</span>
                </Button>}
              </Box>
            )}
            {maximizeLegendCard && (
              <Box
                boxSizing='border-box'
                paddingTop='20px'
                paddingLeft='10px'
                sx={{
                  overflowX: 'auto',
                  overflowY: 'hidden'
                }}
              >
                <List dense disablePadding sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexFlow: 'row',
                  gap: '10px',
                }}>
                  {
                    Object.keys(graphOptions.groups).map(function(key) {
                      if (!key) return;

                      const group = graphOptions.groups[key];
                      const primaryStyle = {
                        color: variables.grayColor,
                        fontFamily: 'Source Sans Pro, sans-serif',
                        fontWeight: 700,
                      };
                      const secondaryStyle = {
                        color: group.color.background,
                      };

                      return <ListItem key={key} disableGutters disablePadding sx={{
                        backgroundColor: variables.lighterGrayBlueColor,
                        width: 'unset',
                        padding: '5px 8px',
                        marginBottom: '5px',
                        border: `
                          1px ${legendNamespacesHidden.includes(key) ? 'dashed' : 'solid'}
                          ${legendNamespacesHidden.includes(key) ? variables.lightGrayColor : variables.lightGrayBlueColor}
                        `,
                        borderRadius: '4px',
                        '&:hover': {
                          border: `
                            1px ${legendNamespacesHidden.includes(key) ? 'dashed' : 'solid'}
                            ${group.color.background ?? variables.lightGrayColor}`
                        },
                        cursor: 'pointer',
                        opacity: legendNamespacesHidden.includes(key) ? 0.7 : 1
                      }}
                      onClick={() => {
                        if (legendNamespacesHidden.includes(key)) {
                          setLegendNamespacesHidden(namespaces => [...namespaces].filter(ns => ns != key))

                          setTimeout(() => {
                            try {
                              if (graphNetworkApi.current) {
                                graphNetworkApi.current.fit({
                                  animation: true
                                })
                              }
                            } catch (e) {
                              console.log('Focusing on service map edges failed due to simultaneous map dimensions changing')
                            }
                          }, 500)
                        } else {
                          setLegendNamespacesHidden(namespaces => [...namespaces, group.key])

                          try {
                            if (graphNetworkApi.current) {
                              graphNetworkApi.current.fit({
                                animation: true
                              })
                            }
                          } catch (e) {
                            console.log('Focusing on service map nodes failed due to simultaneous map dimensions changing')
                          }
                        }
                      }}>
                        <Queryable
                          formatted={false}
                          query={`src.namespace == "${key}" or dst.namespace == "${key}"`}
                          iconStyle={{ position: 'absolute', bottom: '8px', left: '-10px'}}
                          tooltipStyle={{ position: 'absolute', top: '-2px', left: '5px', whiteSpace: 'nowrap' }}
                        >
                          <ListItemIcon sx={{ minWidth: '28px' }}>
                            {legendNamespacesHidden.includes(key) ? (
                              <VisibilityOffRounded
                                htmlColor={variables.slateColor}
                                sx={{ fontSize: '16px' }}
                              />
                            ) : (
                              <CircleIcon sx={{
                                fontSize: '15px',
                                color: group.color.background ?? variables.lightGrayColor,
                                borderRadius: '50px',
                                border: `1px solid ${group.color.background ?? variables.lightGrayColor}`
                              }} />
                            )}

                          </ListItemIcon>
                          <ListItemText
                            primary={key}
                            primaryTypographyProps={{ style: primaryStyle }}
                            secondaryTypographyProps={{ style: secondaryStyle }}
                            sx={{
                              whiteSpace: 'nowrap',
                              margin: 0
                            }}
                          />
                        </Queryable>
                      </ListItem>
                    })
                  }
                </List>
                <List dense disablePadding sx={{
                  marginTop: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  flexFlow: 'row',
                  gap: '10px',
                }}>
                  {
                    Object.keys(legendData).map(function(key) {
                      const proto = legendData[key]
                      const primaryStyle = {
                        color: proto.backgroundColor,
                        fontFamily: 'Source Sans Pro, sans-serif',
                        fontWeight: 'bold',
                      }

                      return (
                        <ListItem key={key} disableGutters disablePadding sx={{
                          backgroundColor: variables.lighterGrayBlueColor,
                          width: 'unset',
                          marginBottom: '5px',
                          border: `
                            1px ${legendProtocolsHidden.includes(proto.abbr) ? 'dashed' : 'solid'}
                            ${legendProtocolsHidden.includes(proto.abbr) ? variables.lightGrayColor : variables.lightGrayBlueColor}
                          `,
                          borderRadius: '4px',
                          '&:hover': {
                            border: `
                            1px ${legendProtocolsHidden.includes(proto.abbr) ? 'dashed' : 'solid'}
                            ${proto.backgroundColor}`
                          },
                          cursor: 'pointer',
                          opacity: legendProtocolsHidden.includes(proto.abbr) ? 0.7 : 1
                        }}
                        onClick={() => {
                          if (legendProtocolsHidden.includes(proto.abbr)) {
                            const nodesToShow = []
                            graphData.edges.forEach(edge => {
                              if (edge.proto === proto.abbr) {
                                if (graphNetworkApi) {
                                  if (edge.from !== undefined) {
                                    nodesToShow.push(edge.from)
                                  }
                                  if (edge.to !== undefined) {
                                    nodesToShow.push(edge.to)
                                  }
                                }
                              }
                            })

                            setLegendNodesHidden(hiddenNodes => [...hiddenNodes].filter(node => !nodesToShow.includes(node)))
                            setLegendProtocolsHidden(protocols => [...protocols].filter(hiddenProto => hiddenProto !== proto.abbr))

                            setTimeout(() => {
                              try {
                                if (graphNetworkApi.current) {
                                  graphNetworkApi.current.fit({
                                    animation: true
                                  })
                                }
                              } catch (e) {
                                console.log('Focusing on service map edges failed due to simultaneous map dimensions changing')
                              }
                            }, 500)
                          } else {
                            const newHiddenNodes = []
                            graphData.edges.forEach(edge => {
                              if (edge.proto === proto.abbr) {
                                if (graphNetworkApi) {
                                  if (edge.from !== undefined) {
                                    newHiddenNodes.push(edge.from)
                                  }
                                  if (edge.to !== undefined) {
                                    newHiddenNodes.push(edge.to)
                                  }
                                }
                              }
                            })

                            setLegendNodesHidden(hiddenNodes => [...hiddenNodes, newHiddenNodes])
                            setLegendProtocolsHidden(protocols => [...protocols, proto.abbr])

                            try {
                              if (graphNetworkApi.current) {
                                graphNetworkApi.current.fit({
                                  animation: true
                                })
                              }
                            } catch (e) {
                              console.log('Focusing on service map edges failed due to simultaneous map dimensions changing')
                            }
                          }
                        }}>
                          <Queryable
                            query={proto.macro}
                            iconStyle={{ position: 'absolute', bottom: '6px', left: '-10px'}}
                            tooltipStyle={{ position: 'absolute', top: '0px', left: '5px', whiteSpace: 'nowrap' }}
                          >
                            <Box
                              boxSizing='border-box'
                              padding='0 5px'
                              display='flex'
                              alignItems='center'
                            >
                              <ListItemIcon sx={{ minWidth: '24px' }}>
                                {legendProtocolsHidden.includes(proto.abbr) ? (
                                  <VisibilityOffRounded
                                    htmlColor={variables.slateColor}
                                    sx={{ fontSize: '16px' }}
                                  />
                                ) : (
                                  <ForwardIcon sx={{
                                    fontSize: '18px',
                                    color: proto.backgroundColor
                                  }} />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={proto.abbr}
                                primaryTypographyProps={{ style: primaryStyle }}
                              />
                            </Box>
                          </Queryable>
                        </ListItem>
                      )
                    })
                  }
                </List>
              </Box>
            )}
          </Box>
        </>
      )}
      {mapLoading && (
        <LoadingBadge absoluteTop='10px' absoluteLeft='50%' text='Calculation in progress...' />
      )}
      {
        Object.keys(nodeMap.current).length > 0 && <ForceGraph
          graphRef={graphRef}
          getNetwork={setGraphNetworkApi}
          graph={{ nodes: [], edges: [] }}
          options={{ ...graphOptions, height: `${graphHeight}px` }}
          events={events}
          modalRef={mapContainerRef}
          setSelectedNodes={setSelectedNodes}
          setSelectedEdges={setSelectedEdges}
          enableSelectingFromHighlight={false}
          setSelectingFromHighlight={setSelectingFromHighlight}
        />
      }
      {showTooltip && <Queryable
        query={tooltipQuery}
        joinCondition="or"
        displayImmediately={showTooltip}
        onAdded={() => setShowTooltip(false)}
        flipped={true}
        tooltipStyle={{ top: 0, marginTop: "-5px", marginLeft: "25px" }}
        style={{
          position: "absolute",
          left: `${hoveredElementPosition.x}px`,
          top: `${hoveredElementPosition.y}px`,
          whiteSpace: "nowrap",
          zIndex: 1400
        }}
      >
      </Queryable>}
    </div>
  );
}
