import { HubWsUrl } from '../consts'
import { appAuthEnabled, appDefaultAuthTypeEnabled } from '../types/global'
import { getRefreshToken, getSessionToken } from '@descope/react-sdk'
import { useRecoilState } from 'recoil'
import { useWebSocketEventHandlers } from '../components/TrafficViewer/useWebSocketEventHandlers'
import useThrottling from './useThrottling'
import { useInterval } from '../helpers/interval'
import scriptLogWsReadyStateAtom from '../recoil/scriptLogWsReadyState'
import { useCallback, useState } from 'react'

export const THROTTLE_WS_STATE_CHANGE_MS = 1000

export let scriptLogWsConn: WebSocket;

interface WebsocketOptions {
  wsUrl: string
  withAuth: boolean
  withIdleTimer: boolean
  onMessage: (e) => void
  onClose: (e) => void
  onError: (e) => void
}

const useScriptLogWebSocket = (options: WebsocketOptions = {
  wsUrl: HubWsUrl,
  withAuth: true,
  withIdleTimer: true,
  onMessage: null,
  onClose: null,
  onError: null,
}) => {
  const [readyState, setReadyState] = useRecoilState(scriptLogWsReadyStateAtom)
  const [secWsProtoAuth, ] = useState([])

  const { onMessage: onMessageDefault, onClose: onCloseDefault, onError: onErrorDefault } = useWebSocketEventHandlers()

  useInterval(() => {
    if (!scriptLogWsConn) {
      return
    }

    setReadyState(scriptLogWsConn.readyState)
  }, 500, true)

  const open = useCallback(() => {
    const url = new URL(options.wsUrl)

    if (options.withAuth && appAuthEnabled() && appDefaultAuthTypeEnabled()) {
      url.searchParams.set('session-token', getSessionToken());
      url.searchParams.set('refresh-token', getRefreshToken());
    }

    try {
      scriptLogWsConn = new WebSocket(url, secWsProtoAuth)
      scriptLogWsConn.onmessage = options.onMessage ?? onMessageDefault
      scriptLogWsConn.onerror = options.onError ?? onErrorDefault
      scriptLogWsConn.onclose = options.onClose ?? onCloseDefault
    } catch (e) {
      console.error(e)
    }
  }, [secWsProtoAuth])

  const send = (data: string | ArrayBufferLike | Blob | ArrayBufferView) => {
    if (scriptLogWsConn && scriptLogWsConn.readyState === WebSocket.OPEN) {
      scriptLogWsConn.send(data)
    }
  }

  const close = () => {
    if (scriptLogWsConn) {
      scriptLogWsConn.close(1000)
    }
  }

  const reopen = useThrottling(() => {
    close()
    setTimeout(() => {
      open()
    }, 500)
  }, THROTTLE_WS_STATE_CHANGE_MS)

  const toggle = useThrottling(() => {
    if (!scriptLogWsConn) {
      return
    }

    if (scriptLogWsConn.readyState === WebSocket.OPEN) {
      close()
    } else {
      open()
    }
  }, THROTTLE_WS_STATE_CHANGE_MS)

  return {
    open,
    send,
    close,
    reopen,
    toggle,
    readyState,
    setReadyState,
  }
}

export default useScriptLogWebSocket
