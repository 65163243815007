import React, { useCallback, useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import variables from '../../../../../variables.module.scss'
import Markdown from 'markdown-to-jsx'
import { InlineCode } from '../../../../UI/InlineCode/InlineCode'

import { atomOneDark, atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript'

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import { CheckRounded, ContentCopy } from '@mui/icons-material'

SyntaxHighlighter.registerLanguage('javascript', js)

// eslint-disable-next-line react/prop-types
export const SyntaxWrapper = ({ children, darkModeEnabled }) => {
  const [contentCopied, setContentCopied] = useState(false)

  useEffect(() => {
    if (contentCopied) {
      setTimeout(() => {
        setContentCopied(false)
      }, 2000)
    }
  }, [contentCopied])

  const handleCopyContent = useCallback((content: string) => {
    if (contentCopied) {
      return
    }

    navigator.clipboard.writeText(content).then(
      () => setContentCopied(true)
    )
  }, [contentCopied])

  // eslint-disable-next-line react/prop-types
  if (typeof children === 'string' && !children.includes('\n') && children.length <= 100) {
    return (
      <Box
        boxSizing='border-box'
        display='inline-block'
      >
        <IconButton
          onClick={() => handleCopyContent(children)}
          sx={{
            padding: '2px'
          }}
        >
          {!contentCopied && (
            <ContentCopy
              htmlColor={darkModeEnabled ? variables.lightSlateColor : variables.darkSlateColor}
              sx={{
                fontSize: '14px'
              }}
            />
          )}
          {contentCopied && (
            <CheckRounded
              htmlColor={variables.successColor}
              sx={{
                fontSize: '14px'
              }}
            />
          )}
        </IconButton>
        <InlineCode darkMode={darkModeEnabled}>{children}</InlineCode>
      </Box>
    )
  }

  // eslint-disable-next-line react/prop-types
  return (
    <Box
      boxSizing='border-box'
      position='relative'
    >
      <IconButton
        onClick={() => handleCopyContent(children)}
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
        }}
      >
        {!contentCopied && (
          <ContentCopy
            htmlColor={darkModeEnabled ? variables.lightSlateColor : variables.darkSlateColor}
            sx={{
              fontSize: '14px'
            }}
          />
        )}
        {contentCopied && (
          <CheckRounded
            htmlColor={variables.successColor}
            sx={{
              fontSize: '14px'
            }}
          />
        )}
      </IconButton>
      <SyntaxHighlighter
        language='javascript'
        showLineNumbers
        style={darkModeEnabled ? atomOneDark : atomOneLight}
        customStyle={{
          borderRadius: '6px',
        }}
      >
        {children}
      </SyntaxHighlighter>
    </Box>
  )
}

interface AiResponseProps {
  maxWidth: number
  text: string
  darkModeEnabled: boolean
}

export const AiResponse: React.FC<AiResponseProps> = ({ maxWidth, text, darkModeEnabled }) => {
  return (
    <Box
      boxSizing='border-box'
      position='relative'
      display='flex'
      alignItems='flex-start'
      gap='24px'
      marginLeft='62px'
      padding='8px 16px'
      width='100%'
      alignSelf='flex-start'
      flex='1'
      bgcolor='transparent'
      maxWidth={maxWidth}
    >
      <span
        style={{
          maxWidth: maxWidth,
          marginTop: '8px',
          fontSize: '14px',
          fontWeight: 400,
          fontFamily: 'Noto Sans, sans-serif',
          lineHeight: '24px',
          color: darkModeEnabled ? variables.headerBackgroundColor : variables.grayColor,
        }}
      >
        <Markdown
          options={{
            overrides: {
              code: {
                component: SyntaxWrapper,
                props: {
                  darkModeEnabled,
                }
              },
              ol: ({ children, ...props }) => {
                return <ol
                  {...props}
                  style={{
                    paddingLeft: '16px',
                  }}
                >
                  {children}
                </ol>
              }
            }
          }}
        >
          {text.replaceAll('->ks-ai-script-code-changed<-', '')}
        </Markdown>
      </span>
    </Box>
  )
}
