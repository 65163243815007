import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Box, IconButton } from '@mui/material'
import Sidebar from './Sidebar/Sidebar'
import { useMonaco } from '@monaco-editor/react';

import styles from './ScriptingView.module.sass'
import variables from '../../../variables.module.scss'

import CodeRoundedIcon from '@mui/icons-material/CodeRounded'

import 'monaco-themes/themes/GitHub Light.json'
import 'monaco-themes/themes/GitHub Dark.json'

import {
  EXAMPLE_SCRIPTS, SCRIPT_BOOTSTRAP, SCRIPT_USE_AI_ASSISTANT,
  TEMPLATE_SCRIPT_TITLES
} from '../../modals/ScriptingModal/ScriptingExamples'
import { ScriptingTemplates } from './ScriptingTemplates/ScriptingTemplates'
import { SidebarScript } from './SidebarScript/SidebarScript'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import scriptsAtom from '../../../recoil/scripts'
import { getScripts } from './api/getScripts'
import { Script } from '../../modals/ScriptingModal/ScriptingTypes'
import { SidebarBanner } from './SidebarBanner/SidebarBanner'
import { putUpdateScript } from './api/putUpdateScript'
import { postCreateScript } from './api/postCreateScript'
import { deleteScript } from './api/deleteScript'
import { SidebarScriptGroup } from './SidebarScriptGroup/SidebarScriptGroup'
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded'
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded'
import scriptIndexOnLoadAtom from '../../../recoil/scriptIndexOnLoad'
import scriptAllWsLogsAtom from '../../../recoil/scriptAllWsLogs'
import { authorizeAction, AUTHZ_ACTIONS } from '../../UI/Auth/SamlAuth/Authorization'

import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels'
import { FloatingSaveChangesButton } from './FloatingSaveChangesButton/FloatingSaveChangesButton'
import { CreateFromTemplateButton } from './CreateFromTemplateButton/CreateFromTemplateButton'
import { ShortcutNewScriptButton } from './ShortcutNewScriptButton/ShortcutNewScriptButton'
import { NavigationBar } from './NavigationBar/NavigationBar'
import { ScriptBrewAiAssistant } from './ScriptBrewAiAssistant/ScriptBrewAiAssistant'
import fullscreenViewAtom from '../../../recoil/fullscreenView'
import { ScriptEditor } from './ScriptEditor/ScriptEditor'
import { RobotIcon } from '../../UI/Icons/RobotIcon'
import { ScriptHistory } from './ScriptHistory/ScriptHistory'
import { deleteConversation } from './ScriptBrewAiAssistant/conversationsStorage'
import useLicense from '../../../hooks/useLicense'
import { LicenseEnterpriseEdition } from '../../../consts'

const overlayAiPromptKeyword = 'ks-internals:scripting:overlay-ai-prompt'

import Moment from 'moment/moment'
import { CreateRounded } from '@mui/icons-material'
import { appAiAssistantEnabled } from '../../../types/global'

enum SidebarContent {
  SCRIPT_BREW_AI_ASSISTANT,
  SCRIPT_HISTORY
}

export const ScriptingView = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [themesLoaded, setThemesLoaded] = useState(false)
  const [darkModeEnabled, setDarkModeEnabled] = useState(true)
  const [compactPadding, setCompactPadding] = useState(false)

  const [scriptChosenEvent, setScriptChosenEvent] = useState(null)
  const [selectedScript, setSelectedScript] = useState<Script>(null)
  const [scriptTitle, setScriptTitle] = useState('Bootstrap Script')
  const [scriptText, setScriptText] = useState(SCRIPT_BOOTSTRAP)
  const [scriptTitleChanged, setScriptTitleChanged] = useState(false)
  const [scriptChanged, setScriptChanged] = useState(false)
  const [saveChangesButtonClicked, setSaveChangesButtonClicked] =
    useState(false)
  const [activationButtonClicked, setActivationButtonClicked] = useState(false)
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false)
  const [templatesOpen, setTemplatesOpen] = useState(false)
  const [deleteDropdownOpen, setDeleteDropdownOpen] = useState(false)
  const [scriptsLoaded, setScriptsLoaded] = useState(false)

  const [activeScripts, setActiveScripts] = useState([])
  const [inactiveScripts, setInactiveScripts] = useState([])

  const fullscreenView = useRecoilValue(fullscreenViewAtom)

  const [scriptIndexOnLoad, setScriptIndexOnLoad] = useRecoilState(
    scriptIndexOnLoadAtom
  )

  const [scripts, setScripts] = useRecoilState(scriptsAtom)
  const setScriptAllWsLogs = useSetRecoilState(scriptAllWsLogsAtom)

  const [rightSidebarContent, setRightSidebarContent] = useState(null)
  const [aiAssistantOpen, setAiAssistantOpen] = useState(false)
  const [, setHistoryOpen] = useState(false)

  const [codeCreatePrompt, setCodeCreatePrompt] = useState('')
  const [codeToChange, setCodeToChange] = useState('')
  const [codeChangePrompt, setCodeChangePrompt] = useState('')
  const [aiCreatedCode, setAiCreatedCode] = useState('')
  const [aiModifiedCode, setAiModifiedCode] = useState('')

  useEffect(() => {
    setSidebarOpen(rightSidebarContent === null)
    setAiAssistantOpen(rightSidebarContent === SidebarContent.SCRIPT_BREW_AI_ASSISTANT)
    setHistoryOpen(rightSidebarContent === SidebarContent.SCRIPT_HISTORY)
  }, [rightSidebarContent])

  const firstRenderRef = useRef(true)

  useEffect(() => {
    setCompactPadding(fullscreenView.fullscreen)
  }, [fullscreenView])

  const monaco = useMonaco()

  useEffect(() => {
    getScripts().then((scripts) => {
      setScripts(scripts)
      setScriptsLoaded(true)
    })

    return () => {
      setScriptsLoaded(false)
      setScriptIndexOnLoad(null)
    }
  }, [])

  useEffect(() => {
    if (!scriptsLoaded || !scripts) {
      return
    }

    if (scriptsLoaded && Object.keys(scripts).length === 0) {
      setActiveScripts([])
      setInactiveScripts([])
      setTemplatesOpen(true)
      return
    }

    const scriptEntries = Object.entries(scripts)

    const activeScripts: Script[] = []
    const inactiveScripts: Script[] = []

    scriptEntries.map((scriptVal) => {
      const script = { ...scriptVal[1] }
      script.index = parseInt(scriptVal[0])

      if (script.active) {
        activeScripts.push(script)
      } else {
        inactiveScripts.push(script)
      }
    })

    setActiveScripts(activeScripts)
    setInactiveScripts(inactiveScripts)

    if (!firstRenderRef.current || selectedScript !== null) {
      return
    }

    let index = 0
    if (scriptIndexOnLoad !== null) {
      index = scriptIndexOnLoad
    }

    const script: Script = { ...scripts[index], index }

    setSelectedScript(script)
    setScriptTitle(script.title)
    setScriptText(script.code)
    setScriptChosenEvent(Date.now())

    firstRenderRef.current = false
  }, [scripts, scriptsLoaded, scriptIndexOnLoad, selectedScript])


  const [showEditorOverlayAiPrompt, setShowEditorOverlayAiPrompt] = useState(false)

  useEffect(() => {
    setShowEditorOverlayAiPrompt(scriptText?.includes(overlayAiPromptKeyword))
  }, [scriptText])

  useEffect(() => {
    if (showEditorOverlayAiPrompt) {
      setScriptChosenEvent(Date.now())
    }
  }, [showEditorOverlayAiPrompt])

  useEffect(() => {
    if (monaco) {
      Promise.allSettled([
        import('monaco-themes/themes/GitHub Light.json').then((data) => {
          // @ts-expect-error: defineTheme should support arbitrary `string`
          // instead of `BuiltinTheme` = 'vs' | 'vs-dark' | 'hc-black' | 'hc-light'
          // `BuiltinTheme` type makes it impossible to set a custom theme &
          // avoid TS error.
          monaco.editor.defineTheme('github-light', data['default'])
        }),
        import('monaco-themes/themes/GitHub Dark.json').then((data) => {
          // @ts-expect-error: defineTheme should support arbitrary `string`
          // instead of `BuiltinTheme` = 'vs' | 'vs-dark' | 'hc-black' | 'hc-light'
          // `BuiltinTheme` type makes it impossible to set a custom theme &
          // avoid TS error.
          monaco.editor.defineTheme('github-dark', data['default'])
        })
      ]).then(() => setThemesLoaded(true))
    }
  }, [monaco])

  useEffect(() => {
    if (monaco && themesLoaded) {
      monaco.editor.setTheme(darkModeEnabled ? 'github-dark' : 'github-light')
    }
  }, [monaco, themesLoaded, darkModeEnabled])

  const handleSaveScriptChanges = useCallback(() => {
    if (
      !authorizeAction({
        [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
          AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
      })
    ) {
      return new Promise(resolve => resolve(false))
    }

    let savedScriptTitle = scriptTitle
    if (savedScriptTitle !== undefined && !savedScriptTitle?.trim()?.length) {
      savedScriptTitle = `Script ${Moment()?.utc().format('MM/DD/YYYY, (UTC+00) h:mm:ss A')}`
      setScriptTitle(savedScriptTitle)
    }

    setSaveChangesButtonClicked(true)

    if (selectedScript === null) {
      return postCreateScript(savedScriptTitle, scriptText).then((response) => {
        getScripts()
          .then((scripts) => {
            setScripts(scripts)

            const createdScript = { ...scripts[response.index] }
            createdScript.index = response.index

            setSelectedScript(createdScript)
            setScriptTitle(createdScript.title)
            setScriptText(createdScript.code)
            setScriptChanged(false)
          })
          .then(() => setSaveChangesButtonClicked(false))
      })
    } else {
      return putUpdateScript(selectedScript.index, savedScriptTitle, scriptText).then(
        () => {
          getScripts()
            .then((scripts) => setScripts(scripts))
            .then(() => setSaveChangesButtonClicked(false))
          setScriptChanged(false)

          if (scriptTitleChanged) {
            setScriptTitleChanged(false)
          }

          setScriptAllWsLogs((scriptLogs) => {
            const updatedScriptLogs = { ...scriptLogs }

            const updatedScriptLog = {
              ...updatedScriptLogs[selectedScript.index]
            }
            updatedScriptLog.logs = []

            updatedScriptLogs[selectedScript.index] = updatedScriptLog

            return updatedScriptLogs
          })
        }
      )
    }
  }, [scriptTitle, scriptText, selectedScript])

  const handleDeleteScript = useCallback(() => {
    if (
      !authorizeAction({
        [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
          AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
      })
    ) {
      return
    }

    setDeleteButtonClicked(true)

    deleteScript(selectedScript.index).then(() => {
      getScripts()
        .then((scripts) => {
          setScripts(scripts)

          setSelectedScript(null)
          setScriptTitle(Object.keys(TEMPLATE_SCRIPT_TITLES)[0])
          setScriptText(EXAMPLE_SCRIPTS[0])
        })
        .then(() => setDeleteDropdownOpen(false))
        .then(() => {
          setDeleteButtonClicked(false)
          deleteConversation(selectedScript.title)
        })
    })
  }, [selectedScript])

  const { licenseInfo } = useLicense()

  const handleOpenAiAssistant = useCallback((toggleEnabled = true) => {
    if (
      !appAiAssistantEnabled() ||
      licenseInfo === null ||
      licenseInfo?.doc?.edition !== LicenseEnterpriseEdition ||
      (licenseInfo?.doc?.edition === LicenseEnterpriseEdition && !licenseInfo?.valid)
    ) {
      return
    }

    if (toggleEnabled) {
      if (rightSidebarContent === SidebarContent.SCRIPT_BREW_AI_ASSISTANT) {
        setRightSidebarContent(null)
      } else if (rightSidebarContent && rightSidebarContent !== SidebarContent.SCRIPT_BREW_AI_ASSISTANT) {
        setRightSidebarContent(SidebarContent.SCRIPT_BREW_AI_ASSISTANT)
      } else {
        setRightSidebarContent(SidebarContent.SCRIPT_BREW_AI_ASSISTANT)
      }
    } else {
      setRightSidebarContent(SidebarContent.SCRIPT_BREW_AI_ASSISTANT)
    }
  }, [rightSidebarContent, licenseInfo])

  const handleTitleInputChange = useCallback((event) => {
    setScriptTitle(event.target.value)
    if (showEditorOverlayAiPrompt) {
      return
    }

    setScriptTitleChanged(true)
    setScriptChanged(true)
  }, [showEditorOverlayAiPrompt])

  return (
    <div
      className={styles.ScriptingViewContainer}
      style={{
        padding: compactPadding ? 'unset' : null
      }}
    >
      <Box
        className={styles.FillAllSpaceContainer}
        padding={`0 ${compactPadding ? 0 : 16}px`}
        paddingTop={!compactPadding ? '10px' : 0}
      >
        <Box
          className={styles.FillAllSpaceContainer}
          position='relative'
          borderRadius='4px'
          boxShadow={variables.lightShadow}
        >
          <ScriptingTemplates
            darkModeEnabled={darkModeEnabled}
            open={templatesOpen}
            setOpen={setTemplatesOpen}
            onTemplateChosen={(index, title) => {
              setScriptChosenEvent(Date.now())
              setSelectedScript(null)
              setScriptTitle(title)
              setScriptText(EXAMPLE_SCRIPTS[index])
            }}
          />
          {scriptChanged &&
            authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
            }) && (
            <FloatingSaveChangesButton
              saveChangesButtonClicked={saveChangesButtonClicked}
              handleSaveScriptChanges={handleSaveScriptChanges}
              hide={showEditorOverlayAiPrompt}
            />
          )}
          <Sidebar isOpen={sidebarOpen}>
            <Box
              boxSizing='border-box'
              padding='12px 16px'
              height='100%'
              width='100%'
              borderRight={`1px solid ${
                darkModeEnabled
                  ? variables.slateColor
                  : variables.lighterGrayColor
              }`}
              bgcolor={
                darkModeEnabled
                  ? variables.githubEditorBackgroundColorLight
                  : variables.mainBackgroundColor
              }
            >
              <Box
                boxSizing='border-box'
                display='flex'
                alignItems='center'
                gap='10px'
              >
                <CreateFromTemplateButton
                  onClick={() => setTemplatesOpen(true)}
                />
                {
                  appAiAssistantEnabled() &&
                  licenseInfo !== null &&
                  licenseInfo.doc.edition === LicenseEnterpriseEdition && licenseInfo.valid && (
                    <ShortcutNewScriptButton
                      darkModeEnabled={darkModeEnabled}
                      tooltipTitle='Use GenAI Assistant to create a new automation'
                      icon={
                        <RobotIcon size={19} stroke={
                          darkModeEnabled ? variables.lightestGrayColor : variables.slateColor
                        }/>
                      }
                      onClick={() => {
                        setSelectedScript(null)
                        setScriptTitle('Use GenAI Assistant')
                        setScriptText(SCRIPT_USE_AI_ASSISTANT)
                      }}
                    />
                  )
                }
                <ShortcutNewScriptButton
                  darkModeEnabled={darkModeEnabled}
                  tooltipTitle='Create an empty script'
                  icon={<CreateRounded sx={{ fontSize: '19px' }} />}
                  onClick={() => {
                    setSelectedScript(null)
                    setScriptTitle('Empty Script')
                    setScriptText(`// Nothing here, it's time to free your imagination!
// 🧑‍💻 See how Scripting benefits your experience in Kubeshark ⬇️:\n
// https://docs.kubeshark.co/en/automation_scripting`)
                  }}
                />
              </Box>
              <Box className={styles.SidebarScripts}>
                {!scriptsLoaded && (
                  <SidebarBanner
                    title='Looking for scripts...'
                    icon={
                      <CodeRoundedIcon
                        htmlColor={variables.slateColor}
                        sx={{ fontSize: '48px' }}
                      />
                    }
                    darkModeEnabled={darkModeEnabled}
                  />
                )}
                {scriptsLoaded &&
                  activeScripts.length === 0 &&
                  inactiveScripts.length === 0 && (
                  <SidebarBanner
                    title='Create your first script now!'
                    icon={
                      <CodeRoundedIcon
                        htmlColor={variables.slateColor}
                        sx={{ fontSize: '48px' }}
                      />
                    }
                    darkModeEnabled={darkModeEnabled}
                  />
                )}
                {scriptsLoaded && activeScripts.length > 0 && (
                  <SidebarScriptGroup
                    title='Active Scripts'
                    icon={<FastForwardRoundedIcon sx={{ fontSize: '14px' }} />}
                  />
                )}
                {scriptsLoaded &&
                  activeScripts.map((script: Script) => {
                    return (
                      <SidebarScript
                        key={script.index}
                        darkModeEnabled={darkModeEnabled}
                        title={script.title}
                        active={script.active}
                        index={script.index}
                        selected={script.index === selectedScript?.index}
                        onSelection={(index) => {
                          const selectedScript = { ...scripts[index] }
                          selectedScript.index = index

                          setRightSidebarContent(null)
                          setScriptChosenEvent(Date.now())
                          setSelectedScript(selectedScript)
                          setScriptTitle(selectedScript.title)
                          setScriptText(selectedScript.code)
                        }}
                      />
                    )
                  })}
                {scriptsLoaded && inactiveScripts.length > 0 && (
                  <SidebarScriptGroup
                    title='Inactive Scripts'
                    icon={<StopCircleRoundedIcon sx={{ fontSize: '14px' }} />}
                  />
                )}
                {scriptsLoaded &&
                  inactiveScripts.map((script: Script) => {
                    return (
                      <SidebarScript
                        key={script.index}
                        darkModeEnabled={darkModeEnabled}
                        title={script.title}
                        active={script.active}
                        index={script.index}
                        selected={script.index === selectedScript?.index}
                        onSelection={(index) => {
                          const selectedScript = { ...scripts[index] }
                          selectedScript.index = index

                          setRightSidebarContent(null)
                          setScriptChosenEvent(Date.now())
                          setSelectedScript(selectedScript)
                          setScriptTitle(selectedScript.title)
                          setScriptText(selectedScript.code)
                        }}
                      />
                    )
                  })}
              </Box>
            </Box>
          </Sidebar>
          <Box
            className={styles.SidebarContent}
            sx={sidebarOpen ? { left: '300px !important' } : null}
          >
            <NavigationBar
              darkModeEnabled={darkModeEnabled}
              onSidebarSwitchClick={() => setSidebarOpen(!sidebarOpen)}
              sidebarOpen={sidebarOpen}
              scriptTitle={scriptTitle}
              onTitleInputChange={handleTitleInputChange}
              scriptChanged={scriptChanged}
              selectedScript={selectedScript}
              saveChangesButtonClicked={saveChangesButtonClicked}
              handleSaveScriptChanges={handleSaveScriptChanges}
              disableSaving={showEditorOverlayAiPrompt}
              deleteDropdownOpen={deleteDropdownOpen}
              setDeleteDropdownOpen={setDeleteDropdownOpen}
              onDeleteDropdownButtonClick={() => {
                if (
                  !authorizeAction({
                    [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]:
                      AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_DELETE
                  })
                ) {
                  return
                }

                setDeleteDropdownOpen(!deleteDropdownOpen)
              }}
              deleteButtonClicked={deleteButtonClicked}
              handleDeleteScript={handleDeleteScript}
              handleCancelDeleteScript={() => {
                setDeleteDropdownOpen(false)
              }}
              onDarkModeChange={(event) =>
                setDarkModeEnabled(event.target.checked)
              }
              onFullscreenModeChange={(fullscreen) =>
                setCompactPadding(fullscreen)
              }
            />
            <Box
              boxSizing='border-box'
              height='100%'
              paddingTop='57px'
              bgcolor={
                darkModeEnabled
                  ? variables.githubEditorBackgroundColor
                  : variables.lighterGrayColor
              }
            >
              <PanelGroup direction='horizontal'>
                <Panel id='script-editor' minSize={20} maxSize={80}>
                  <ScriptEditor
                    selectedScript={selectedScript}
                    setScriptTitle={setScriptTitle}
                    scriptText={scriptText}
                    setScriptText={setScriptText}
                    setScriptChanged={setScriptChanged}
                    activationButtonClicked={activationButtonClicked}
                    setActivationButtonClicked={setActivationButtonClicked}
                    darkModeEnabled={darkModeEnabled}
                    setSelectedScript={setSelectedScript}
                    handleOpenAiAssistant={() => handleOpenAiAssistant(false)}
                    setCodeCreatePrompt={setCodeCreatePrompt}
                    setCodeToChange={setCodeToChange}
                    setCodeChangePrompt={setCodeChangePrompt}
                    aiModifiedCode={aiModifiedCode}
                    setAiModifiedCode={setAiModifiedCode}
                    aiCreatedCode={aiCreatedCode}
                    setAiCreatedCode={setAiCreatedCode}
                    handleSaveScriptChanges={handleSaveScriptChanges}
                    showOverlayAiPrompt={showEditorOverlayAiPrompt}
                    setShowOverlayAiPrompt={setShowEditorOverlayAiPrompt}
                  />
                </Panel>
                {
                  appAiAssistantEnabled() &&
                  rightSidebarContent !== null &&
                  licenseInfo !== null &&
                  licenseInfo.doc.edition === LicenseEnterpriseEdition && licenseInfo.valid && (<>
                    <PanelResizeHandle
                      style={{
                        display: showEditorOverlayAiPrompt ? 'none' : null,
                        background: darkModeEnabled
                          ? variables.githubEditorBackgroundColorLight
                          : variables.mainBackgroundColor,
                        width: '10px',
                        borderLeft: `1px solid ${darkModeEnabled ? variables.slateColor : variables.lighterGrayColor}`
                      }}
                    />
                    <Panel
                      id='scriptbrew-ai-panel'
                      className={`${styles.ScriptBrewAiPanel} ${darkModeEnabled ? styles.Dark : styles.Light}`}
                      minSize={30}
                      maxSize={80}
                      style={{
                        display: showEditorOverlayAiPrompt ? 'none' : null
                      }}
                    >
                      {rightSidebarContent === SidebarContent.SCRIPT_BREW_AI_ASSISTANT && (
                        <ScriptBrewAiAssistant
                          scriptText={scriptText}
                          setScriptText={setScriptText}
                          scriptTitle={scriptTitle}
                          scriptChosenEvent={scriptChosenEvent}
                          scriptChanged={scriptChanged}
                          scriptExists={selectedScript !== null && typeof selectedScript.index === 'number'}
                          scriptDeleted={deleteButtonClicked}
                          codeToChange={codeToChange}
                          setCodeToChange={setCodeToChange}
                          codeCreatePrompt={codeCreatePrompt}
                          setCodeCreatePrompt={setCodeCreatePrompt}
                          codeChangePrompt={codeChangePrompt}
                          setCodeChangePrompt={setCodeChangePrompt}
                          aiCreatedCode={aiCreatedCode}
                          setAiCreatedCode={setAiCreatedCode}
                          aiModifiedCode={aiModifiedCode}
                          setAiModifiedCode={setAiModifiedCode}
                          darkModeEnabled={darkModeEnabled}
                        />
                      )}
                      {rightSidebarContent === SidebarContent.SCRIPT_HISTORY && <ScriptHistory darkModeEnabled={darkModeEnabled} />}
                    </Panel>
                  </>
                  )
                }
                {
                  appAiAssistantEnabled() &&
                  licenseInfo !== null &&
                  licenseInfo.doc.edition === LicenseEnterpriseEdition &&
                  licenseInfo.valid && (
                    <Panel
                      id='right-sidebar-panel'
                      style={{
                        minWidth: '55px',
                        maxWidth: '55px',
                        display: showEditorOverlayAiPrompt ? 'none' : null,
                      }}
                    >
                      <Box
                        boxSizing='border-box'
                        padding='8px 0'
                        width='100%'
                        height='100%'
                        display='flex'
                        alignItems='center'
                        flexDirection='column'
                        gap='16px'
                        bgcolor={
                          darkModeEnabled
                            ? variables.githubEditorBackgroundColorLight
                            : variables.mainBackgroundColor
                        }
                      >
                        <IconButton
                          disableRipple
                          sx={{
                            padding: '4px',
                            borderRadius: '4px',
                            backgroundColor: aiAssistantOpen ? (
                              darkModeEnabled ? variables.slateColor : variables.lightGrayBlueColor
                            ) : 'transparent',
                          }}
                          onClick={() => handleOpenAiAssistant(true)}
                        >
                          <RobotIcon
                            stroke={aiAssistantOpen ? (
                              darkModeEnabled
                                ? variables.lighterGrayBlueColor
                                : variables.slateColor
                            ) : (
                              darkModeEnabled
                                ? variables.lighterGrayColor
                                : variables.slateColor
                            )}
                          />
                        </IconButton>
                      </Box>
                    </Panel>
                  )}
              </PanelGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
