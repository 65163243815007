import React from 'react'
import { ErrorRounded, LoginRounded } from '@mui/icons-material'
import variables from '../../variables.module.scss'
import { Box } from '@mui/material'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import { KubesharkBackdropLogoIcon } from '../../components/UI/Icons/KubesharkBackdropLogoIcon'

interface ErrorChainProps {
  errorIcon: React.ReactNode
}

export const ErrorChain: React.FC<ErrorChainProps> = ({ errorIcon }) => {
  return (
    <Box
      boxSizing='border-box'
      display='flex'
      alignItems='center'
      gap='5px'
    >
      <LoginRounded
        htmlColor={variables.lightGrayColor}
        sx={{
          fontSize: '48px',
        }}
      />
      <Box border={`1px dashed ${variables.lighterGrayColor}`} width='80px' />
      <Box
        boxSizing='border-box'
        position='relative'
        mr='5px'
      >
        {errorIcon}
        <ErrorRounded
          htmlColor={variables.failureColor}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: '-4px',
            fontSize: '18px',
            zIndex: 2,
          }}
        />
        <CircleRoundedIcon
          htmlColor={variables.mainBackgroundColor}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: '-4px',
            fontSize: '20px',
          }}
        />
      </Box>
      <Box border={`1px dashed ${variables.lighterGrayColor}`} width='80px' />
      <KubesharkBackdropLogoIcon fill={variables.blueColor} size={42} />
    </Box>
  )
}
