import React, { useCallback } from 'react'
import { Box } from '@mui/material'

import { AuthExpiredState } from './views/AuthExpiredState'
import { AuthInvalidState } from './views/AuthInvalidState'
import { AuthInternalError } from './views/AuthInternalError'

interface StaticViewProps {
  view: string
}

export enum StaticViews {
  AuthInvalidState = 'authinvalidstate',
  AuthExpiredState = 'authexpiredstate',
  AuthInternalError = 'authinternalerror',
}

export const StaticView: React.FC<StaticViewProps> = ({ view }) => {
  const resolveView = useCallback(() => {
    switch(view) {
    case StaticViews.AuthInvalidState:
      return <AuthInvalidState />
    case StaticViews.AuthExpiredState:
      return <AuthExpiredState />
    case StaticViews.AuthInternalError:
      return <AuthInternalError />
    }
  }, [view])

  return (
    <Box
      boxSizing='border-box'
      height='100%'
      width='100%'
      padding='16px'
      display='flex'
      flexDirection='column'
    >
      {resolveView()}
    </Box>
  )
}
