import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import authSessionLogoutAtom from '../../../../recoil/authSessionLogout'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authSessionOkAtom from '../../../../recoil/authSessionOk'
import Cookies from 'js-cookie'
import { HubBaseUrl } from '../../../../consts'
import { removeAuthSessionDetails, setAuthSessionDetails } from '../AuthSessionDetails'

interface OidcAuthProps {
  children?: React.ReactNode | React.ReactNode[]
}

export interface OidcAuthCtxState {
  userClaims: string
  getUserClaims: () => string
}

const extractUserClaims = (userClaimsCookieVal: string) => {
  if (!userClaimsCookieVal) {
    return null
  }

  return JSON.parse(userClaimsCookieVal)
}

export const OidcAuthContext = createContext<OidcAuthCtxState>({
  userClaims: extractUserClaims(Cookies.get('user_claims')),
  getUserClaims: () => extractUserClaims(Cookies.get('user_claims')),
})

const initOidcAuthPath = '/auth/session'
const logoutPath = '/oauth2/logout'

const probeOidcAuth = () => {
  return fetch(
    `${HubBaseUrl}${initOidcAuthPath}`,
    {
      headers: {
        'X-Kubeshark-Capture': 'ignore',
      }
    }
  )
}

const logout = () => {
  return fetch(
    `${HubBaseUrl}${logoutPath}`,
    {
      method: 'POST',
      headers: {
        'X-Kubeshark-Capture': 'ignore',
      }
    }
  )
}

export const OidcAuth: React.FC<OidcAuthProps> = ({ children }) => {
  const setAuthSessionOk = useSetRecoilState(authSessionOkAtom)
  const authSessionLogout = useRecoilValue(authSessionLogoutAtom)
  const [cookies, , removeCookie] = useCookies(['user_claims']);

  const [userClaims, setUserClaims] = useState(Cookies.get('user_claims'))

  useEffect(() => {
    if (cookies?.user_claims) {
      const userClaims = cookies?.user_claims
      setUserClaims(userClaims)
      setAuthSessionDetails({
        userId: userClaims.sub,
        email: userClaims.email,
        name: userClaims.name,
      })
    } else {
      setUserClaims(null)
      removeAuthSessionDetails()
      probeOidcAuth()
    }
  }, [cookies])

  const getUserClaims = useCallback(() => userClaims, [userClaims])

  const removeUserClaims = useCallback(() => {
    removeCookie('user_claims')
  }, [removeCookie])

  useEffect(() => {
    if (authSessionLogout !== null) {
      removeUserClaims()
      logout()
    }
  }, [authSessionLogout])

  useEffect(() => {
    probeOidcAuth().then(response => {
      if (!response) {
        return
      }

      setAuthSessionOk(response.status === 200)
    })
  }, [])

  return (
    <OidcAuthContext.Provider
      value={{
        userClaims,
        getUserClaims,
      }}
    >
      {children}
    </OidcAuthContext.Provider>
  )
}
