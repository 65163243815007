import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { Button, Typography } from '@mui/material'

interface CreateFromTemplateButtonProps {
  onClick: () => void
}

export const CreateFromTemplateButton: React.FC<CreateFromTemplateButtonProps> = ({ onClick }) => {
  return (
    <Tooltip
      title='Choose a script from our curated templates collection'
      placement='top-start'
      arrow
    >
      <Button
        color='success'
        variant='contained'
        size='small'
        className='themeButton success'
        fullWidth
        onClick={onClick}
      >
        <Typography
          variant='body1'
          fontSize={13}
          fontFamily='Roboto'
          fontWeight={700}
          color='#ffffff'
        >
          Create from Template
        </Typography>
      </Button>
    </Tooltip>
  )
}
