import React, { KeyboardEventHandler } from 'react'
import { Box } from '@mui/material'

import variables from '../../../../../variables.module.scss'
import assistantStyles from '../../ScriptBrewAiAssistant/ScriptBrewAiAssistant.module.scss'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { AiAssistantBannerIcon } from '../../../../UI/Icons/AiAssistantBannerIcon'

interface NewScriptOverlayAiPromptProps {
  darkModeEnabled: boolean
  handleSubmitAiPrompt: KeyboardEventHandler
}

export const NewScriptOverlayAiPrompt: React.FC<NewScriptOverlayAiPromptProps> = ({
  darkModeEnabled,
  handleSubmitAiPrompt
}) => {
  return (
    <Box
      boxSizing='border-box'
      position='absolute'
      top={0}
      left={0}
      bottom={0}
      right={0}
      width='100%'
      height='100%'
      zIndex={901}
    >
      <Box
        boxSizing='border-box'
        width='100%'
        height='100%'
        padding='16px 0'
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        gap='15px'
        textAlign='center'
      >
        <Box
          boxSizing='border-box'
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          gap='5px'
        >
          <AiAssistantBannerIcon
            stroke={darkModeEnabled ? variables.slateColor : variables.lightGrayColor}
          />
          <h1
            style={{
              fontFamily: 'Roboto, sans-serif',
              fontSize: '26px',
              fontWeight: 600,
              color: darkModeEnabled ? variables.headerBackgroundColor : variables.lightGrayColor
            }}
          >
            Kubeshark GenAI Assistant
          </h1>
          <span
            style={{
              fontFamily: 'Source Sans Pro, sans-serif',
              fontSize: '16px',
              fontWeight: 600,
              color: variables.lightGrayColor
            }}
          >
            Gain unlimited network insights using natural language to <br />
            process real-time traffic and generate custom reports, metrics, <br />
            and automations, leveraging the latest Gen-AI advancements.
          </span>
        </Box>
        <TextareaAutosize
          className={`
            ${assistantStyles.Textarea} ${assistantStyles.InEditor}
            ${darkModeEnabled ? assistantStyles.Dark : assistantStyles.Light}
          `}
          placeholder='⚡ Ask our GenAI to create a script'
          onKeyDown={handleSubmitAiPrompt}
        />
      </Box>
    </Box>
  )
}
