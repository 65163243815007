import React from 'react'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material'
import { DefaultAuthProvider } from './components/UI/Auth/DefaultAuth/DefaultAuthProvider'
import { AppLoader } from './AppLoader'

import { CookiesProvider } from 'react-cookie'

import './App.sass'
import { useSearchParams } from 'react-router-dom'
import { StaticView } from './static/StaticView'

const App: React.FC = () => {
  const [searchParams] = useSearchParams()

  return (
    <StyledEngineProvider injectFirst>
      <CookiesProvider>
        <DefaultAuthProvider>
          <ThemeProvider theme={createTheme(({}))}>
            {
              searchParams.has('staticview') ?
                <StaticView view={searchParams.get('staticview')} /> :
                <AppLoader />
            }
          </ThemeProvider>
        </DefaultAuthProvider>
      </CookiesProvider>
    </StyledEngineProvider>
  )
}

export default App
