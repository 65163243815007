import React from 'react'
import { Box } from '@mui/material'
import { CodeRounded } from '@mui/icons-material'

import variables from '../../../../../variables.module.scss'
import styles from '../ScriptBrewAiAssistant.module.scss'

interface AiCodeContextProps {
  maxWidth: number
  completed: boolean
  darkModeEnabled: boolean
}

export const AiToEditorModification: React.FC<AiCodeContextProps> = ({ maxWidth, completed, darkModeEnabled }) => {
  return (
    <Box
      boxSizing='border-box'
      position='relative'
      display='flex'
      alignItems='center'
      gap='24px'
      padding='8px 16px'
      width='100%'
      alignSelf='flex-start'
      flex='1'
      bgcolor='transparent'
      maxWidth={maxWidth}
    >
      <Box
        boxSizing='border-box'
        minWidth='40px'
        width='40px'
        height='40px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderRadius='6px'
        bgcolor={darkModeEnabled ? variables.slateColor : variables.lightGrayBlueColor}
      >
        <CodeRounded
          htmlColor={
            darkModeEnabled
              ? variables.lighterGrayBlueColor
              : variables.slateColor
          }
        />
      </Box>
      <Box
        boxSizing='border-box'
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        gap='5px'
      >
        <Box
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans, sans-serif',
            lineHeight: '24px',
            color: darkModeEnabled ? variables.lightestGrayColor : variables.grayColor,
          }}
        >
          <Box style={{ fontWeight: 600 }}>
            {completed ? (
              <span>Modification completed!</span>
            ) : (
              <Box className={darkModeEnabled ? styles.AssistantProcessTextDark : styles.AssistantProcessTextLight}>
                Modifying your script...
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
