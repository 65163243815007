import { Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import variables from '../../../../variables.module.scss'
// import { LicenseEnterpriseEdition, LicenseOndemandEdition, LicenseProCompEdition, LicenseProUnlEdition, LinkAdminConsole, LinkContactUs } from '../../../../consts'
import { LinkAdminConsole, LinkContactUs } from '../../../../consts'
import useLicense from '../../../../hooks/useLicense'

export const LicenseIssueText: React.FC = () => {
  const {
    licenseInfo: info,
    commercialEdition,
    nodeLimitOverflow,
    expired,
    online,
  } = useLicense()

  const defaultText: JSX.Element = <>
    <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
      Oops! It seems something went wrong with your license, please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
    </Typography>
  </>

  // const expirableEditions = [LicenseEnterpriseEdition, LicenseOndemandEdition, LicenseProCompEdition, LicenseProUnlEdition]

  const [issueText, setIssueText] = useState(defaultText)

  useEffect(() => {
    if (!commercialEdition){
      if (online){
        if (!nodeLimitOverflow) {
          setIssueText(<>
            <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
              The Community Edition provides full functionality and is free to use within this cluster for logged-in users.
            </Typography>
          </>)
        } else {
          setIssueText(<>
            <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
              - Log in to use your license, or <br />
              - Get an Enterprise trial license <Link href={LinkAdminConsole} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>here</Link>.
            </Typography>
          </>)
        }
      } else { // noncommercial, no internet
        setIssueText(<>
          <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
            - Air-gapped clusters require setting a license key in the configuration.<br />
            - Download your license key from: <Link href={LinkAdminConsole} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>https://console.kubeshark.co/</Link>
          </Typography>
        </>)
      } 
    } else { // commercial
      if (info.doc.end<0) { 
        setIssueText(<>
          <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
            - Your license is pending activation. <br />
            - Check your inbox for next steps. <br />
            - Optionally <Link href={LinkAdminConsole} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>downgrade</Link> to the Community tier. <br />
            - <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>Contact us</Link> if something is wrong.
          </Typography>
        </>)
      } else if (expired) {
        setIssueText(<>
          <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
            - Your license has expired. <br />
            - Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> to extend your license.
          </Typography>
        </>)
      } else if (nodeLimitOverflow) {
        // PRO: node limit overflow
        setIssueText(<>
          <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
            Node limit reached!
          </Typography>
        </>)
      } 
    }
  }, [info, commercialEdition, nodeLimitOverflow, expired])

  return issueText
}
