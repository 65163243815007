import React from 'react'
import { Script } from '../../../modals/ScriptingModal/ScriptingTypes'
import { Box } from '@mui/material'
import { DeactivateScriptButton } from '../ScriptButtons/DeactivateScriptButton'
import { ActivateScriptButton } from '../ScriptButtons/ActivateScriptButton'
import { ScriptLogsButton } from '../ScriptButtons/ScriptLogsButton'

interface FloatingScriptActionsPanelProps {
  selectedScript: Script
  darkModeEnabled: boolean
  inProgress: boolean
  blurred: boolean
  handleDeactivateScript: () => void
  handleActivateScript: () => void
  goToScriptConsoleLogs: () => void
}

export const FloatingScriptActionsPanel: React.FC<FloatingScriptActionsPanelProps> = ({
  selectedScript,
  darkModeEnabled,
  inProgress,
  blurred,
  handleDeactivateScript,
  handleActivateScript,
  goToScriptConsoleLogs,
}) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap='15px'
      sx={{
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        filter: blurred ? 'blur(10px)' : null
      }}
      zIndex={900}
    >
      {selectedScript !== null &&
        (selectedScript.active ? (
          <DeactivateScriptButton
            darkModeEnabled={darkModeEnabled}
            inProgress={inProgress}
            onClick={handleDeactivateScript}
          />
        ) : (
          <ActivateScriptButton
            darkModeEnabled={darkModeEnabled}
            inProgress={inProgress}
            onClick={handleActivateScript}
          />
        ))}
      {selectedScript !== null && (
        <ScriptLogsButton
          darkModeEnabled={darkModeEnabled}
          scriptActive={selectedScript.active}
          onClick={goToScriptConsoleLogs}
        />
      )}
    </Box>
  )
}
