import React, { useEffect, useState } from 'react'
import { InlineCode } from '../../components/UI/InlineCode/InlineCode'
import { ServerIcon } from '../../components/UI/Icons/ServerIcon'
import { ErrorPageTemplate } from '../components/ErrorPageTemplate'

import variables from '../../variables.module.scss'
import { appAuthEnabled, appDexAuthTypeEnabled } from '../../types/global'

export const AuthInternalError: React.FC = () => {
  const [suggestions, setSuggestions] = useState([
    <>
      <strong>Inspect logs:</strong><br/>1. In <InlineCode>kubeshark-hub</InlineCode> deployment using&nbsp;
      <InlineCode>kubectl logs deployment/kubeshark-hub -f</InlineCode>
    </>
  ])

  useEffect(() => {
    if (appAuthEnabled() && appDexAuthTypeEnabled()) {
      setSuggestions(prevSuggestions => {
        const updatedSuggestions = [...prevSuggestions]
        updatedSuggestions.push(
          <>
            <br/>2. In <InlineCode>kubeshark-dex</InlineCode> deployment using&nbsp;
            <InlineCode>kubectl logs deployment/kubeshark-dex -f</InlineCode>
          </>
        )

        return updatedSuggestions
      })
    }
  }, [])

  return (
    <ErrorPageTemplate
      title='Internal error'
      description={<>
        Kubeshark has run into an internal error while carrying your authentication process.
      </>}
      suggestion={suggestions}
      errorIcon={<ServerIcon size={36} stroke={variables.failureColor} />}
    />
  )
}
