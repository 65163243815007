import React, { useCallback, useEffect, useState } from 'react'
import variables from '../../../variables.module.scss'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import { useRecoilValue } from 'recoil'
import packetCapturingStoppedAtom from '../../../recoil/packetCapturingStopped'
import { appAuthEnabled, appSamlAuthTypeEnabled, appStopTrafficCapturingDisabled } from '../../../types/global'
import { useTrafficCapturingStatus } from '../../../hooks/useTrafficCapturingStatus'
import { checkSamlAuth } from '../../UI/Auth/SamlAuth/SamlAuth'
import { authorizeAction, AUTHZ_ACTIONS } from '../../UI/Auth/SamlAuth/Authorization'
import useElementRouter from '../../../hooks/useElementRouter'
import { useAuth } from '../../UI/Auth/Auth'

interface DisableTrafficCapturingButtonProps {
  size?: 'small' | 'medium'
  mode?: 'link' | 'change'
  authorized?: boolean
  altView?: boolean | string
  onChange?: (stopped: boolean) => void
}

export const DisableTrafficCapturingButton: React.FC<DisableTrafficCapturingButtonProps> = ({ mode = 'change', size = 'medium', authorized = true, altView = false, onChange }) => {
  if (appStopTrafficCapturingDisabled()) {
    return <></>
  }

  const { getCapturingStatus, postCapturingStoppedStatus } = useTrafficCapturingStatus(true)
  const { navigateToElement } = useElementRouter()
  const { shouldAuthenticate } = useAuth()

  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)

  const [capturingButtonClicked, setCapturingButtonClicked] = useState(false)

  useEffect(() => {
    if (appAuthEnabled() && appSamlAuthTypeEnabled() && !checkSamlAuth()) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    getCapturingStatus()
  }, [shouldAuthenticate])

  const getTooltipTitle = useCallback(() => {
    if (!authorizeAction(AUTHZ_ACTIONS.CAN_STOP_TRAFFIC_CAPTURING)) {
      return 'You are not authorized to stop/resume traffic capture'
    }

    if (authorized) {
      if (mode === 'link') {
        return 'Go to traffic capturing setting'
      }

      if (capturingStopped) {
        return 'Once ON, Kubeshark will start capture and process traffic'
      } else {
        return 'Once OFF, Kubeshark will stop processing traffic and become dormant'
      }
    } else {
      return 'You are not authorized to stop/resume traffic capture.'
    }
  }, [capturingStopped, authorized])

  const handleNavigateToCapturingSettings = useCallback(() => {
    if (capturingButtonClicked) {
      return
    }

    navigateToElement('settings')
  }, [capturingButtonClicked])

  const handleChangeCapturingStatus = useCallback(() => {
    if (!authorized || capturingButtonClicked) {
      return
    }

    setCapturingButtonClicked(true)
    const status = !capturingStopped

    postCapturingStoppedStatus(status).then(() => {
      setCapturingButtonClicked(false)

      if (typeof onChange === 'function') {
        onChange(status)
      }
    })
  }, [authorized, capturingStopped, capturingButtonClicked])

  if (capturingStopped === null) {
    return <></>
  }

  return (
    <Tooltip
      arrow
      placement='top-start'
      title={getTooltipTitle()}
    >
      <Box width='fit-content' display='inline-block'>
        <Button
          variant='contained'
          className={`themeButton ${altView ? capturingStopped ? 'success' : 'error' : capturingStopped ? 'error' : 'success'}`}
          color={altView ? capturingStopped ? 'success' : 'error' : capturingStopped ? 'error' : 'success'}
          size='medium'
          sx={{
            padding: size === 'small' ? '4px 12px' : null,
            mt: '10px',
            opacity: !authorized || capturingButtonClicked ? 0.5 : 1,
            cursor: authorized ? 'pointer' : 'not-allowed',
            pointerEvents: capturingButtonClicked ? 'none' : 'auto',
          }}
          startIcon={
            <PowerSettingsNewRoundedIcon
              htmlColor='#ffffff'
              sx={{
                marginRight: '5px'
              }}
            />
          }
          disabled={!authorizeAction(AUTHZ_ACTIONS.CAN_STOP_TRAFFIC_CAPTURING)}
          onClick={mode === 'link' ? handleNavigateToCapturingSettings : handleChangeCapturingStatus}
        >
          <Typography
            variant='body1'
            fontSize={14}
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color='#FFFFFF'
          >
            {capturingButtonClicked ? `Turning ${!capturingStopped ? 'OFF' : 'ON'}...` : (
              altView ?
                <span>{capturingStopped ? 'Enable' : 'Disable'} Traffic Capture</span> :
                <span>Traffic Capture: {capturingStopped ? 'OFF' : 'ON'}</span>
            )}
          </Typography>
        </Button>
      </Box>
    </Tooltip>
  )
}
