import React from 'react'
import { SquareAsteriskIcon } from '../../components/UI/Icons/SquareAsteriskIcon'
import { ErrorPageTemplate } from '../components/ErrorPageTemplate'

import variables from '../../variables.module.scss'

export const AuthInvalidState: React.FC = () => {
  return (
    <ErrorPageTemplate
      title='Invalid (OAuth2) state'
      description={<>
        Kubeshark OAuth2 callback handler has rejected this request due to the invalid OAuth2 state.
        Please, start over!
      </>}
      suggestion={[<>
        <strong>Suggestion:</strong>&nbsp;Your login process might have been interrupted by Kubeshark re-installation.
        Go to Dashboard and try to log in again.
      </>]}
      errorIcon={<SquareAsteriskIcon size={36} stroke={variables.failureColor} />}
    />
  )
}
