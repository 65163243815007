import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { IconButton } from '@mui/material'

interface ShortcutNewScriptButtonProps {
  darkModeEnabled: boolean
  onClick: () => void
  tooltipTitle: string
  icon: React.ReactNode
}

export const ShortcutNewScriptButton: React.FC<ShortcutNewScriptButtonProps> = ({
  darkModeEnabled,
  onClick,
  tooltipTitle,
  icon
}) => {
  return (
    <Tooltip title={tooltipTitle} placement='top-start' arrow>
      <IconButton
        className={`themeButton ${
          darkModeEnabled ? 'white' : 'black'
        } secondary`}
        size='small'
        onClick={onClick}
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}
