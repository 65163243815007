import Moment from 'moment-timezone';

import variables from '../variables.module.scss'
import { WebSocketCloseEventSummary, WebSocketObjectData } from '../types/websocket'

const IP_ADDRESS_REGEX = /([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3})(:([0-9]{1,5}))?/

type JSONValue =
  | string
  | number
  | boolean
  | unknown

export interface BoolProps {
  [key: string]: boolean;
}

export class Utils {
  static isIpAddress = (address: string): boolean => IP_ADDRESS_REGEX.test(address)
  static lineNumbersInString = (code: string): number => code.split("\n").length;

  static humanFileSize(bytes: number, si = false, dp = 1): string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  // Converts 7250 to 7.25K, 3990245 to 3.99M, etc.
  static humanReadableNumber(number) {
    if (number >= 1e9) {
      return (number / 1e9).toFixed(2).replace(/\.00$/, '') + "B";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2).replace(/\.00$/, '') + "M";
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(2).replace(/\.00$/, '') + "K";
    }
    return number.toString();
  }

  static padTo2Digits = (num: number): string => {
    return String(num).padStart(2, '0');
  }

  static getHoursAndMinutes = (protocolTimeKey: string): string => {
    const time = new Date(protocolTimeKey)
    const hoursAndMinutes = Utils.padTo2Digits(time.getHours()) + ':' + Utils.padTo2Digits(time.getMinutes());
    return hoursAndMinutes;
  }

  static formatDate = (date: string): string => {
    const d = new Date(date),
      year = d.getFullYear();

    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();

    const hoursAndMinutes = Utils.getHoursAndMinutes(date);
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    const newDate = [year, month, day].join('-');
    return [hoursAndMinutes, newDate].join(' ');
  }

  static createUniqueObjArrayByProp = (objArray: unknown[], prop: string): unknown => {
    const map = new Map(objArray.map((item) => [item[prop], item])).values()
    return Array.from(map);
  }

  static isJson = (str: string): boolean => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static downloadFile = (data: string, filename: string, fileType: string): void => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    a.remove();
  }

  static exportToJson = (data: JSONValue, name: string): void => {
    Utils.downloadFile(JSON.stringify(data), `${name}.json`, 'text/json')
  }

  static getTimeFormatted = (time: Moment.MomentInput): string => {
    return Moment(time).utc().format('MM/DD/YYYY, h:mm:ss.SSS A')
  }

  static TimezoneMoment = (timezone: string, inp?: Moment.MomentInput): Moment.Moment => {
    const moment = Moment(inp || new Date())
    const timezoneValid = Moment.tz.names().includes(timezone)

    return timezoneValid ? moment.tz(timezone) : moment
  }

  static getNow = (format = 'MM/DD/YYYY, HH:mm:ss.SSS'): string => {
    return Moment().format(format)
  }

  static getGradientColorByPercentage = (percentage: number): string => {
    let r, g, b

    percentage = Math.max(0, Math.min(100, percentage))
    if (percentage <= 50) {
      const factor = percentage / 50

      r = Math.round(219 + (255 - 219) * factor)
      g = Math.round(33 + (215 - 33) * factor)
      b = Math.round(86 * (1 - factor))
    } else {
      const factor = (percentage - 50) / 50

      r = Math.round(255 + (39 - 255) * factor)
      g = Math.round(215 + (174 - 215) * factor)
      b = Math.round(96 * factor)
    }

    return `rgb(${r},${g},${b})`
  }

  static getColorByPercentage = (percentage: number): string => {
    percentage = Math.max(0, Math.min(100, percentage));

    if (percentage <= 33.33) {
      return variables.warningColor
    // } else if (percentage <= 66.66) {
    //   return variables.warningColor
    } else {
      return variables.successColor
    }
  }

  static countTrueProps = (obj: BoolProps): number => {
    return Object.values(obj).reduce((count, value) => {
      return count + (value ? 1 : 0);
    }, 0);
  };

  static truncateString = (str: string, firstCharCount: number = str.length, endCharCount = 0, dotCount = 3) => {
    if (!str?.length) {
      return str
    }

    if (str.length <= firstCharCount + endCharCount) {
      return str;
    }

    const firstPortion = str.slice(0, firstCharCount);
    const endPortion = endCharCount ? str.slice(-endCharCount) : '';
    const dots = '.'.repeat(dotCount);

    return `${firstPortion}${dots}${endPortion}`;
  }

  static summarizeWebSocketCloseEvent = (e: CloseEvent): WebSocketCloseEventSummary => {
    return {
      code: e.code,
      timeStamp: e.timeStamp,
      reason: e.reason,
      isTrusted: e.isTrusted,
      wasClean: e.wasClean,
    }
  }

  static summarizeWebSocketObject = (socket: WebSocket): WebSocketObjectData => {
    return {
      url: socket.url,
      readyState: socket.readyState,
      protocol: socket.protocol,
      extensions: socket.extensions,
      binaryType: socket.binaryType
    };
  }

  static fetchWithTimeout = async (resource, options: {timeout?: number, method: string, headers?: HeadersInit;} = { method: 'GET' }) => {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      method: options.method,
      signal: controller.signal
    });
    clearTimeout(id);

    return response;
  }

  static isReadableNumber = (num: number): boolean => {
    return !isNaN(num) && isFinite(num)
  }

  static toBase64(str: string): string {
    return Buffer.from(str, 'utf-8').toString('base64')
  }

  static isBase64(str: string) {
    if (str.length % 4 !== 0) {
      return false
    }

    const base64Regex = /^[A-Z0-9+/]*={0,2}$/i
    return base64Regex.test(str)
  }

  static toBase64IfNeeded(str: string) {
    if (typeof str !== 'string') {
      throw new Error('Input must be a string')
    }

    if (Utils.isBase64(str)) {
      return str
    }

    return Utils.toBase64(str)
  }

  static splitArrToChunks(arr: unknown[], size: number) {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    )
  }

  static shuffleArray<T,>(array: T[]): T[] {
    const shuffled = [...array]
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
    }
    return shuffled
  }

  static calculateHexLuminance(hex: string): number {
    const rgb = hex.match(/.{1,2}/g)?.map((val) => parseInt(val, 16) / 255) || [0, 0, 0]
    const [r, g, b] = rgb.map((v) => (v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)))
    return 0.2126 * r + 0.7152 * g + 0.0722 * b
  }

  static extractPropBySelector (obj, selector: string | unknown) {
    if (typeof selector === 'string') {
      return obj[selector]
    } else if (typeof selector === 'object') {
      const [key, nestedSelector] = Object.entries(selector)[0]
      if (obj[key]) {
        return Utils.extractPropBySelector(obj[key], nestedSelector)
      }
    }
    return undefined
  }

  static cleanURL (url: string, pathsToRemove: string[]) {
    pathsToRemove.map(path => {
      if (url.includes(path)) {
        url = url.replace(path, '')
      }
    })

    url = url.replace(/\/?$/, '/')

    return url
  }
}
