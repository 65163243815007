import React from 'react'
import { Box } from '@mui/material'
import variables from '../../variables.module.scss'
import { ErrorChain } from './ErrorChain'
import { GoToDashboardButton } from './GoToDashboardButton'
import {
  SettingDisabledCard
} from '../../components/modals/TrafficSettingsModal/SettingDisabledCard/SettingDisabledCard'

export interface ErrorPageTemplateProps {
  title: string
  description: string | React.ReactNode
  suggestion: React.ReactNode[]
  errorIcon: React.ReactNode
}

export const ErrorPageTemplate: React.FC<ErrorPageTemplateProps> = ({ title, description, suggestion, errorIcon }) => {
  return (
    <>
      <Box
        boxSizing='border-box'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        flex='0.4'
        borderRadius='6px'
        border={`1px solid ${variables.lightGrayBlueColor}`}
        bgcolor={variables.lightestGrayColor}
      >
        <ErrorChain errorIcon={errorIcon} />
        <h1
          style={{
            marginTop: '80px',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '32px',
            fontWeight: 600,
            textAlign: 'center',
            color: variables.grayColor,
          }}
        >
          {title}
        </h1>
      </Box>
      <Box
        boxSizing='border-box'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        flex='0.6'
      >
        <Box
          boxSizing='border-box'
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <span
            style={{
              marginTop: '80px',
              fontFamily: 'Source Sans Pro, sans-serif',
              fontSize: '18px',
              fontWeight: 600,
              textAlign: 'center',
              color: variables.darkSlateColor,
            }}
          >
            {description}
          </span>
          <GoToDashboardButton />
        </Box>
        <SettingDisabledCard width='auto' height='auto'>
          <span
            style={{
              width: '100%',
              boxSizing: 'border-box',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: 2,
              color: variables.fontColor
            }}
          >
            {suggestion}
          </span>
        </SettingDisabledCard>
      </Box>
    </>
  )
}
