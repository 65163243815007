import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import variables from '../../../../variables.module.scss'
import { HistoryItem } from './HistoryItem/HistoryItem'

import { DiffEditor, useMonaco } from '@monaco-editor/react'

import 'monaco-themes/themes/GitHub Light.json'
import 'monaco-themes/themes/GitHub Dark.json'

interface ScriptHistoryProps {
  darkModeEnabled: boolean
}

export const ScriptHistory: React.FC<ScriptHistoryProps> = ({ darkModeEnabled }) => {
  const monaco = useMonaco()
  const [themesLoaded, setThemesLoaded] = useState(false)

  useEffect(() => {
    if (monaco) {
      Promise.allSettled([
        import('monaco-themes/themes/GitHub Light.json').then((data) => {
          // @ts-expect-error: defineTheme should support arbitrary `string`
          // instead of `BuiltinTheme` = 'vs' | 'vs-dark' | 'hc-black' | 'hc-light'
          // `BuiltinTheme` type makes it impossible to set a custom theme &
          // avoid TS error.
          monaco.editor.defineTheme('github-light', data['default'])
        }),
        import('monaco-themes/themes/GitHub Dark.json').then((data) => {
          // @ts-expect-error: defineTheme should support arbitrary `string`
          // instead of `BuiltinTheme` = 'vs' | 'vs-dark' | 'hc-black' | 'hc-light'
          // `BuiltinTheme` type makes it impossible to set a custom theme &
          // avoid TS error.
          monaco.editor.defineTheme('github-dark', data['default'])
        })
      ]).then(() => setThemesLoaded(true))
    }
  }, [monaco])

  useEffect(() => {
    if (monaco && themesLoaded) {
      monaco.editor.setTheme(darkModeEnabled ? 'github-dark' : 'github-light')
    }
  }, [monaco, themesLoaded, darkModeEnabled])

  const [counts, setCounts] = useState({
    added: 0,
    deleted: 0,
    modified: 0,
  });

  useEffect(() => {
    console.log('Code diff', counts)
  }, [counts])

  const handleDidUpdateDiff = (editor) => {
    const model = editor.getModel();

    if (model) {
      const lineChanges = editor.getLineChanges();

      if (lineChanges) {
        let added = 0;
        let deleted = 0;
        let modified = 0;

        lineChanges.forEach(change => {
          const originalStart = change.originalStartLineNumber;
          const originalEnd = change.originalEndLineNumber;
          const modifiedStart = change.modifiedStartLineNumber;
          const modifiedEnd = change.modifiedEndLineNumber;

          const originalLines = originalEnd - originalStart + 1;
          const modifiedLines = modifiedEnd - modifiedStart + 1;

          if (originalLines === 0 && modifiedLines > 0) {
            // Added lines
            added += modifiedLines;
          } else if (originalLines > 0 && modifiedLines === 0) {
            // Deleted lines
            deleted += originalLines;
          } else {
            // Modified lines
            // Consider the max of added or deleted lines as modified
            modified += Math.max(originalLines, modifiedLines);
          }
        });

        setCounts({ added, deleted, modified });
      }
    }
  }

  const handleEditorMounted = (editor) => {
    handleDidUpdateDiff(editor)
    const updateDiffHandler = () => {
      handleDidUpdateDiff(editor)
    }
    editor.onDidUpdateDiff(updateDiffHandler)

    return () => {
      editor.offDidUpdateDiff(updateDiffHandler)
      editor.dispose()
    };
  }

  return (
    <Box
      boxSizing='border-box'
      position='relative'
      display='flex'
      alignItems='center'
      flexDirection='column'
      gap='10px'
      height='100%'
      width='100%'
      padding='12px 14px'
      paddingLeft='4px'
      bgcolor={variables.githubEditorBackgroundColorLight}
      borderRight={`1px solid ${variables.slateColor}`}
      overflow='auto'
    >
      <HistoryItem selected={false} title='Change' date='20.01.2025, 18:09' />
      <HistoryItem selected={false} title='Change' date='20.01.2025, 18:11' />
      <HistoryItem selected title='Change' date='20.01.2025, 18:15' />
      <DiffEditor
        height="300px"
        language="javascript"
        keepCurrentOriginalModel
        keepCurrentModifiedModel
        originalModelPath={'Upload a file (e.g. PCAP) to an AWS S3 Bucket'}
        original={`
// Upload a file (e.g. PCAP) to an AWS S3 Bucket
function jobS3(){
  if (env.AWS_ACCESS_KEY_ID && env.AWS_SECRET_ACCESS_KEY)
    vendor.s3.put(
      env.S3_BUCKET , name + tarFile, env.AWS_REGION,
      env.AWS_ACCESS_KEY_ID, env.AWS_SECRET_ACCESS_KEY
    );
  else
    vendor.s3.put( env.S3_BUCKET , name + "_" + tarFile, env.AWS_REGION );
}
        `}
        modifiedModelPath={'V2: Upload a file (e.g. PCAP) to an AWS S3 Bucket'}
        modified={`
function jobS3(){
  if (env.AWS_ACCESS_KEY_ID && env.AWS_SECRET_ACCESS_KEY)
    vendor.s3.put(
      env.S3_BUCKET , name + "_" + tarFile, env.AWS_REGION,
    );
  else
    vendor.s3.put( env.S3_BUCKET , name + "_" + tarFile, env.AWS_REGION );

    console.log("job initiated")
}
        `}
        onMount={handleEditorMounted}
      />
    </Box>
  )
}
