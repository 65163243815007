import React from 'react'
import variables from '../../../../../variables.module.scss'
import { Box } from '@mui/material'

interface HistoryItemProps {
  selected: boolean
  title: string
  date: string
}

export const HistoryItem: React.FC<HistoryItemProps> = ({ selected, title, date }) => {
  return (
    <Box
      boxSizing='border-box'
      width='100%'
      padding='8px 16px'
      borderRadius='6px'
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.1s ease-in-out',
        backgroundColor: selected ? variables.slateColor : null,
        '&:hover': {
          backgroundColor: !selected ? variables.darkSlateColor : null,
        }
      }}
    >
      <Box
        boxSizing='border-box'
        display='flex'
        alignItems='flex-start'
        flexDirection='column'
        gap='6px'
      >
        <span
          style={{
            fontSize: '15px',
            fontWeight: 600,
            fontFamily: 'Source Sans Pro, sans-serif',
            color: variables.lightestGrayColor
          }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: 'Roboto, sans-serif',
            color: variables.lightGrayColor
          }}
        >
          {date}
        </span>
      </Box>
    </Box>
  )
}
