import React, { useEffect, useState } from 'react'

import { ServiceMap } from '../../modals/ServiceMapModal/ServiceMapModal'

import styles from './ServiceMapView.module.sass'
import useStreamingWebSocket from '../../../hooks/useStreamingWebSocket'

export const ServiceMapView: React.FC = () => {
  const [renderKey, setRenderKey] = useState(null)

  const { readyState } = useStreamingWebSocket()

  useEffect(() => {
    if (readyState === WebSocket.OPEN || readyState === WebSocket.CLOSED) {
      setRenderKey(-Date.now())
    }
  }, [readyState])

  return (
    <div className={styles.ServiceMapViewContainer}>
      <ServiceMap key={renderKey} renderKey={renderKey} setRenderKey={setRenderKey} />
    </div>
  )
}
