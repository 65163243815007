import React, { useCallback, useEffect, useRef, useState } from 'react'
import variables from '../../../../variables.module.scss'
import { Box, CircularProgress } from '@mui/material'

import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import styles from './ScriptBrewAiAssistant.module.scss'
import { useInterval } from '../../../../helpers/interval'
import ResizeObserver from 'rc-resize-observer'
import { HubBaseUrl } from '../../../../consts'
import { AiRequest } from './AiRequest/AiRequest'
import { AiResponse } from './AiResponse/AiResponse'
import { AiResponseStatus } from './AiResponseStatus/AiResponseStatus'
import { AiAssistantBannerIcon } from '../../../UI/Icons/AiAssistantBannerIcon'
import {
  checkThreadIdIsNew,
  getConversation,
  saveConversation
} from './conversationsStorage'
import { AiCodeContext } from './AiCodeContext/AiCodeContext'
import { AiToEditorModification } from './AiToEditorModification/AiToEditorModification'
import { AiHasMessageInProgressStatus } from './AiHasMessageInProgressStatus/AiHasMessageInProgressStatus'

class EmptyAiResponseError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'EmptyAiResponseError'

    Object.setPrototypeOf(this, EmptyAiResponseError.prototype)
  }
}

export interface Message {
  role: 'user' | 'assistant'
  chunks: MessageChunk[]
  isStatus?: boolean
  isCodeContext?: boolean
  isAiToEditorStatus?: boolean
  isAiToEditorCreation?: boolean
  isAiToEditorModification?: boolean
}

export interface MessageChunk {
  type: 'text' | 'code'
  value: string
}

interface ListMessagesResponse {
  messages: ApiListedMessage[]
  hasActiveRun: boolean
}

interface ApiListedMessage {
  role: 'user' | 'assistant'
  content: ApiListedMessagePart[]
}

interface ApiListedMessagePart {
  type: 'text'
  text: {
    value: string
  }
}

interface ScriptBrewAiAssistantProps {
  scriptTitle: string
  scriptText: string
  setScriptText: (text: string) => void
  scriptChanged: boolean
  scriptChosenEvent: number | null
  scriptExists: boolean
  scriptDeleted: boolean
  codeToChange: string
  setCodeToChange: (codeToChange: string) => void
  codeCreatePrompt: string
  setCodeCreatePrompt: (codeChangePrompt: string) => void
  codeChangePrompt: string
  setCodeChangePrompt: (codeChangePrompt: string) => void
  aiCreatedCode: string
  setAiCreatedCode: (code: string) => void
  aiModifiedCode: string
  setAiModifiedCode: (code: string) => void
  darkModeEnabled: boolean
}

export const ScriptBrewAiAssistant: React.FC<ScriptBrewAiAssistantProps> = ({
  scriptTitle,
  scriptText,
  scriptChanged,
  scriptChosenEvent,
  scriptExists,
  scriptDeleted,
  codeToChange,
  codeCreatePrompt,
  codeChangePrompt,
  setAiCreatedCode,
  setAiModifiedCode,
  darkModeEnabled
}) => {
  const messagesRef = useRef(null)
  const postSendAbortController = useRef(null)
  const fetchAbortController = useRef(null)

  const [width, setWidth] = useState<number>(0)
  const [shouldAutoscroll, setShouldAutoScroll] = useState<boolean>(true)
  const [messages, setMessages] = useState<Message[]>([])
  const [thinking, setThinking] = useState(false)
  const [userMessage, setUserMessage] = useState('')
  const [threadId, setThreadId] = useState<string>('')
  const [fetchingMessages, setFetchingMessages] = useState(false)
  const [fetchedMessages, setFetchedMessages] = useState(false)
  const [codeContextAcquired, setCodeContextAcquired] = useState(false)
  const [scriptCodeChanged, setScriptCodeChanged] = useState(false)
  const [awaitingCodeContext, setAwaitingCodeContext] = useState(false)
  const [awaitingCodeCreation, setAwaitingCodeCreation] = useState(false)
  const [awaitingCodeModification, setAwaitingCodeModification] = useState(false)
  const [hasMessageInProgress, setHasMessageInProgress] = useState(false)

  useEffect(() => {
    if (scriptChosenEvent === null) {
      return
    }

    setMessages([])

    setThreadId('')
    setThinking(false)
    setCodeContextAcquired(false)
    setScriptCodeChanged(false)
    setFetchedMessages(false)
    setFetchingMessages(false)
    setShouldAutoScroll(true)

    setAwaitingCodeContext(false)
    setAwaitingCodeCreation(false)
    setAwaitingCodeModification(false)

    setHasMessageInProgress(false)
  }, [scriptChosenEvent])

  const fetchMessagesByThreadId = async (threadId: string): Promise<ListMessagesResponse> => {
    try {
      const abortController = new AbortController();
      fetchAbortController.current = abortController;
      const { signal } = abortController;

      const response = await fetch(
        `${HubBaseUrl}/ai/assistant/messages/list?threadId=${threadId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal
        },
      )

      if (!response.ok) {
        return
      }

      const parsed = await response.json()

      return JSON.parse(parsed)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const isExistingScript = scriptTitle?.length > 0 && scriptExists && !scriptChanged

    if (threadId?.length > 0 && isExistingScript) {
      if (checkThreadIdIsNew(threadId)) {
        saveConversation(threadId, scriptTitle)
      }
    }
  }, [threadId, thinking, scriptTitle, scriptChanged, scriptExists])

  const fetchConversation = useCallback((ignoreOnActiveRun = false) => {
    if (postSendAbortController.current) {
      postSendAbortController.current.abort("before fetching: abort pending send-message");
    }

    if (fetchAbortController.current) {
      fetchAbortController.current.abort("before fetching: abort pending fetch-messages");
    }

    if (scriptChosenEvent === null) {
      return
    }

    const isExistingScript = scriptTitle?.length > 0 && scriptExists && !scriptChanged

    if (!isExistingScript) {
      setThreadId('')
      return
    }

    const conversation = getConversation(scriptTitle)
    if (conversation !== null) {
      setThreadId(conversation.threadId)

      setFetchingMessages(true)

      fetchMessagesByThreadId(conversation.threadId).then(response => {
        if (!response) {
          return
        }

        setHasMessageInProgress(response.hasActiveRun)

        if (ignoreOnActiveRun && response.hasActiveRun) {
          return
        }

        setMessages([])

        response.messages.map(message => {
          setMessages(prev => {
            const updatedMessages = [...prev]

            let internalMessage = false

            updatedMessages.push({
              role: message.role,
              chunks: message.content.map(part => {
                return {
                  type: part.type,
                  value: part.text.value,
                }
              }).filter(part => {
                if (part.value.includes('->ks-ai-code-context<-')) {
                  internalMessage = true
                  return false
                }

                if (part.value.includes('->ks-ai-script-code-changed<-')) {
                  internalMessage = true
                  return false
                }

                if (part.value.includes('->ks-ai-script-code-created<-')) {
                  internalMessage = true
                  return false
                }

                return true
              })
            })

            if (
              message.role === 'assistant' &&
              !internalMessage
            ) {
              updatedMessages.splice(updatedMessages.length - 1, 0, {
                role: 'assistant',
                chunks: [],
                isStatus: true,
              })
            }

            return updatedMessages
          })
        })
      }).then(() => {
        setMessages(prev => {
          const updatedMessages = [...prev]

          return updatedMessages.filter(message => {
            return message.chunks.length > 0 || message.isStatus || message.isCodeContext
          })
        })
      }).finally(() => {
        setFetchingMessages(false)
        setFetchedMessages(true)
      })
    } else {
      setThreadId('')
      setFetchedMessages(true)
    }
  }, [scriptChosenEvent, scriptExists, scriptChanged])

  useEffect(() => {
    fetchConversation()
  }, [scriptChosenEvent, scriptExists, scriptChanged])

  useInterval(useCallback(() => {
    if (hasMessageInProgress) {
      fetchConversation(true)
    }
  }, [hasMessageInProgress]), 7000, false)

  useEffect(() => {
    if (scriptDeleted) {
      if (postSendAbortController.current) {
        postSendAbortController.current.abort("before deleting: abort pending send-message");
      }

      if (fetchAbortController.current) {
        fetchAbortController.current.abort("before deleting: abort pending fetch-messages");
      }
      setMessages([])

      setThreadId('')
      setThinking(false)
      setCodeContextAcquired(false)
      setScriptCodeChanged(false)
      setFetchedMessages(false)
      setFetchingMessages(false)
      setShouldAutoScroll(true)

      setAwaitingCodeContext(false)
      setAwaitingCodeCreation(false)
      setAwaitingCodeModification(false)

      setHasMessageInProgress(false)
    }
  }, [scriptChosenEvent, scriptDeleted])

  useEffect(() => {
    if (!userMessage.trim()) return

    setAwaitingCodeContext(userMessage.includes('->ks-ai-code-context<-'))
    setAwaitingCodeCreation(userMessage.includes('->ks-ai-script-code-created<-'))
    setAwaitingCodeModification(userMessage.includes('->ks-ai-script-code-changed<-'))

    // Function to send POST request and handle SSE stream
    const sendPostAndListen = async () => {
      const url = `${HubBaseUrl}/ai/assistant/message`; // Replace with your endpoint
      const payload = {
        "message": userMessage,
        "threadId": threadId
      };

      const abortController = new AbortController();
      postSendAbortController.current = abortController;
      const { signal } = abortController;

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream'
          },
          body: JSON.stringify(payload),
          signal
        });

        if (!response.ok) {
          throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        let lines: string[] = []
        let hasTextInResponse = false

        // Function to process the stream
        const processStream = async () => {
          let done = false;
          let capturingCode = false;

          while (!done) {
            const { done: streamDone, value } = await reader.read();
            if (streamDone) {
              done = true;
              break;
            }

            // Decode the incoming bytes to text
            buffer += decoder.decode(value, { stream: true });

            // Split the buffer into lines
            lines = buffer.split(/\r?\n/);
            buffer = lines.pop(); // Save the last partial line

            for (const line of lines) {
              if (line.startsWith('data:')) {
                const data = line.replace(/^data:\s*/, '')

                if (data.match(/^thread:\s*/)) {
                  const threadId = data.replace(/^thread:\s*/, '')
                  setThreadId(threadId)
                } else if (data.match(/^message:\s*/)) {
                  try {
                    const message = data.replace(/^message:\s*/, '')

                    const parsedData = JSON.parse(message).content[0].text.value as string

                    if (!hasTextInResponse) {
                      hasTextInResponse = parsedData.trim().length > 0
                    }

                    if (!capturingCode && (parsedData.trim().startsWith('```') || parsedData.trim().match(/^`{2,}/))) {
                      capturingCode = true
                      continue
                    } else if (capturingCode && parsedData.trim().match(/^`{2,}/)) {
                      capturingCode = false
                      if (userMessage.includes('->ks-ai-script-code-changed<-')) {
                        setMessages(prev => {
                          const updatedMessages = [...prev]

                          const updatedMessage = {...updatedMessages[updatedMessages.length - 1]}
                          if (updatedMessage.chunks.find(chunk => chunk.type === 'code')) {
                            updatedMessage.isAiToEditorModification = true
                          }

                          updatedMessages[updatedMessages.length - 1] = updatedMessage

                          return updatedMessages
                        })
                      }

                      if (userMessage.includes('->ks-ai-script-code-created<-')) {
                        setMessages(prev => {
                          const updatedMessages = [...prev]

                          const updatedMessage = {...updatedMessages[updatedMessages.length - 1]}
                          if (updatedMessage.chunks.find(chunk => chunk.type === 'code')) {
                            updatedMessage.isAiToEditorCreation = true
                          }

                          updatedMessages[updatedMessages.length - 1] = updatedMessage

                          return updatedMessages
                        })
                      }

                      continue
                    }

                    if (capturingCode) {
                      setMessages(prev => {
                        const updatedMessages = [...prev]

                        let updatedMessage: Message = null

                        if (
                          updatedMessages.length === 0 ||
                          updatedMessages[updatedMessages.length - 1].role !== 'assistant' ||
                          updatedMessages[updatedMessages.length - 1].isStatus ||
                          updatedMessages[updatedMessages.length - 1].isAiToEditorStatus
                        ) {
                          updatedMessages.push({
                            role: 'assistant',
                            chunks: [],
                          })
                        }

                        updatedMessage = updatedMessages[updatedMessages.length - 1]

                        if (updatedMessage.chunks.length === 0) {
                          updatedMessage.chunks.push({
                            type: 'code',
                            value: '```' + parsedData + '```',
                          })
                        } else {
                          const lastMessage = updatedMessage.chunks[updatedMessage.chunks.length - 1]
                          const prevType = lastMessage.type
                          const prevValue = lastMessage.value.replaceAll('```', '')

                          if (prevType !== 'code') {
                            updatedMessage.chunks.push({
                              type: 'code',
                              value:  '```' + parsedData + '```',
                            })
                          } else {
                            updatedMessage.chunks[updatedMessage.chunks.length - 1].value = '```' + `${prevValue}${parsedData}` + '```'
                          }
                        }

                        updatedMessages[updatedMessages.length - 1] = updatedMessage

                        return updatedMessages.filter(msg => msg)
                      })
                    } else {
                      setMessages(prev => {
                        const updatedMessages = [...prev]

                        let updatedMessage: Message = null

                        if (
                          updatedMessages.length === 0 ||
                          updatedMessages[updatedMessages.length - 1].role !== 'assistant' ||
                          updatedMessages[updatedMessages.length - 1].isStatus ||
                          updatedMessages[updatedMessages.length - 1].isAiToEditorStatus
                        ) {
                          updatedMessages.push({
                            role: 'assistant',
                            chunks: []
                          })
                        }

                        updatedMessage = updatedMessages[updatedMessages.length - 1]

                        if (updatedMessage.chunks.length === 0) {
                          updatedMessage.chunks.push({
                            type: 'text',
                            value: `${parsedData}`
                          })
                        } else {
                          const lastMessage = updatedMessage.chunks[updatedMessage.chunks.length - 1]
                          const prevType = lastMessage.type
                          const prevValue = lastMessage.value

                          if (prevType !== 'text') {
                            updatedMessage.chunks.push({
                              type: 'text',
                              value: `${parsedData}`
                            })
                          } else {
                            updatedMessage.chunks[updatedMessage.chunks.length - 1].value = `${prevValue}${parsedData}`
                          }
                        }

                        updatedMessages[updatedMessages.length - 1] = updatedMessage

                        return updatedMessages.filter(msg => msg)
                      })
                    }
                  } catch (e) {
                    setMessages(prev => {
                      const updatedMessages = [...prev]

                      let updatedMessage: Message = null

                      if (
                        updatedMessages.length === 0 ||
                        updatedMessages[updatedMessages.length - 1].role !== 'assistant' ||
                        updatedMessages[updatedMessages.length - 1].isStatus ||
                        updatedMessages[updatedMessages.length - 1].isAiToEditorStatus
                      ) {
                        updatedMessages.push({
                          role: 'assistant',
                          chunks: []
                        })
                      }

                      updatedMessage = updatedMessages[updatedMessages.length - 1]

                      if (updatedMessage.chunks.length === 0) {
                        updatedMessage.chunks.push({
                          type: 'text',
                          value: `${data}`
                        })
                      } else {
                        const lastMessageChunk = updatedMessage.chunks[updatedMessage.chunks.length - 1]
                        const prevType = lastMessageChunk.type
                        const prevValue = lastMessageChunk.value

                        if (prevType !== 'text') {
                          updatedMessage.chunks.push({
                            type: 'text',
                            value: `${data}`
                          })
                        } else {
                          updatedMessage.chunks[updatedMessage.chunks.length - 1].value = `${prevValue}${data}`
                        }
                      }

                      updatedMessages[updatedMessages.length - 1] = updatedMessage

                      return updatedMessages.filter(msg => msg)
                    })
                  }
                }
              }
            }
          }
        }

        await processStream()

        if (lines.length === 0 || !hasTextInResponse) {
          throw new EmptyAiResponseError('possible in-progress run blocking new messages, wait for completion')
        }
      } catch (err) {
        if (err instanceof EmptyAiResponseError) {
          setHasMessageInProgress(true)
        } else {
          setThinking(false)

          setFetchedMessages(false)
          setFetchingMessages(false)

          setAwaitingCodeContext(false)
          setAwaitingCodeCreation(false)
          setAwaitingCodeModification(false)
        }
      }
    };

    setThinking(true)
    sendPostAndListen().then(() => {
      setThinking(false)
      setUserMessage('')
    })
  }, [userMessage]);

  useEffect(() => {
    return () => {
      if (postSendAbortController.current) {
        postSendAbortController.current.abort("before unmount: abort pending send-message");
      }

      if (fetchAbortController.current) {
        fetchAbortController.current.abort("before unmount: abort pending fetch-messages");
      }
    };
  }, [])

  useEffect(() => {
    if (awaitingCodeContext && !codeContextAcquired && !thinking) {
      setCodeContextAcquired(true)

      setMessages(prev => {
        const updatedMessages = [...prev]

        updatedMessages.push({
          role: 'assistant',
          chunks: [{
            type: 'text',
            value: `Ready to help with your script: <br/><strong>${scriptTitle}</strong>`
          }]
        })

        return updatedMessages
      })
    }
  }, [awaitingCodeContext, codeContextAcquired, thinking])

  useEffect(() => {
    if (awaitingCodeCreation && !scriptCodeChanged && !thinking) {
      let aiCreatedCodeVal = ''

      const aiCreatedCodeMsg = messages.slice().reverse().find(message => message.isAiToEditorCreation === true)
      if (!aiCreatedCodeMsg) {
        return
      }

      const aiCreatedCodeMsgChunk = aiCreatedCodeMsg.chunks.find(chunk => chunk.type === 'code')

      aiCreatedCodeVal = aiCreatedCodeMsgChunk.value.trim()

      setMessages(prev => {
        const updatedMessages = [...prev]

        updatedMessages.push({
          role: 'assistant',
          chunks: [{
            type: 'text',
            value: `Your script has been created! Take a look at the editor.<br/>
<h3>📝 Follow these steps to run your script:</h3>
1. Save your script (click <strong>Save New Script</strong>).
2. In the bottom right corner of our code editor, click <strong>Activate</strong>.
3. Upon activation, script starts running, ingesting K8s traffic & processing it using the script code.
4. To see the script output, click <strong>Script Logs</strong>.
            `
          }]
        })

        return updatedMessages
      })

      setAiCreatedCode(
        aiCreatedCodeVal
          .replace(/^`{2,}(javascript)?(js)?/g, '')
          .replace(/`{2,}$/g, '')
          .replaceAll(/-?>?ks-ai-script-code-created<?-?/g, '')
          .trim()
      )

      setScriptCodeChanged(true)

      setScriptCodeChanged(false)
      setAwaitingCodeCreation(false)
    }
  }, [awaitingCodeCreation, scriptCodeChanged, messages, thinking])

  useEffect(() => {
    if (awaitingCodeModification && !scriptCodeChanged && !thinking) {
      let aiModifiedCodeVal = ''

      const aiModifiedCodeMsg = messages.slice().reverse().find(message => message.isAiToEditorModification === true)
      if (!aiModifiedCodeMsg) {
        return
      }

      const aiModifiedCodeMsgChunk = aiModifiedCodeMsg.chunks.find(chunk => chunk.type === 'code')

      aiModifiedCodeVal = aiModifiedCodeMsgChunk.value.trim()

      setMessages(prev => {
        const updatedMessages = [...prev]

        updatedMessages.push({
          role: 'assistant',
          chunks: [{
            type: 'text',
            value: `Changed from:\n\`\`\`\n${codeToChange.trim()}\n\`\`\`\nTo:\n${aiModifiedCodeVal}`
          }]
        })

        return updatedMessages
      })

      setAiModifiedCode(
        aiModifiedCodeVal
          .replace(/^`{2,}(javascript)?(js)?/g, '')
          .replace(/`{2,}$/g, '')
          .replaceAll(/-?>?ks-ai-script-code-changed<?-?/g, '')
      )
      setScriptCodeChanged(true)

      setScriptCodeChanged(false)
      setAwaitingCodeModification(false)
    }
  }, [awaitingCodeModification, scriptCodeChanged, messages, thinking])

  const scrollMessages = useCallback(() => {
    if (messagesRef.current !== null) {
      messagesRef.current.scrollTo({
        top: messagesRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [messagesRef.current])

  useInterval(useCallback(() => {
    if (shouldAutoscroll && messagesRef.current !== null) {
      scrollMessages()
    }
  }, [shouldAutoscroll]), 1000, true)

  useEffect(() => {
    if (scriptExists && fetchedMessages) {
      if (!codeContextAcquired) {
        setMessages(prev => {
          const updatedMessages = [...prev]
          updatedMessages.push({
            role: 'assistant',
            chunks: [],
            isCodeContext: true,
          })

          return updatedMessages
        })

        setUserMessage(`
          Sending my current full script code:\n\n\`\`\`${scriptText.replaceAll('\u001b', '')}\`\`\`.\n\n\n
          Once you acquired the code context & ready to receive new user requests,
          respond with "->ks-ai-code-context<-" only to this message.
        `)
      }
    }
  }, [scriptExists, codeContextAcquired, fetchedMessages])

  useEffect(() => {
    if (scriptExists && fetchedMessages && codeContextAcquired) {
      if (!scriptCodeChanged && codeToChange.length > 0 && codeChangePrompt.length > 0) {
        setMessages(prev => {
          const updatedMessages = [...prev]
          updatedMessages.push({
            role: 'assistant',
            chunks: [],
            isAiToEditorStatus: true,
          })

          return updatedMessages
        })

        setUserMessage(`
          I want to modify a part of my script:\n\n\`\`\`${codeToChange.replaceAll('\u001b', '')}\`\`\`.\n\n\n
          What I need to do:\n
          ${codeChangePrompt}\n\n
          You should respond with ->ks-ai-script-code-changed<- & the modified code wrapped with backticks only to this message.
        `)

        setShouldAutoScroll(true)
      }
    }
  }, [scriptExists, codeContextAcquired, scriptCodeChanged, codeToChange, codeChangePrompt, fetchedMessages])

  useEffect(() => {
    if (codeCreatePrompt.length > 0) {
      setMessages(prev => {
        const updatedMessages = [...prev]
        updatedMessages.push({
          role: 'assistant',
          chunks: [],
          isAiToEditorStatus: true,
        })

        return updatedMessages
      })

      setUserMessage(`
      I want to create a Kubeshark script to capture/process certain traffic.
      What I need to do:\n
      ${codeCreatePrompt}\n\n
      The response instructions (only to this message) are:
      Respond with ->ks-ai-script-code-created<-,
      then give the created code wrapped with backticks
      (tip: first line of the code should be a comment with a short script title (without markdown), reflecting the script purpose;
      2nd line - blank line, afterwards - describe the script in single-line comments,
      add blank line, then add these steps to comments:
      Follow these steps to run your script:
      1. Save your script (click Save New Script).
      2. In the bottom right corner of our code editor, click Activate.
      3. Upon activation, script starts running, ingesting K8s traffic & processing it using the script code.
      4. To see the script output, click Script Logs.
      5. Learn more at https://docs.kubeshark.co/en/automation_scripting

      after comments - put the code). Nothing else.
    `)

      setShouldAutoScroll(true)
    }
  }, [codeCreatePrompt])

  const handleEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault()

      const userMessage = e.target.value
      if (!userMessage) {
        return
      }

      setMessages(prev => {
        const updatedMessages = [...prev]

        let updatedMessage: Message = null

        if (updatedMessages.length === 0 || updatedMessages[updatedMessages.length - 1].role !== 'user') {
          updatedMessages.push({
            role: 'user',
            chunks: []
          })
        }

        updatedMessage = updatedMessages[updatedMessages.length - 1]

        updatedMessage.chunks.push({
          type: 'text',
          value: userMessage
        })

        updatedMessages[updatedMessages.length - 1] = updatedMessage

        updatedMessages.push({
          role: 'assistant',
          chunks: [],
          isStatus: true
        })

        return updatedMessages
      })

      setUserMessage(userMessage)

      scrollMessages()

      setShouldAutoScroll(true)

      e.target.value = ''
    }
  }

  const handleOnWheel = useCallback(() => {
    if (!thinking) {
      setShouldAutoScroll(false)
    }
  }, [thinking])

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setWidth(width - 100)
      }}
    >
      <Box
        ref={messagesRef}
        boxSizing='border-box'
        position='relative'
        display='flex'
        alignItems='center'
        flexDirection='column'
        gap='5px'
        height='100%'
        width='100%'
        bgcolor={
          darkModeEnabled
            ? variables.githubEditorBackgroundColorLight
            : variables.mainBackgroundColor
        }
        borderRight={`1px solid ${darkModeEnabled ? variables.slateColor : variables.lighterGrayColor}`}
        overflow='auto'
        onWheel={handleOnWheel}
      >
        {messages.map((message, index) => {
          if (message.role === 'user') {
            return message.chunks.map(messageChunk => {
              return (
                <AiRequest
                  key={messageChunk.value}
                  maxWidth={width}
                  text={messageChunk.value}
                  darkModeEnabled={darkModeEnabled}
                />
              )
            })
          } else if (message.role === 'assistant') {
            if (message.isStatus) {
              return (
                <AiResponseStatus
                  key={`response-status-${index}`}
                  maxWidth={width}
                  completed={messages[index + 1]?.role === 'assistant'}
                  darkModeEnabled={darkModeEnabled}
                />
              )
            } else if (message.isCodeContext) {
              return (
                <AiCodeContext
                  key={`code-context-${index}`}
                  maxWidth={width}
                  scriptTitle={scriptTitle}
                  completed={codeContextAcquired}
                  darkModeEnabled={darkModeEnabled}
                />
              )
            } else if (message.isAiToEditorStatus) {
              return (
                <AiToEditorModification
                  key={`ai-to-editor-modification-${index}`}
                  maxWidth={width}
                  completed={messages[index + 1] !== undefined}
                  darkModeEnabled={darkModeEnabled}
                />
              )
            } else if (
              message.isAiToEditorModification ||
              message.chunks.find(
                chunk =>
                  chunk.value.includes('->ks-ai-code-context<-') ||
                  chunk.value.includes('->ks-ai-script-code-changed<-') ||
                  chunk.value.includes('->ks-ai-script-code-created<-')
              )
            ) {
              return null
            } else {
              return (
                message.chunks.map(messageChunk => {
                  return (
                    <AiResponse
                      key={messageChunk.value}
                      maxWidth={width}
                      text={messageChunk.value}
                      darkModeEnabled={darkModeEnabled}
                    />
                  )
                })
              )
            }
          }
        })}
        {hasMessageInProgress && (
          <AiHasMessageInProgressStatus
            maxWidth={width}
            darkModeEnabled={darkModeEnabled}
          />
        )}
        {!fetchingMessages && messages.length === 0 && (
          <Box
            marginTop='60px'
            boxSizing='border-box'
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            gap='15px'
            textAlign='center'
          >
            <AiAssistantBannerIcon
              stroke={darkModeEnabled ? variables.slateColor : variables.lightGrayColor}
            />
            <h1
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '26px',
                fontWeight: 600,
                color: darkModeEnabled ? variables.headerBackgroundColor : variables.lightGrayColor
              }}
            >
              Kubeshark GenAI Assistant
            </h1>
            <span
              style={{
                fontFamily: 'Source Sans Pro, sans-serif',
                fontSize: '16px',
                fontWeight: 600,
                color: variables.lightGrayColor
              }}
            >
              Gain unlimited network insights using natural language to <br />
              process real-time traffic and generate custom reports, metrics, <br />
              and automations, leveraging the latest Gen-AI advancements.
            </span>
          </Box>
        )}
        {fetchingMessages && messages.length === 0 && (
          <Box
            marginTop='120px'
            boxSizing='border-box'
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            gap='15px'
            textAlign='center'
          >
            <CircularProgress
              size={64}
              sx={{
                marginBottom: '30px',
                color: darkModeEnabled ? variables.slateColor : variables.lightGrayColor
              }}
            />
            <h1
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '26px',
                fontWeight: 600,
                color: darkModeEnabled ? variables.headerBackgroundColor : variables.lightGrayColor
              }}
            >
              Conversation
            </h1>
            <span
              style={{
                fontFamily: 'Source Sans Pro, sans-serif',
                fontSize: '16px',
                fontWeight: 600,
                color: variables.lightGrayColor
              }}
            >
              Your conversation history is almost here!
            </span>
          </Box>
        )}
        <Box
          boxSizing='border-box'
          position='sticky'
          bottom='0'
          width='100%'
          height='100%'
          padding='20px 16px'
          display='flex'
          alignItems='end'
          bgcolor={
            darkModeEnabled
              ? variables.githubEditorBackgroundColorLight
              : variables.mainBackgroundColor
          }
        >
          <TextareaAutosize
            className={`${styles.Textarea} ${darkModeEnabled ? styles.Dark : styles.Light}`}
            disabled={thinking || hasMessageInProgress}
            placeholder={
              thinking ?
                '⏳ Processing your request...' :
                (hasMessageInProgress ?
                  '⏳ Your previous message is being processed by assistant. It will appear soon!' :
                  '⚡ Ask our GenAI to change/fix the code'
                )
            }
            onKeyDown={!thinking && !hasMessageInProgress ? handleEnterPress: null}
            style={{
              cursor: thinking ? 'wait' : null,
            }}
          />
        </Box>
      </Box>
    </ResizeObserver>
  )
}
