import React from 'react'

interface IconProps {
  stroke: string
  size?: number
}

export const RobotIcon: React.FC<IconProps> = ({ stroke, size = '22' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={stroke}
      strokeWidth='1.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M12 6V2H8' />
      <path d='m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z' />
      <path d='M2 12h2' />
      <path d='M9 11v2' />
      <path d='M15 11v2' />
      <path d='M20 12h2' />
    </svg>
  )
}
