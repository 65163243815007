import React from 'react'

import * as UI from './components/UI'

import { PushLicenseOnOidcAuth } from './components/healdess/PushLicenseOnOidcAuth'
import { TrafficPage } from './components/Pages/TrafficPage/TrafficPage'

import { appDefaultAuthTypeEnabled } from './types/global'

import { useSetRecoilState } from 'recoil'
import onlineAtom from './recoil/online'

import { Detector } from 'react-detect-offline'

import useSentryTracking from './hooks/useSentryTracking'
import useAuthTracker from './hooks/useAuthTracker'
import useInterceptor from './hooks/useInterceptor'
import useLicenseUpdater from './hooks/useLicenseUpdater'
import { useIntercomChat } from './hooks/useIntercomChat'
import { useIntercomEvents } from './hooks/useIntercomEvents'
import useElementRouter from './hooks/useElementRouter'
import useQueryRestore from './hooks/useQueryRestore'

export const AppLoader: React.FC = () => {
  useSentryTracking()
  useAuthTracker()
  useInterceptor()
  useLicenseUpdater()
  useIntercomChat()
  useIntercomEvents()
  useElementRouter()
  useQueryRestore()

  const setOnline = useSetRecoilState(onlineAtom)

  return (
    <>
      <Detector render={({ online }) => {
        setOnline(online)
        return null
      }} />
      <UI.Auth>
        <>
          {appDefaultAuthTypeEnabled() && <PushLicenseOnOidcAuth />}
          <div className="kubesharkApp">
            <TrafficPage />
          </div>
        </>
      </UI.Auth>
    </>
  )
}
