import Intercom from '@intercom/messenger-js-sdk'
import { getAuthSessionDetails } from '../components/UI/Auth/AuthSessionDetails'
import {
  appAuthEnabled,
  appAuthType,
  appDefaultAuthTypeEnabled,
  appSamlAuthTypeEnabled,
  appSupportChatEnabled
} from '../types/global'
import authSessionOkAtom from '../recoil/authSessionOk'
import { useRecoilValue } from 'recoil'
import { getSamlAttributesPayload } from '../components/UI/Auth/SamlAuth/Attributes'
import { extractJwtPayload } from '../components/UI/Auth/SamlAuth/SamlAuth'
import { useCallback, useEffect } from 'react'

import { HubBaseUrl, LocalStorageKey, SamlAuthType } from '../consts'
import { v4 as uuidv4 } from 'uuid';

const issueVisitorUserId = (): string => {
  let visitorUserId = localStorage.getItem(LocalStorageKey.IntercomVisitorUserId)

  if (visitorUserId === null) {
    visitorUserId = `visitor__${uuidv4()}`
    localStorage.setItem(LocalStorageKey.IntercomVisitorUserId, visitorUserId)
  }

  return visitorUserId
}

const obtainUserHmac = async (userId: string) => {
  if (userId === null || userId === "") {
    return null
  }

  const url = new URL(`${HubBaseUrl}/intercom/user/obtain-hmac`)

  try {
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Kubeshark-Capture': 'ignore',
      },
      body: JSON.stringify({ userId })
    })
    const responseJson = await response.json()
    const hmac: string = responseJson?.hmac
    return hmac
  } catch (err) {
    console.error(err)
    return null
  }
}

export const useIntercomChat = () => {
  const authSessionOk= useRecoilValue(authSessionOkAtom)

  const getUserInfo = useCallback(() => {
    const user = {
      user_id: null,
      name: null,
      email: null
    }

    if (appAuthEnabled()) {
      if (appDefaultAuthTypeEnabled()) {
        const sessionDetails = getAuthSessionDetails()
        if (sessionDetails !== null) {
          user.user_id = sessionDetails.userId
          user.email = sessionDetails.email
          user.name = sessionDetails.name
        }
      } else if (appSamlAuthTypeEnabled()) {
        if (authSessionOk) {
          const jwtPayload = extractJwtPayload() ?? []
          if (jwtPayload['sub'] !== undefined) {
            user.user_id = jwtPayload['sub']
          }

          const samlAttributes = getSamlAttributesPayload()
          if (samlAttributes['email'][0] !== undefined) {
            user.name = samlAttributes['email'][0]
            user.email = samlAttributes['email'][0]
          }
        }
      }
    }

    if (user.user_id === null) {
      user.name = `Visitor`
      user.user_id = issueVisitorUserId()
    }

    return user
  }, [authSessionOk])

  useEffect(() => {
    if (!appSupportChatEnabled() || (
      appAuthEnabled() && appAuthType() === SamlAuthType && !authSessionOk
    )) {
      return
    }

    const user = getUserInfo()

    obtainUserHmac(user.user_id).then(user_hash => {
      if (window.Intercom === undefined) {
        Intercom({ app_id: 'ulyaoqvr', ...user, user_hash })
      } else {
        window.Intercom('shutdown')
        window.Intercom('boot', {app_id: 'ulyaoqvr', ...user, user_hash})
      }
    })
  }, [authSessionOk])
}
