import React from 'react'
import {
  appAuthEnabled,
  appDefaultAuthTypeEnabled
} from '../../../../types/global'
import { AuthProvider } from '@descope/react-sdk'

interface OidcAuthProviderProps {
  children: React.ReactElement
}

const isDefaultAuthReady = (): boolean => {
  return appAuthEnabled() && appDefaultAuthTypeEnabled()
}

export const DefaultAuthProvider: React.FC<OidcAuthProviderProps> = ({
  children
}) => {
  if (isDefaultAuthReady()) {
    return <AuthProvider projectId='P2PyftOipp2EcXXRyivNwlJkUMHm'>{children}</AuthProvider>
  }
  return children
}
