import React from 'react'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { Box, ButtonBase } from '@mui/material'

import variables from '../../../../variables.module.scss'

interface FloatingSaveChangesButtonProps {
  saveChangesButtonClicked: boolean
  handleSaveScriptChanges: () => void
  hide: boolean
}

export const FloatingSaveChangesButton: React.FC<FloatingSaveChangesButtonProps> = ({
  saveChangesButtonClicked,
  handleSaveScriptChanges,
  hide,
}) => {
  return (
    <ButtonBase
      component={Box}
      onClick={saveChangesButtonClicked ? null : handleSaveScriptChanges}
      sx={{
        display: hide ? 'none' : null,
        position: 'absolute',
        top: '80px',
        left: '50%',
        padding: '4px 10px',
        backgroundColor: variables.warningColor,
        borderRadius: '4px',
        transform: 'translateX(-50%)',
        opacity: saveChangesButtonClicked ? 0.7 : 1,
        pointerEvents: saveChangesButtonClicked ? 'none' : 'all',
        zIndex: 1000
      }}
    >
      <span
        style={{
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: 500,
          color: variables.grayColor
        }}
      >
        <SaveRoundedIcon
          sx={{
            mr: '5px',
            transform: 'translateY(2px)',
            fontSize: '14px'
          }}
        />
        {saveChangesButtonClicked
          ? 'Saving changes...'
          : 'Script changed. Click to save'}
      </span>
    </ButtonBase>
  )
}
