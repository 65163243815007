import React from 'react'
import { Box, ButtonBase } from '@mui/material'
import variables from '../../variables.module.scss'
import { ArrowBackRounded } from '@mui/icons-material'

export const GoToDashboardButton = () => {
  return (
    <ButtonBase
      component={Box}
      onClick={() => location.replace(window.location.origin)}
      sx={{
        padding: '8px 16px',
        marginTop: '80px',
        marginBottom: '20px',
        backgroundColor: variables.blueColor,
        borderRadius: '6px',
        maxHeight: '38px',
        zIndex: 1000
      }}
    >
      <span
        style={{
          fontFamily: 'Roboto, sans-serif',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '2em',
          letterSpacing: '0.02em',
          color: variables.whiteColor
        }}
      >
        <ArrowBackRounded
          sx={{
            mr: '10px',
            transform: 'translateY(5px)',
            fontSize: '20px'
          }}
        />
        Go to Dashboard
      </span>
    </ButtonBase>
  )
}
