import { Utils } from './helpers/Utils'

const initSamlAuthPath = '/init-saml'

const hostWithPath = Utils.cleanURL(`${window.location.host}${window.location.pathname}`, [
  initSamlAuthPath
])

const HubBaseUrl = `${window.location.protocol !== "https:" ? "http" : "https"}://${hostWithPath}api`
const HubWsUrl = `${window.location.protocol !== "https:" ? "ws" : "wss"}://${hostWithPath}api/ws`
const HubScriptLogsWsUrl = `${window.location.protocol !== "https:" ? "ws" : "wss"}://${hostWithPath}api/scripts/logs`

const ColorGreen = "#d2fad2"
const ColorRed = "#fad6dc"
const ColorYellow = "#f6fad2"
const ColorWhite = "#ffffff"

const LinkDocs = "https://docs.kubeshark.co";
const LinkSupport = "https://kubeshark.co/support";
const LinkAdminConsole = "https://console.kubeshark.co/?origin=upgrade";
const LinkContactUs = "https://www.kubeshark.co/contact-us";
const LinkPricingPage = "https://www.kubeshark.co/pricing";
const LinkTrafficCapture = "https://docs.kubeshark.co/en/on_off_switch";
const LinkCaptureFilters = "https://docs.kubeshark.co/en/pod_targeting";
const LinkProtocolDissectors = "https://docs.kubeshark.co/en/protocols";
const LinkKflSyntaxReference = "https://docs.kubeshark.co/en/filtering#kfl-syntax-reference"
const LinkHelmChartReadme = "https://github.com/kubeshark/kubeshark/blob/master/helm-chart/README.md"

const LicenseAnonymousEdition = "community";
const LicenseCommunityEdition = "COMMUNITY";
const LicenseProEdition = "PRO";
const LicenseEnterpriseEdition = "ENTERPRISE";
const LicenseOndemandEdition = "ON-DEMAND";
const LicenseProCompEdition = "PRO-COMP";
const LicenseProUnlEdition = "PRO-UNL";

const HttpStatusFound = 302
const HttpStatusUnauthorized = 401
const HttpStatusNodeLimitOverflow = 418

const DefaultAuthType = 'default'
const OidcAuthType = 'oidc'
const DexAuthType = 'dex'
const SamlAuthType = 'saml'

const SamlAuthCookieName = 'saml_token'

enum LocalStorageKey {
  Timezone = 'kubeshark.timezone',
  ShouldRestoreQuery = 'kubeshark.shouldRestoreQuery',
  AuthSessionDetails = 'kubeshark.authSessionDetails',
  IntercomVisitorUserId = 'kubeshark.intercomVisitorUserId',
  ScriptingAssistantConversations = 'kubeshark.scriptingAssistantConversations',
  ServiceMapEdgeType = 'kubeshark.serviceMapEdgeType',
  ServiceMapNodeType = 'kubeshark.serviceMapNodeType',
  ServiceMapShowCumulative = 'kubeshark.serviceMapShowCumulative',
  ServiceMapShowRequests = 'kubeshark.serviceMapShowRequests',
  ServiceMapShowResponses = 'kubeshark.serviceMapShowResponses',
  ServiceMapNamespacesHidden = 'kubeshark.serviceMapNamespacesHidden',
  ServiceMapProtocolsHidden = 'kubeshark.serviceMapProtocolsHidden',
}

enum SessionStorageKey {
  PageRefreshed = 'kubeshark.pageRefreshed',
}

enum ScriptingKeyword {
  ClearConsoleLogs = 'ks-script-internals:clear_console_logs'
}

export {
  initSamlAuthPath,
  HubBaseUrl,
  HubWsUrl,
  HubScriptLogsWsUrl,
  ColorGreen,
  ColorRed,
  ColorYellow,
  ColorWhite,
  LinkDocs,
  LinkSupport,
  LinkAdminConsole,
  LinkContactUs,
  LinkPricingPage,
  LinkTrafficCapture,
  LinkCaptureFilters,
  LinkProtocolDissectors,
  LinkKflSyntaxReference,
  LinkHelmChartReadme,
  LicenseAnonymousEdition,
  LicenseCommunityEdition,
  LicenseProEdition,
  LicenseEnterpriseEdition,
  LicenseOndemandEdition,
  LicenseProCompEdition,
  LicenseProUnlEdition,
  HttpStatusFound,
  HttpStatusUnauthorized,
  HttpStatusNodeLimitOverflow,
  DefaultAuthType,
  OidcAuthType,
  DexAuthType,
  SamlAuthType,
  SamlAuthCookieName,
  LocalStorageKey,
  SessionStorageKey,
  ScriptingKeyword,
}
