import React, { useCallback, useState } from 'react'
import styles from './EntriesList.module.sass';
import down from "./assets/downImg.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import entriesAtom from "../../recoil/entries";
import { TrafficEntry } from "../TrafficEntry/TrafficEntry";
import { Entry } from '../EntryListItem/Entry'
import { VirtualizedList, VirtualizedListHandle } from '../UI/VirtualizedList/VirtualizedList'
import useStreamingList from '../TrafficViewer/useStreamingList'
import { useInterval } from '../../helpers/interval'
import focusedEntryAtom from '../../recoil/focusedEntry'
import focusedItemAtom from '../../recoil/focusedItem'
import focusedStreamAtom from '../../recoil/focusedStream'
import focusedRecordAtom from '../../recoil/focusedRecord'
import { heapCacheObject } from '../../helpers/cacher/cacher'
import cacheableEntryFields from '../TrafficEntry/cacheableEntryFields'
import variables from '../../variables.module.scss'
import { Fab } from '@mui/material'
import { ArrowDownwardRounded } from '@mui/icons-material'
import entryDetailsOpenAtom from '../../recoil/entryDetailsOpen'

const TRAFFIC_ENTRY_HEIGHT = 62

interface EntriesListProps {
  scrollableRef: React.MutableRefObject<VirtualizedListHandle>;
}

export const EntriesList: React.FC<EntriesListProps> = ({
  scrollableRef,
}) => {
  const entries = useRecoilValue(entriesAtom)
  const entryDetailsOpen = useRecoilValue(entryDetailsOpenAtom)
  const [focusedEntry, setFocusedEntry] = useRecoilState(focusedEntryAtom)
  const setFocusedItem = useSetRecoilState(focusedItemAtom)
  const setFocusedStream = useSetRecoilState(focusedStreamAtom)
  const setFocusedRecord = useSetRecoilState(focusedRecordAtom)

  const { toBottom, setToBottom } = useStreamingList()

  const renderItem = useCallback((item: Entry, index: number, style) => {
    return (
      <TrafficEntry
        key={item.key}
        entry={item}
        duplicateOrigin={
          item.duplicate !== '' ? entries.get(item.duplicate) : null
        }
        style={style}
      />
    );
  }, []);

  const [, setRenderClock] = useState(0)
  const [lastEntriesSize, setLastEntriesSize] = useState(0)

  useInterval(useCallback(() => {
    if (entries.size > 0 && focusedEntry === null) {
      const entry = heapCacheObject([...entries.values()][0], cacheableEntryFields)
      setFocusedEntry(entry)
      setFocusedItem(entry.id)
      setFocusedStream(entry.stream)
      setFocusedRecord(entry.record)
    }

    if (toBottom && entries.size !== lastEntriesSize) {
      setRenderClock(Date.now())
      setLastEntriesSize(entries.size)
    }
  }, [toBottom, focusedEntry, lastEntriesSize]), 1000, true)

  return (
    <React.Fragment>
      <div className={styles.list}>
        <div id='list' className={styles.list}>
          <VirtualizedList
            ref={scrollableRef}
            itemHeight={TRAFFIC_ENTRY_HEIGHT}
            renderItem={renderItem}
            items={[...entries.values()]}
            isSnappedToBottom={toBottom}
            setIsSnappedToBottom={setToBottom}
            onSnapBroken={() => setToBottom(false)}
          />
          {!toBottom && entryDetailsOpen && (
            <button
              type='button'
              title='Show latest items'
              className={`${styles.btnLive} ${
                toBottom ? styles.hideButton : styles.showButton
              }`}
              onClick={() => {
                scrollableRef.current.jumpToBottom()
                setToBottom(true)
              }}
            >
              <img alt='down' src={down} />
            </button>
          )}
          {!toBottom && !entryDetailsOpen && (
            <Fab
              className={styles.btnLiveWide}
              variant='extended'
              color='default'
              size='small'
              onClick={() => {
                scrollableRef.current.jumpToBottom()
                setToBottom(true)
              }}
              sx={{
                paddingLeft: '13px',
                paddingRight: '16px',
                backgroundColor: variables.blueColor,
                '&:hover': {
                  backgroundColor: variables.darkBlueColor
                }
              }}
            >
              <ArrowDownwardRounded
                htmlColor={variables.mainBackgroundColor}
                sx={{
                  mr: '6px',
                  fontSize: '18px'
                }}
              />
              <span
                style={{
                  fontSize: '13px',
                  fontWeight: 500,
                  textTransform: 'initial',
                  letterSpacing: '0.01em',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  color: variables.mainBackgroundColor
                }}
              >
                Go to new items
              </span>
            </Fab>
          )}
        </div>
      </div>
    </React.Fragment>
  )
};
