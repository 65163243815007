import React from 'react'
import { Box } from '@mui/material'
import variables from '../../../../../variables.module.scss'
import Markdown from 'markdown-to-jsx'
import { SyntaxWrapper } from '../AiResponse/AiResponse'

interface AiRequestProps {
  maxWidth: number
  text: string
  darkModeEnabled: boolean
}

export const AiRequest: React.FC<AiRequestProps> = ({ maxWidth, text, darkModeEnabled }) => {
  return (
    <Box
      boxSizing='border-box'
      padding='12px 22px'
      marginTop='16px'
      marginRight='16px'
      borderRadius='28px'
      maxWidth={maxWidth}
      alignSelf='flex-end'
      flex='1'
      bgcolor={darkModeEnabled ? variables.slateColor : variables.lightGrayBlueColor}
      sx={{
        borderBottomRightRadius: 0,
        overflowWrap: 'break-word'
      }}
    >
      <span
        style={{
          fontSize: '13px',
          fontWeight: 500,
          fontFamily: 'Noto Sans, sans-serif',
          lineHeight: '22px',
          letterSpacing: '0.01em',
          color: darkModeEnabled ? variables.lightGrayBlueColor : variables.grayColor,
        }}
      >
        <Markdown
          options={{
            overrides: {
              code: {
                component: SyntaxWrapper
              },
              ol: ({ children, ...props }) => {
                return <ol
                  {...props}
                  style={{
                    paddingLeft: '16px',
                  }}
                >
                  {children}
                </ol>
              }
            }
          }}
        >
          {text}
        </Markdown>
      </span>
    </Box>
  )
}
