import React, { useState } from 'react'
import { Box } from '@mui/material'
import Queryable from '../../../UI/Queryable/Queryable'

import { EntryPoint } from '../../../../types/trafficEntry'
import { EntrySource } from '../ResolutionData'
import { Utils } from '../../../../helpers/Utils'
import { useRecoilValue } from 'recoil'
import entryDetailsOpenAtom from '../../../../recoil/entryDetailsOpen'

import classNames from 'classnames'
import variables from '../../../../variables.module.scss'
import styles from './IpWithPort.module.sass'
import '../../TrafficEntry.module.sass'

interface IpWithPortProps {
  ip: string
  port: string
  point: EntryPoint
}

const longIpThresholdLength = 15

export const IpWithPort: React.FC<IpWithPortProps> = ({ ip, port, point }) => {
  const entryDetailsOpen = useRecoilValue(entryDetailsOpenAtom)
  const [compactIp] = useState((ip?.length > longIpThresholdLength) && entryDetailsOpen)

  const justifyContent = point === EntrySource ? styles.IpWithPortEnd : styles.IpWithPortStart
  const wider = !entryDetailsOpen ? styles.Wider : null

  return (
    <Box className={classNames(styles.IpWithPort, justifyContent, wider)}>
      <Queryable
        query={`${point}.ip == "${ip}"`}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          position: 'absolute',
          top: '12px'
        }}
        tooltipStyle={point === EntrySource ? {
          position: 'absolute',
          top: '-5px'
        } : {
          position: 'absolute',
          right: '25px',
          top: '-5px'
        }}
      >
        <span className={`MonoText ${compactIp ? 'Smaller' : ''}`}>
          {compactIp ? Utils.truncateString(ip, 10, 4) : ip}:
        </span>
      </Queryable>
      <Queryable
        query={`${point}.port == "${port}"`}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          position: 'absolute',
          top: '12px'
        }}
        tooltipStyle={ point === EntrySource ? {
          position: 'absolute',
          top: '-5px'
        } : {
          position: 'absolute',
          right: 0,
          top: '-5px'
        }}
      >
        <span
          className={`MonoText ${compactIp ? 'Smaller' : ''}`}
          style={{ color: variables.lightGrayColor }}
        >
          {port}
        </span>
      </Queryable>
    </Box>
  )
}
