import { getRefreshToken, getSessionToken } from '@descope/react-sdk'
import fetchIntercept from 'fetch-intercept'
import { HttpStatusUnauthorized, HttpStatusNodeLimitOverflow } from '../consts'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_STOPPED, TRAFFIC_PLAYBACK_LOCKED, TRAFFIC_PLAYBACK_OK } from '../recoil/trafficPlayback/atom'
import {
  appAuthEnabled,
  appDefaultAuthTypeEnabled,
  appDexAuthTypeEnabled
} from '../types/global'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import authSessionLogoutAtom from '../recoil/authSessionLogout/atom'
import useSyncedRef from './useSyncedRef'
import useLicense from './useLicense'
import authSessionOkAtom from '../recoil/authSessionOk'

const licenseRoute = "/license"

const nonLicenseRoutes = [licenseRoute]

const checkForNonLicenseRoute = (response: Response) => {
  return nonLicenseRoutes.findIndex(nonLicenseRoute => response.url.includes(nonLicenseRoute)) !== -1
}

const publicRoutes = ["/pods/targeted"]

const checkForPublicRoute = (response: Response) => {
  return publicRoutes.findIndex(publicRoute => response.url.includes(publicRoute)) !== -1
}

const useInterceptor = () => {
  const authSessionOk = useRecoilValue(authSessionOkAtom)

  const [trafficPlayback, setTrafficPlayback] = useRecoilState(trafficPlaybackAtom)
  const { setNodeLimitOverflow } = useLicense()

  const setAuthSessionLogout = useSetRecoilState(authSessionLogoutAtom)

  const trafficPlaybackRef = useSyncedRef(trafficPlayback)

  fetchIntercept.register({
    request: function (url, config: { headers } = { headers: [] }) {
      const requestUrl = new URL(url, window.location.origin)

      const pathArr = requestUrl.pathname.split('/')

      if (pathArr.length > 0 && pathArr[pathArr.length - 1] === 'license') {
        return [requestUrl.toString(), config]
      }

      if (
        appAuthEnabled() &&
        appDefaultAuthTypeEnabled() &&
        requestUrl.host === window.location.host
      ) {
        if (!config?.headers) {
          config.headers = {}
        }

        config.headers['X-Authorization'] = getSessionToken()
        config.headers['X-Refresh-Token'] = getRefreshToken()
      }

      return [requestUrl.toString(), config]
    },
    response: function (response) {
      const isAuthEnabled = appAuthEnabled() && appDexAuthTypeEnabled()
      if (isAuthEnabled) {
        if (!authSessionOk) {
          if (response.headers.has('X-Redirect-Location')) {
            window.location.href = response.headers.get('X-Redirect-Location')
            return response
          }
        }
      }

      if (response?.url?.includes(window.location.hostname)) {
        if (response.status === HttpStatusUnauthorized) {
          setTrafficPlayback(TRAFFIC_PLAYBACK_STOPPED)
          if (response.headers.get('X-Unauthorized-Reason') !== 'license') {
            setAuthSessionLogout(Date.now())
          }
          return response
        }

        if (response.status === HttpStatusNodeLimitOverflow) {
          setTrafficPlayback(TRAFFIC_PLAYBACK_STOPPED)
          setNodeLimitOverflow(true)
        } else if (response.ok) {
          if (!checkForNonLicenseRoute(response) && !checkForPublicRoute(response)) {
            if (trafficPlaybackRef.current !== TRAFFIC_PLAYBACK_LOCKED) {
              setTrafficPlayback(TRAFFIC_PLAYBACK_OK)
            }
          }
        }
      }
      return response
    }
  })
}

export default useInterceptor
