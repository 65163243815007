import React from 'react'
import { Box } from '@mui/material'
import { CodeRounded } from '@mui/icons-material'

import variables from '../../../../../variables.module.scss'
import styles from '../ScriptBrewAiAssistant.module.scss'

interface AiCodeContextProps {
  maxWidth: number
  scriptTitle: string
  completed: boolean
  darkModeEnabled: boolean
}

export const AiCodeContext: React.FC<AiCodeContextProps> = ({ maxWidth, scriptTitle, completed, darkModeEnabled }) => {
  return (
    <Box
      boxSizing='border-box'
      position='relative'
      display='flex'
      alignItems='center'
      gap='24px'
      padding='8px 16px'
      width='100%'
      alignSelf='flex-start'
      flex='1'
      bgcolor='transparent'
      maxWidth={maxWidth}
    >
      <Box
        boxSizing='border-box'
        minWidth='40px'
        width='40px'
        height='40px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderRadius='6px'
        bgcolor={darkModeEnabled ? variables.slateColor : variables.lightGrayBlueColor}
      >
        <CodeRounded
          htmlColor={
            darkModeEnabled
              ? variables.lighterGrayBlueColor
              : variables.slateColor
          }
        />
      </Box>
      <Box
        boxSizing='border-box'
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        gap='5px'
      >
        <Box
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans, sans-serif',
            lineHeight: '24px',
            color: darkModeEnabled ? variables.lightestGrayColor : variables.grayColor,
          }}
        >
          <Box style={{ fontWeight: 600 }}>
            {completed ? (
              <span>Script context acquired!</span>
            ) : (
              <Box className={`${darkModeEnabled ? styles.AssistantProcessTextDark : styles.AssistantProcessTextLight}`}>
                Acquiring
                <Box
                  boxSizing='border-box'
                  margin='0 5px'
                  padding='3px 6px'
                  display='inline-flex'
                  alignItems='center'
                  gap='5px'
                  borderRadius='6px'
                  bgcolor={
                    darkModeEnabled ?
                      variables.githubEditorBackgroundColor :
                      variables.lightGrayBlueColor
                  }
                  sx={{
                    verticalAlign: 'middle'
                  }}
                >
                  <CodeRounded
                    htmlColor={darkModeEnabled ? variables.lightGrayBlueColor : variables.darkSlateColor}
                    sx={{
                      fontSize: '14px'
                    }}
                  />
                  <span
                    style={{
                      maxWidth: '120px',
                      fontSize: '12px',
                      fontWeight: 600,
                      fontFamily: 'Source Sans Pro, sans-serif',
                      lineHeight: '1em',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: darkModeEnabled ? variables.lightGrayBlueColor : variables.darkSlateColor,
                    }}
                  >
                    {scriptTitle}
                  </span>
                </Box>
                code context...
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
