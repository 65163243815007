import React from 'react'
import { Box } from '@mui/material'
import { RobotIcon } from '../../../../UI/Icons/RobotIcon'
import { TypeAnimation } from 'react-type-animation'

import variables from '../../../../../variables.module.scss'
import styles from '../ScriptBrewAiAssistant.module.scss'

interface AiResponseStatusProps {
  maxWidth: number
  completed: boolean
  darkModeEnabled: boolean
}

export const AiResponseStatus: React.FC<AiResponseStatusProps> = ({ maxWidth, completed, darkModeEnabled }) => {
  return (
    <Box
      boxSizing='border-box'
      position='relative'
      display='flex'
      alignItems='flex-start'
      gap='24px'
      padding='8px 16px'
      width='100%'
      alignSelf='flex-start'
      flex='1'
      bgcolor='transparent'
      maxWidth={maxWidth}
    >
      <Box
        boxSizing='border-box'
        minWidth='40px'
        width='40px'
        height='40px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderRadius='6px'
        bgcolor={darkModeEnabled ? variables.slateColor : variables.lightGrayBlueColor}
      >
        <RobotIcon
          stroke={
            darkModeEnabled
              ? variables.lighterGrayBlueColor
              : variables.slateColor
          }
        />
      </Box>
      <span
        style={{
          marginTop: '8px',
          fontSize: '14px',
          fontWeight: 400,
          fontFamily: 'Noto Sans, sans-serif',
          lineHeight: '24px',
          color: darkModeEnabled ? variables.lighterGrayColor : variables.grayColor,
          transition: 'all .2s ease'
        }}
      >
        <strong style={{ fontWeight: 700 }}>
          {!completed && <TypeAnimation
            className={darkModeEnabled ? styles.AssistantProcessTextDark : styles.AssistantProcessTextLight}
            sequence={[
              'Thinking...',
              4000,
              'Summarizing...',
              4000,
              'Considering your requirements...',
              4000,
              'Seeking for an answer...',
              4000,
              'Composing solution...',
              4000,
            ]}
            wrapper="span"
            cursor={false}
            speed={50}
            repeat={0}
          />}
          {completed && 'Answering:'}
        </strong>
      </span>
    </Box>
  )
}
