import React, { useEffect } from 'react'
import { useDescope } from '@descope/react-sdk'

import { useRecoilValue } from 'recoil'
import licenseInfoAtom from '../../recoil/licenseInfo'

import useLicense from '../../hooks/useLicense'

import { appCloudLicenseEnabled, appDefaultAuthTypeEnabled } from '../../types/global'
import { useTrafficCapturingStatus } from '../../hooks/useTrafficCapturingStatus'

export const PushLicenseOnOidcAuth: React.FC = () => {
  if (!appCloudLicenseEnabled() || !appDefaultAuthTypeEnabled()) {
    return null
  }

  const licenseInfo = useRecoilValue(licenseInfoAtom)

  const descopeSdk = useDescope()
  const { getLicense, postPushLicense } = useLicense()
  const { getCapturingStatus } = useTrafficCapturingStatus()

  useEffect(() => {
    if (licenseInfo === null) {
      return
    }

    const { userId, email } = licenseInfo.doc
    if (userId.length > 0 && email.length > 0) {
      return
    }

    if (descopeSdk.getSessionToken()) {
      descopeSdk.me().then((sdkResponse) => {
        const { customAttributes } = sdkResponse.data
        postPushLicense(customAttributes['license']).then(
          () => getLicense(false).then(() => getCapturingStatus())
        )
      })
    }
  }, [licenseInfo])

  return null
}
