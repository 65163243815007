import style from './StatusBar.module.sass';
import React, { useCallback, useEffect, useState } from 'react'
import Queryable from "../Queryable/Queryable";
import variables from '../../../variables.module.scss'

import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from '../../../recoil/trafficPlayback/atom'
import { useRecoilValue } from 'recoil'

import { Box, ButtonBase, Fade } from '@mui/material'

import { appTargetedPodsUpdateDisabled, checkTargetedPodsUpdateDisabled } from '../../../types/global'
import { useAuth } from '../Auth/Auth';

import targetedPodsAtom from '../../../recoil/targetedPods'
import useTargeting from '../../../hooks/useTargeting'
import useElementRouter from '../../../hooks/useElementRouter'
import { authorizeAction, AUTHZ_ACTIONS } from '../Auth/SamlAuth/Authorization'
import Tooltip from '@mui/material/Tooltip'
import { V1Pod } from '@kubernetes/client-node'
import { useInterval } from '../../../helpers/interval'

const pluralize = (noun: string, amount: number) => {
  return `${noun}${amount !== 1 ? 's' : ''}`
}

const uniqueNamespaces = (targets: V1Pod[]) => {
  return [...new Set(targets.map(pod => `[${pod.metadata.namespace}]`))];
}

const uniqueNodes = (targets: V1Pod[]) => {
  return [...new Set(targets.map((pod): string => JSON.stringify(pod.spec.nodeName)))]
}


interface StatusBarContentProps {
  expandedBar: boolean;
  setExpandedBar: (v: boolean) => void;
}

const StatusBarContent: React.FC<StatusBarContentProps> = ({
  expandedBar,
  setExpandedBar,
}) => {
  const targets = useRecoilValue(targetedPodsAtom)

  useEffect(() => {
    if (!expandedBar) {
      return
    }

    if (!checkTargetedPodsUpdateDisabled()) {
      setExpandedBar(expandedBar)
    }
  }, [expandedBar])

  const { navigateToElement } = useElementRouter()

  return (
    <div className={style.statusBarContainer} onMouseOver={() => setExpandedBar(true)} onMouseLeave={() => setExpandedBar(false)}>
      <div className={`${style.statusBar} ${(expandedBar && targets?.length > 0 ? `${style.expandedStatusBar}` : "")}`} data-cy="expandedStatusBar">
        <div className={style.podsCount}>
          {targets !== null && targets?.length > 0 && (
            <span className={style.podsCountText} data-cy="podsCountText">
              {`
                Targeting ${targets.length} ${pluralize('pod', targets.length)}
                in ${uniqueNamespaces(targets).length} ${pluralize('namespace', uniqueNamespaces(targets).length)}
                across ${uniqueNodes(targets).length} ${pluralize('node', uniqueNodes(targets).length)}
              `}
            </span>
          )}
          {(targets === null || targets?.length === 0) && <span className={style.podsCountText}>
            No pods to target by current regex/namespaces
          </span>}
          {!appTargetedPodsUpdateDisabled() && (
            <Tooltip arrow title={authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS) ? 'Edit targeting settings' : 'You are unauthorized to edit targeting settings'}>
              <ButtonBase onClick={() => {
                if (authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)) {
                  navigateToElement('settings:targeting')
                }
              }}>
                <span style={{
                  fontFamily: variables.textFontFamily,
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                  color: variables.dataBackgroundColor,
                  textTransform: 'lowercase',
                  textDecoration: 'underline',
                  textDecorationColor: variables.dataBackgroundColor,
                  opacity: authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS) ? 1 : 0.5
                }}>
                  [edit]
                </span>
              </ButtonBase>
            </Tooltip>
          )}
        </div>
        {expandedBar && targets !== null && targets?.length > 0 && <div className={style.tableContainer}>
          <table>
            <thead>
              <tr>
                <th style={{ width: "40%" }}>Pod name</th>
                <th style={{ width: "20%" }}>Namespace</th>
                <th style={{ width: "20%" }}>Node name</th>
              </tr>
            </thead>
            <tbody>
              {targets.map(pod => <tr key={pod.metadata.uid}>
                <td style={{ position: "relative", width: "40%" }}>
                  <Queryable
                    query={`src.pod.metadata.name == "${pod.metadata.name}" or dst.pod.metadata.name == "${pod.metadata.name}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {pod.metadata.name}
                  </Queryable>
                </td>
                <td style={{ position: "relative", width: "20%" }}>
                  <Queryable
                    query={`src.namespace == "${pod.metadata.namespace}" or dst.namespace == "${pod.metadata.namespace}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {pod.metadata.namespace}
                  </Queryable>
                </td>
                <td style={{ position: "relative", width: "20%" }}>
                  <Queryable
                    query={`node.name == "${pod.spec.nodeName}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {pod.spec.nodeName}
                  </Queryable>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>}
      </div>
    </div>
  )
}

export const StatusBar: React.FC = () => {
  const [expandedBar, setExpandedBar] = useState(false);
  const [targetedPodsFetched, setTargetedPodsFetched] = useState(false)
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const { fetchTargetedPods: fetchTargets, fetchTargetSettings } = useTargeting()

  const fetchTargetedPods = () => {
    fetchTargets().finally(() => setTargetedPodsFetched(true));
  }

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchTargetSettings()
    fetchTargetedPods()
  }, [trafficPlayback, shouldAuthenticate])

  useInterval(useCallback(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchTargetedPods()
  }, [trafficPlayback, shouldAuthenticate]), 20000, false)

  return (
    <Fade in={targetedPodsFetched}>
      <Box>
        <StatusBarContent
          expandedBar={expandedBar}
          setExpandedBar={setExpandedBar}
        />
      </Box>
    </Fade>
  );
}
