import React from 'react'

import { Box, Typography, Button, Paper, Link } from '@mui/material'

import { HubBaseUrl, initSamlAuthPath } from '../../../../../consts'

import useQuerySave from '../../../../../hooks/useQuerySave'

import styles from './SamlSignInForm.module.sass'

import ShieldIcon from '@mui/icons-material/Shield'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import variables from '../../../../../variables.module.scss'
import logoVertical from '../../../../../assets/logoVertical.svg'

export const SamlSignInForm: React.FC = () => {
  const { saveQuery } = useQuerySave()

  const proceedWithSamlAuth = () => {
    saveQuery().then(() => triggerSamlAuth())
  }

  const triggerSamlAuth = () => {
    window.location.href = `${HubBaseUrl}${initSamlAuthPath}`
  }

  return (
    <Box className={styles.SamlSignInForm}>
      <img className='logo' src={logoVertical} alt='logo' />
      <Typography
        variant='body1'
        fontFamily={variables.textFontFamily}
        color={variables.secondaryFontColor}
        align='center'
      >
        By continuing, you agree to the <br />
        <Link
          target='_blank'
          rel='noopener'
          color={variables.blueColor}
          underline='hover'
          href='https://www.kubeshark.co/privacy-policy'
          sx={{ fontWeight: 700 }}
        >
          Company&apos;s Privacy Statement
        </Link>
        &nbsp;and&nbsp;
        <Link
          target='_blank'
          rel='noopener'
          color={variables.blueColor}
          underline='hover'
          href='https://www.kubeshark.co/terms-of-service'
          sx={{ fontWeight: 700 }}
        >
          Terms of Service
        </Link>
        .
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
        <Button
          size='large'
          variant='contained'
          startIcon={
            <VpnKeyIcon htmlColor='#ffffff' style={{ marginRight: '10px' }} />
          }
          style={{ textTransform: 'none', borderRadius: '6px' }}
          onClick={proceedWithSamlAuth}
        >
          <Typography
            variant='body1'
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color='#FFFFFF'
          >
            Sign up / Log in
          </Typography>
        </Button>
      </Box>
      <Paper>
        <Box className={styles.SamlNotice}>
          <ShieldIcon htmlColor={variables.blueColor} />
          <Typography
            variant='body2'
            fontFamily={variables.textFontFamily}
            fontWeight={500}
            color={variables.fontColor}
          >
            This installation is secured with&nbsp;
            <Link
              target='_blank'
              rel='noopener'
              color={variables.blueColor}
              underline='hover'
              href='https://docs.kubeshark.co/en/authentication#saml'
              sx={{ fontWeight: 700 }}
            >
              SAML auth
            </Link>
            .
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}
