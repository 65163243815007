import { useEffect } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import authSessionOkAtom from "../recoil/authSessionOk/atom"
import { appAuthEnabled, appCloudLicenseEnabled, appDefaultAuthTypeEnabled } from "../types/global"
import shouldAuthenticateAtom from "../recoil/shouldAuthenticate/atom"
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_LOCKED } from "../recoil/trafficPlayback/atom"
import licenseInfoAtom from '../recoil/licenseInfo'

const useAuthTracker = () => {
  const authSessionOk = useRecoilValue(authSessionOkAtom)
  const licenseInfo = useRecoilValue(licenseInfoAtom)

  const setShouldAuthenticate = useSetRecoilState(shouldAuthenticateAtom)

  useEffect(() => {
    let shouldAuthenticateVal = appAuthEnabled() && !authSessionOk
    if (appCloudLicenseEnabled() && appDefaultAuthTypeEnabled()) {
      shouldAuthenticateVal = false
    }

    setShouldAuthenticate(shouldAuthenticateVal)
  }, [authSessionOk, licenseInfo])

  const setTrafficPlayback = useSetRecoilState(trafficPlaybackAtom);

  useEffect(() => {
    if (appCloudLicenseEnabled() && appDefaultAuthTypeEnabled()) {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('descope-login-flow')) {
        setTrafficPlayback(TRAFFIC_PLAYBACK_LOCKED)
        setShouldAuthenticate(true)
      }
    }
  }, [])
}

export default useAuthTracker
