import React from 'react'

interface IconProps {
  stroke: string
}

export const AiAssistantBannerIcon: React.FC<IconProps> = ({ stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='128'
      height='128'
      viewBox='0 0 24 24'
      fill='none'
      stroke={stroke}
      strokeWidth='1'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-message-circle-code'
    >
      <path d='M10 9.5 8 12l2 2.5' />
      <path d='m14 9.5 2 2.5-2 2.5' />
      <path d='M7.9 20A9 9 0 1 0 4 16.1L2 22z' />
    </svg>
  )
}
